import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { Breadcrumbs } from '@mui/material';

import SearchPatients from 'components/SearchBar/SearchPatients';
import arrowBack from 'assets/arrow_back.svg';
import { searchItems } from 'constants/searchItems';
import ButtonReidControl from 'components/ButtonReidControl';
import * as S from './styles';

export default function TitlePage({
  title, subtitle, studyName, searchItem, searchTerm, setSearchTerm, searchType, setSearchType,
}) {
  const navigate = useNavigate();
  const currentPage = window.location.href;

  const [searchParams] = useSearchParams();
  const customerID = searchParams.get('customer');

  return (
    <S.Container>
      <S.Toolbar>
        <S.Nav>
          <S.BackButton
            type="button"
            onClick={() => navigate(-1)}
          >
            <img src={arrowBack} alt="Voltar" />
          </S.BackButton>

          <Breadcrumbs separator="|">

            {(currentPage.includes('clientes') || customerID) && (
              <Link to="/clientes">Clientes</Link>
            )}

            {currentPage.includes('estudos') && (
              <Link to={{
                pathname: '/estudos',
                search: customerID ? `?customer=${customerID}` : '',
              }}
              >Estudos
              </Link>
            )}

            {studyName && <p>{studyName}</p>}

            {currentPage.includes('perfil') && (
              <p>Perfil</p>
            )}

            {currentPage.includes('favoritos') && (
              <p>Favoritos</p>
            )}
          </Breadcrumbs>
        </S.Nav>

        {studyName && <ButtonReidControl />}

      </S.Toolbar>
      <S.TitleItem>
        <div>
          <h2>{title}</h2>
          <p>{subtitle}</p>
        </div>

        {searchItem === searchItems.PATIENT && (
          <SearchPatients
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            searchType={searchType}
            setSearchType={setSearchType}
          />
        )}
      </S.TitleItem>
    </S.Container>
  );
}
