export const patientElegibility = {
  YES: 1,
  NO: 0,
};

export const optionsByElebility = {
  [patientElegibility.YES]: {
    option1: 'Investigação',
    option2: 'Contato',
    option3: 'Consulta',
    option4: 'Pre-screening',
    option5: 'Concluídos',
    option6: 'Paciente já em acompanhamento',
    option7: 'Não quero acompanhá-lo (por favor, especifique nas observações)',
  },
  [patientElegibility.NO]: {
    option1: 'Não atende a algum critério de inclusão',
    option2: 'Possui critério de exclusão',
    option3: 'Outros (por favor, especifique nas observações)',
  },
};
