import { Button as MaterialButton } from '@mui/material';
import styled, { css } from 'styled-components';

export const Label = styled.p`
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: capitalize;
`;

const Group = styled.div`
  display: flex;
  align-items: stretch;
  gap: 0.5rem;
  margin-right: 1.5rem;
  margin-bottom: 1rem;

  &:first-child {
    margin-top: 0.5rem;
  }
  
  span {
    font-size: 0.875rem;
    font-weight: 500;
    color: #1C1B1F;
  }

  label, ::placeholder {
    font-size: 0.875rem;
    font-weight: 400;
    color: #1C1B1F;
  }

  p.MuiFormHelperText-root {
    font-size: 0.7rem;
    letter-spacing: 0;
    line-height: 0.75rem;
    color: #1C1B1F;
  }
`;

export const GroupColumn = styled(Group)`
  flex-direction: column;
  width: 100%;
`;

export const GroupRow = styled(Group)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Button = styled(MaterialButton)`
  border-radius: 16px;
  color: #FFF;
  background: #604FFF;
  font-weight: 400;
  box-shadow: none;
  text-transform: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;

  ${({ variant }) => variant === 'ghost' && css`
    color: #6750A4;
    background: #F6F8FC;
    font-weight: 500;
  `}
`;
