import { Section } from 'components/Section';
import styled from 'styled-components';

export const Container = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SectionFullWidth = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 1rem;
  padding: 1.5rem 1rem;

`;
