import { useEffect, useState } from 'react';

import CardPatient from 'components/CardPatient';
import { ErrorContainer } from 'components/ErrorContainer';
import Layout from 'components/Layout';
import Loader from 'components/Loader';
import { Section } from 'components/Section';
import TitlePage from 'components/TitlePage';

import SemanticSearchServices from 'services/SemanticSearchServices';

import { useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import CloseButton from 'components/Toast/CloseButton';
import * as S from './styles';

export default function Favorites() {
  const [listData, setListData] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const hasPatients = !error && !loading && listData?.patients?.length > 0;

  useEffect(() => {
    (async () => {
      try {
        const response = await SemanticSearchServices.getFavoriteListData(id);
        setListData(response);
      } catch {
        setError(true);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={false}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        closeButton={CloseButton}
      />
      <Layout>
        <Section>
          <TitlePage
            title={listData.name}
          />
        </Section>

        {loading && <Loader />}

        <Section>

          {(error && !loading) && (
            <ErrorContainer>
              <span>Erro ao carregar dados da Lista</span>
            </ErrorContainer>
          )}

          {(!error && !loading && !hasPatients) && (
            <ErrorContainer>
              <span>Nenhum paciente encontrado</span>
            </ErrorContainer>
          )}

          <S.Cards>
            {!error && !loading && hasPatients && (
              listData?.patients.map((patient) => (
                <CardPatient
                  key={patient.sha}
                  data={patient}
                  study={listData?.study}
                  studyName={listData?.studyName}
                  favorite
                />
              ))
            )}
          </S.Cards>

        </Section>
      </Layout>
    </>
  );
}
