import styled from 'styled-components';

export const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  background: #F6F8FC;

  strong {
    font-size: 1.25rem;
  }
`;
