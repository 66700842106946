import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useAuth from 'context/auth/useAuth';
import Loader from 'components/Loader';

import { getIndexRoute } from 'utils/getIndexRoute';
import * as S from './styles';
import Form from './components/Form';
import Banner from './components/Banner';

export default function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const { authenticated, dataUserAuthenticated, handleLogout } = useAuth();
  const navigation = useNavigate();

  useEffect(() => {
    if (authenticated) {
      const indexRoute = getIndexRoute(dataUserAuthenticated?.permissions);
      if (!indexRoute) {
        handleLogout();
        return;
      }

      navigation(indexRoute);
    }
  }, [authenticated]);

  return (
    <S.Container>
      {isLoading && (
        <Loader />
      )}

      <Form setIsLoading={setIsLoading} />
      <Banner />

    </S.Container>
  );
}
