import {
  Add, Bookmark, BookmarkAddOutlined, Check,
} from '@mui/icons-material';
import {
  CircularProgress, Divider, Menu, MenuItem,
} from '@mui/material';

import useMenu from 'hooks/useMenu';
import useFavoriteLists from 'context/favoriteLists/useFavoriteLists';
import { useState } from 'react';
import useModal from 'hooks/useModal';
import Modal from 'components/Modal';
import { toast } from 'react-toastify';
import SemanticSearchServices from 'services/SemanticSearchServices';
import useFilter from 'context/filter/useFilter';
import { useNavigate, useParams } from 'react-router-dom';
import * as S from '../styles';
import ModalCreateList from '../ModalCreateList';

export default function FavoriteListMenu({
  sha, aes, cid, listsSelected, study,
}) {
  const [listsSelectedMutable, setListsSelectedMutable] = useState(listsSelected);
  const {
    anchorElMenu, openMenu, handleOpen, handleClose,
  } = useMenu();
  const { visible, handleOpenModal, handleCloseModal } = useModal();
  const { filters } = useFilter();
  const navigate = useNavigate();
  const { id: currentListIdPage } = useParams();

  const {
    lists, error, loading, loadLists, setLists,
  } = useFavoriteLists();
  const [loadingCreateList, setLoadingCreateList] = useState(false);

  async function handleFavoritePatient(listId) {
    if (listsSelectedMutable?.includes(listId)) {
      try {
        await SemanticSearchServices.removePatientFromList(listId, sha);
        toast.success('Paciente removido da lista com sucesso');
        setListsSelectedMutable((prevState) => prevState.filter((id) => id !== listId));
        if (currentListIdPage === listId) {
          navigate(0);
        }
      } catch (erro) {
        toast.error('Erro ao remover paciente da lista');
      }
    } else {
      try {
        await SemanticSearchServices.addPatientToList(listId, sha, aes, filters, cid);
        toast.success('Paciente adicionado na lista com sucesso');
        setListsSelectedMutable((prevState) => [...prevState, listId]);
      } catch {
        toast.error('Erro ao adicionar paciente na lista');
      }
    }
  }

  async function createList(name) {
    setLoadingCreateList(true);
    try {
      await SemanticSearchServices.createFavoriteList(name, study);
      handleCloseModal();
      toast.success('Lista criada com sucesso');
      setLists([]);
      loadLists();
    } catch {
      toast.error('Erro ao criar lista');
    } finally {
      setLoadingCreateList(false);
    }
  }

  return (
    <>
      <Modal visible={visible}>
        <ModalCreateList callback={createList} onClose={handleCloseModal} loading={loadingCreateList} />
      </Modal>

      <S.ButtonCardActions
        type="button"
        onClick={handleOpen}
      >
        {listsSelectedMutable?.length === 0 && (
          <BookmarkAddOutlined onClick={loadLists} />
        )}

        {listsSelectedMutable?.length > 0 && (
          <Bookmark onClick={loadLists} />
        )}
      </S.ButtonCardActions>
      <Menu
        anchorEl={anchorElMenu}
        open={visible ? false : openMenu}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {error && <MenuItem disabled><S.MenuItemWithIcon>Erro ao carregar listas</S.MenuItemWithIcon></MenuItem>}

        {loading && (
          <MenuItem>
            <CircularProgress size={14} />
          </MenuItem>
        )}

        {lists?.map((list) => (
          <MenuItem key={list.id} onClick={() => handleFavoritePatient(list.id)}>
            <S.MenuItemWithIcon>
              {listsSelectedMutable?.includes(list.id) && <Check />}
              {list.name}
            </S.MenuItemWithIcon>
          </MenuItem>
        ))}
        <Divider />
        <MenuItem>
          <S.MenuItemWithIcon onClick={handleOpenModal}>
            <Add />
            Criar Nova Lista
          </S.MenuItemWithIcon>
        </MenuItem>
      </Menu>
    </>
  );
}
