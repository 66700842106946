import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const MultipleTitles = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const Title = styled.div`
  padding: 0.5rem 1.25rem;
  font-size: 0.875rem;
  text-align: center;
  cursor: pointer;
  font-weight: 400;
  color: #C5C0FF;
  border-bottom: 1px solid #C5C0FF;

  ${({ active }) => active && `
    font-weight: 500;
    color: #604FFF;
    border-bottom: 2px solid #604FFF;
  `}
`;

export const ModalInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2.75rem;

  p {
    font-size: 1rem;
    color: #5F6368;
    font-weight: 500;
  }
`;

export const IndicatedDate = styled.div`
  color: #5F6368;
  font-weight: 500;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const PatientInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
`;
