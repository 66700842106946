import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.5rem;
`;

export const Selector = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  button {
    border: 0;
    background: transparent;
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: #D3D3D3;
  margin: 0.5rem 0;
`;
