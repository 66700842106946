import { useRef, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import useReid from 'context/reid/useReid';

import * as S from './styles';

export default function SearchBar({
  children, placeholder, searchTerm, setSearchTerm,
}) {
  const [visibleMoreOptions, setVisibleMoreOptions] = useState(false);
  const { someReidentifiedPatient, password } = useReid();
  const inputRef = useRef(null);
  const patientsReidentify = someReidentifiedPatient;

  function handleFocusInputSearch() {
    inputRef.current.focus();

    if (patientsReidentify) {
      setVisibleMoreOptions(true);
    }
  }

  function handleBlurInputSearch(event) {
    if (event.currentTarget === null
      || event.currentTarget === undefined
      || event.relatedTarget === null
      || event.relatedTarget === undefined
    ) {
      setVisibleMoreOptions(false);
    }
  }

  function handleClearInputSearch() {
    setSearchTerm('');
  }

  return (
    <S.Container
      onClick={handleFocusInputSearch}
      onBlur={handleBlurInputSearch}
      tabIndex={0}
      size={(patientsReidentify || !!password) ? 'large' : 'small'}
    >
      <SearchIcon sx={{ color: '#5F6368' }} />
      <S.InputSearch
        placeholder={placeholder}
        value={searchTerm}
        onChange={(event) => setSearchTerm(event.target.value)}
        ref={inputRef}
        id="search-bar"
      />
      <CloseIcon sx={{ color: '#5F6368', display: !searchTerm && 'none' }} onClick={handleClearInputSearch} />
      {visibleMoreOptions && (
        <>
          <S.Separator />
          { children }
        </>
      )}
    </S.Container>
  );
}
