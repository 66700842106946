import { useEffect, useState } from 'react';
import ListItem from '@mui/material/ListItem';

import {
  Search,
  PermPhoneMsgOutlined,
  CalendarMonthOutlined,
  DescriptionOutlined,
  CheckCircleOutlined,
  DangerousOutlined,
  ArrowDropDownOutlined,
  CheckCircleOutlineOutlined,
} from '@mui/icons-material';

import { patientStatus, patientStatusNames, patientStatusRequestsNames } from 'constants/patientStatus';
import { patientElegibility } from 'constants/patientElegibility';
import { toast } from 'react-toastify';
import Modal from 'components/Modal';
import useModal from 'hooks/useModal';
import PatientServices from 'services/PatientServices';
import useAuth from 'context/auth/useAuth';
import ModalIneligible from 'pages/PatientsList/components/ModalIneligible';
import * as S from './styles';
import MenuItem from './MenuItem';

export default function Tag({
  currentSubStatus, currentStatus, loadPatients, patientId, studyId, elegibility, setIsLoading,
}) {
  const { dataUserAuthenticated } = useAuth();
  const options = [
    {
      status: patientStatus.IN_INVESTIGATION,
      icon: Search,
      text: 'Investigar',
      subItems: [
        'Conversar com Médico(a) Assistente',
        'Avaliar Exames',
        'Avaliar Futuramente',
      ],
    },
    {
      status: patientStatus.IN_CONTACT,
      icon: PermPhoneMsgOutlined,
      text: 'Contato',
      subItems: [
        'Primeiro Contato',
        'Segundo Contato',
        'Terceiro Contato',
      ],
    },
    {
      status: patientStatus.IN_CONSULTATION,
      icon: CalendarMonthOutlined,
      text: 'Consulta',
    },
    {
      status: patientStatus.IN_PRE_SCREENING,
      icon: DescriptionOutlined,
      text: 'Pre-Screening',
    },
    {
      status: patientStatus.CONCLUDED,
      icon: CheckCircleOutlined,
      text: 'Randomizado',
    },
    {
      status: patientStatus.CONCLUDED,
      icon: DangerousOutlined,
      text: 'Exclusão',
      subItems: [
        'Sem Sucesso no Contato',
        'Sem Interesse',
        'Desistência',
        'Falha de Pre-Screening',
      ],
    },
  ];
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  function findIndexOption() {
    if (currentStatus === patientStatus.CONCLUDED && elegibility === patientElegibility.NO) {
      return 5;
    }
    return options.findIndex((option) => option.status === currentStatus);
  }
  const [selectedIndex, setSelectedIndex] = useState(findIndexOption);

  function findIndexSubOption() {
    const indexOption = findIndexOption();
    const indexSubOption = options[indexOption]?.subItems?.findIndex((subItem) => subItem === currentSubStatus);
    if (indexSubOption > -1) {
      return indexSubOption;
    }
    return null;
  }

  const [selectedIndexSubItem, setSelectedIndexSubItem] = useState(findIndexSubOption);
  const [indexBeingSelected, setIndexBeingSelected] = useState(null);

  const variantContainer = currentStatus === patientStatus.CONCLUDED ? elegibility : currentStatus;
  const CurrentIcon = options[selectedIndex]?.icon;
  const currentText = (selectedIndexSubItem !== null)
    ? options[findIndexOption()]?.subItems[selectedIndexSubItem] : options[selectedIndex]?.text;

  const { visible, handleOpenModal, handleCloseModal } = useModal();
  const [modaltext, setModalText] = useState('');

  function handleClose() {
    setAnchorEl(null);
    setIndexBeingSelected(null);
    setSelectedIndexSubItem(findIndexSubOption);
    setSelectedIndex(findIndexOption);
  }

  useEffect(() => {
    handleClose();
  }, [currentStatus, currentSubStatus]);

  function handleClickListItem(event) {
    setAnchorEl(event.currentTarget);
  }

  async function handleUpdatePatientStatus({ data, newStatus, oldStatus }) {
    try {
      if (oldStatus !== newStatus) {
        setIsLoading(true);
      }
      await PatientServices.sendFeedback(data);

      if (oldStatus !== newStatus) {
        toast.success(`Paciente enviado para a aba ${patientStatusNames[newStatus]}`, {
          icon: <CheckCircleOutlineOutlined />,
        });
        loadPatients({ currentStatus: patientStatusRequestsNames[oldStatus] });
        loadPatients({ currentStatus: patientStatusRequestsNames[newStatus] });
      }
    } catch {
      toast.error('Erro ao atualizar status do paciente');
    } finally {
      if (oldStatus !== newStatus) {
        setIsLoading(false);
      }
    }
  }

  async function handleMenuItemClick(event, index) {
    const oldStatus = options[selectedIndex].status;
    setSelectedIndex(index);
    setAnchorEl(null);

    const newStatus = options[index].status;

    // como item do menu sem subItem, o unico possível é Randomizado
    const newSubStatus = newStatus === patientStatus.CONCLUDED ? 'Randomizado' : options[index].text;

    const data = {
      status: newStatus,
      substatus: newSubStatus,
      patientId,
      author_id: dataUserAuthenticated.userId,
      study_id: studyId,
      is_elegible: elegibility,
    };

    await handleUpdatePatientStatus({ data, newStatus, oldStatus });
  }

  function handleSelectIneligible(index) {
    handleClose();
    handleOpenModal();
    setModalText(options[indexBeingSelected]?.subItems[index]);
  }

  async function handleSubMenuItemClick(index) {
    const oldStatus = options[selectedIndex].status;
    setSelectedIndexSubItem(index);
    setSelectedIndex(indexBeingSelected);
    setAnchorEl(null);

    const newStatus = options[indexBeingSelected].status;
    const subStatus = options[indexBeingSelected]?.subItems[index];

    const data = {
      status: newStatus,
      substatus: subStatus,
      patientId,
      author_id: dataUserAuthenticated.userId,
      study_id: studyId,
      is_elegible: elegibility,
    };

    await handleUpdatePatientStatus({ data, newStatus, oldStatus });
  }

  return (
    <>
      <Modal visible={visible}>
        <ModalIneligible
          motive={modaltext || 'Inelegível'}
          onClose={handleCloseModal}
          loadPatients={loadPatients}
          oldStatus={options[selectedIndex]?.status}
          patientId={patientId}
          studyId={studyId}
        />
      </Modal>

      <S.Container variant={variantContainer}>
        <S.CustomList>
          <ListItem onClick={handleClickListItem}>
            <CurrentIcon />
            {currentText}
            <ArrowDropDownOutlined />
          </ListItem>
        </S.CustomList>

        <S.CustomMenu
          sx={{ top: 8 }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {options.map((option, index) => (
            <MenuItem
              key={option.text}
              option={option}
              selectedIndex={selectedIndex}
              index={index}
              onMenuItemClick={handleMenuItemClick}
              onSubMenuItemClick={option.text !== 'Exclusão' ? handleSubMenuItemClick : handleSelectIneligible}
              setIndexBeingSelected={setIndexBeingSelected}
            />
          ))}
        </S.CustomMenu>
      </S.Container>
    </>
  );
}
