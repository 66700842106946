import { useEffect, useState } from 'react';

import {
  TableBody, TableCell, TableFooter, TableRow,
} from '@mui/material';

import TitlePage from 'components/TitlePage';
import Layout from 'components/Layout';
import Loader from 'components/Loader';
import EmptyTableCell from 'components/EmptyTableCell';
import TableData from 'components/TableData';
import { Section } from 'components/Section';

import StudyServices from 'services/StudyServices';

import { useSearchParams } from 'react-router-dom';
import StudyRow from './components/StudyRow';
import * as S from './styles';

export default function StudiesList() {
  const [studies, setStudies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const hasStudies = studies.length > 0;
  const tableHeaderItems = [
    { content: 'Estudos' },
    { content: 'Status' },
    { content: 'Pacientes' },
    { content: 'Estimativa de Encerramento do Estudo' },
    { content: 'Atualizado' },
  ];

  const [searchParams] = useSearchParams();
  const customerID = searchParams.get('customer');

  useEffect(() => {
    (async () => {
      try {
        if (customerID) {
          const { studies: studiesList } = await StudyServices.listStudiesByCustomerID(customerID);
          setStudies(studiesList);
        } else {
          const { studies: studiesList } = await StudyServices.listCustomerStudies();
          setStudies(studiesList);
        }
      } catch {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <Layout>
      <Section>
        <TitlePage
          title="Lista de Estudos"
          subtitle="Aqui você encontrará toda estrutura necessária para seus estudos."
        />
      </Section>
      {(isLoading) ? (
        <Loader />
      ) : (
        <Section>
          <TableData headItems={tableHeaderItems} label="lista de Estudos">
            <TableBody>
              {error ? (
                <EmptyTableCell message="Erro ao carregar estudos" columns={5} />
              ) : !hasStudies ? (
                <EmptyTableCell message="Não há nenhum estudo no momento" columns={5} />
              ) : (
                studies.map((study) => (
                  <StudyRow
                    key={study.id}
                    study={study}
                    customerID={customerID}
                  />
                ))
              )}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TableCell
                  colSpan={5}
                  sx={{
                    borderBottom: 0, paddingBottom: 0, paddingTop: '1rem', paddingRight: '1rem',
                  }}
                >
                  {(hasStudies && !error) && (
                    <S.FooterPaginationTable>
                      <p>Total de Estudos: {studies.length}</p>
                    </S.FooterPaginationTable>
                  )}
                </TableCell>
              </TableRow>
            </TableFooter>
          </TableData>

        </Section>
      )}
    </Layout>
  );
}
