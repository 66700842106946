import { useRef, useState } from 'react';
import { CheckCircleOutlineOutlined, Close } from '@mui/icons-material';
import { toast } from 'react-toastify';
import NotificationServices from 'services/NotificationServices';
import { useSearchParams } from 'react-router-dom';
import { Checkbox } from '@mui/material';
import * as DS from '../../styles';

import * as S from './styles';
import Button from '../Button';

export default function SendNotification({
  onClose, onSubmit, patient, studyName, studyID,
}) {
  const [observation, setObservation] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const inputObservations = useRef(null);
  const [checked, setChecked] = useState(false);

  const [searchParams] = useSearchParams();
  const customerID = searchParams.get('customer');

  function handleChangeObservation(event) {
    setObservation(event.target.value);
  }

  function handleChangeChecked() {
    setChecked(!checked);
    setObservation('');
  }

  async function handleSubmit() {
    setIsSubmitting(true);
    try {
      const promises = [onSubmit];

      if (checked) {
        const observationFormatted = `Paciente: ${patient}\r\nEstudo: ${studyName}\r\n${observation}`;

        const notification = {
          title: 'Paciente potencialmente elegível',
          message: observationFormatted,
          type: 'recruitment',
          receive: customerID,
          extras: JSON.stringify({
            link: `/estudos/${studyID}?search=${patient}`,
            studyName,
            studyID,
          }),
        };

        promises.push(NotificationServices.createNotification(notification));
      }

      await Promise.all(promises);

      toast.success('Notificação enviada com sucesso', {
        icon: <CheckCircleOutlineOutlined />,
      });
    } catch (error) {
      toast.error('Erro ao enviar notificação');
    } finally {
      setIsSubmitting(false);
      onClose();
    }
  }

  return (
    <DS.Container>
      <S.CloseButton onClick={onClose}>
        <Close />
      </S.CloseButton>
      <S.Header>
        <h4>Avaliar Paciente</h4>
        <p>Envie uma notificação direta ao recrutador indicando que o paciente é potencialmente elegível ao estudo.</p>
      </S.Header>

      <S.Content>
        <p>Estudo: {studyName}</p>
        <p>Paciente: {patient}</p>

        <p>
          Enviar notificação?
          <Checkbox
            checked={checked}
            onChange={handleChangeChecked}
          />
        </p>
      </S.Content>

      <S.Form onSubmit={handleSubmit}>
        <S.TexField
          disabled={!checked}
          label="Observações"
          placeholder="Escreva uma observação para o recrutador…"
          multiline
          rows={5}
          value={observation}
          onChange={handleChangeObservation}
          inputRef={inputObservations}
          tabIndex={-1}
        />

        <Button
          formIsValid
          isSubmitting={isSubmitting}
          type="submit"
          variant="contained"
        >Avaliar
        </Button>
      </S.Form>
    </DS.Container>
  );
}
