import { CircularProgress } from '@mui/material';
import { Overlay } from 'components/Overlay';

export default function Loader() {
  return (
    <Overlay>
      <CircularProgress size={80} sx={{ color: '#604FFF' }} />
    </Overlay>
  );
}
