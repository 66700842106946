import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CustomerServices from 'services/CustomerServices';
import UtilServices from 'services/UtilServices';
import { getIndexRoute } from 'utils/getIndexRoute';
import getTokenFromStorage from 'utils/getTokenFromStorage';

const AuthContext = createContext();

function AuthProvider({ children }) {
  const [dataUserAuthenticated, setDataUserAuthenticated] = useState(null);
  const authenticated = !!dataUserAuthenticated;
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const { dataUser, token } = getTokenFromStorage();

    if (!token) {
      setLoading(false);
      return;
    }

    (async () => {
      try {
        await UtilServices.sanityCheck();
        setDataUserAuthenticated(dataUser);
      } catch {} finally {
        setLoading(false);
      }
    })();
  }, []);

  function handleLogout() {
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    setDataUserAuthenticated(null);
    sessionStorage.clear();
    navigate('/');
  }

  async function handleLogin({ email, password, remember }) {
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    try {
      const { token } = await CustomerServices.login({ email, password });

      const dataUser = {
        token: token.token,
        customerId: token.customerId,
        customerName: token.customerName || 'Customer Name',
        name: token.name,
        surname: token.surname,
        userId: token.userId,
        avatar: token.avatar,
        permissions: token.permissions,
      };

      if (remember) {
        const dateExpires = new Date();
        dateExpires.setDate(dateExpires.getDate() + 1);

        document.cookie = `token=${JSON.stringify(dataUser)}; expires=${dateExpires}; path=/;`;
      } else {
        sessionStorage.setItem('token', JSON.stringify(dataUser));
      }
      setDataUserAuthenticated(dataUser);

      setLoading(false);

      const indexRoute = getIndexRoute(dataUser?.permissions);
      if (!indexRoute) {
        handleLogout();
        return;
      }

      navigate(indexRoute);
    } catch {
      alert('Email ou senha incorretos');
    }
  }

  return (
    <AuthContext.Provider value={{
      authenticated, dataUserAuthenticated, handleLogin, handleLogout, loading,
    }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
