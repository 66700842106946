import * as S from './styles';

export default function TopicTag({ tag }) {
  const { entity } = tag;
  const value = tag.value && tag.value.slice(0, 1).toUpperCase() + tag.value.slice(1).toLowerCase();

  return (
    <S.Container>
      {entity}: {value}
    </S.Container>
  );
}
