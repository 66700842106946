import HttpClient from './utils/HttpClient';

class NotificationServices {
  constructor() {
    this.httpClient = new HttpClient(process.env.REACT_APP_API_COMTRIALS_URL);
  }

  createNotification(notification) {
    return this.httpClient.post('/notification', notification);
  }

  listNotifications() {
    return this.httpClient.get('/notification');
  }

  changeRead(id, read) {
    return this.httpClient.put(`/notification/${id}`, {
      read,
    });
  }

  deleteNotifications(notifications) {
    return this.httpClient.delete('/notification', {
      notificationsIDs: notifications,
    });
  }
}

export default new NotificationServices();
