import { TableContainer as TableContainerMui } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 60vh;
`;

export const TableContainer = styled(TableContainerMui)`
  padding-right: 1rem;

    ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    margin: 1.25rem 0;
    background-color: #FFF; 
    border-radius: 4px;
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #B8B8B8;
    width: 6px;
    border-radius: 4px;
    background-clip: content-box;
  }
`;

export const Row = styled.div`  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 100%;
  max-width: 30vw;
  display: inline-block;
`;
