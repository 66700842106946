import styled, { css } from 'styled-components';

export const Container = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: calc(100vh - 68px);
  position: relative;
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
  padding-top: 1rem;
  min-width: 100px;
`;

export const Links = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
`;

export const ListItem = styled.li`
  width: 100%;

  ${({ isActive }) => isActive && css`
    background: #E3DFFF;
    border-radius: 0 1rem 1rem 0;
    font-weight: 700;
  `};

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    padding: 0.5rem;
    text-decoration: none;
    color: #202124;
    font-size: 0.75rem;
    font-weight: 500;
    text-align: center;
  }
`;

export const ListItemWithButton = styled(ListItem)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  color: #604FFF;
  cursor: pointer;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  p {
    font-size: 0.875rem;
    font-weight: 500;
    margin-top: 0.5rem;
  }
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  position: fixed;
  bottom: 0;

  p {
    font-weight: 500;
    font-size: 0.65rem;
    color: #B8B8B8;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;

    &:nth-child(3){
      font-size: 0.65rem;
    }
  }


`;
