import { useCallback, useEffect, useState } from 'react';

import { useLocation, useSearchParams } from 'react-router-dom';

import PatientServices from 'services/PatientServices';
import { errorTypes } from 'constants/errorTypes';
import { patientStatusRequests } from 'constants/patientStatus';
import { searchTypes } from 'constants/searchTypes';
import useReid from 'context/reid/useReid';
import useAuth from 'context/auth/useAuth';
import { isAdmin } from 'utils/isAdmin';

export default function usePatientsList() {
  const { dataUserAuthenticated } = useAuth();
  const [searchParams] = useSearchParams();
  const customerID = searchParams.get('customer');
  const patientSearchURL = searchParams.get('search');
  const [status, setStatus] = useState(patientStatusRequests.NOT_EVALUATED);
  const [patients, setPatients] = useState({});
  const [qtyOfPatients, setQtyOfPatients] = useState({});
  const [searchAES, setSearchAES] = useState(patientSearchURL || '');
  const [searchType, setSearchType] = useState(searchTypes.AES);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const emptyCurrentPage = () => {
    const pagesByStatus = {};
    Object.values(patientStatusRequests).forEach((value) => {
      pagesByStatus[value] = 0;
    });
    return pagesByStatus;
  };
  const [tabsMenu, setTabsMenu] = useState(() => [
    {
      label: 'Pacientes para Avaliação',
      status: patientStatusRequests.NOT_EVALUATED,
    },
    {
      label: 'Em Investigação',
      status: patientStatusRequests.IN_INVESTIGATION,
    },
    {
      label: 'Em Contato',
      status: patientStatusRequests.IN_CONTACT,
    },
    {
      label: 'Em Consulta',
      status: patientStatusRequests.IN_CONSULTATION,
    },
    {
      label: 'Pre-Screening',
      status: patientStatusRequests.IN_PRE_SCREENING,
    },
    {
      label: 'Concluídos',
      status: patientStatusRequests.CONCLUDED,
    },
  ]);
  const [currentPage, setCurrentPage] = useState(emptyCurrentPage);
  const [limitPatientsPerPage, setLimitPatientsPerPage] = useState(10);
  const [order, setOrder] = useState(null);
  const { getAESByReid } = useReid();
  const location = useLocation();
  const { studyName, studyId } = location.state || {};
  const tableHeaderItems = {
    evaluated: [
      { content: 'Identificação', action: setOrder, type: order },
      { content: 'Score' },
      { content: 'Classificação' },
      { content: 'Atualizado em' },
      { content: 'Copiar Hash', align: 'center' },
    ],
    notEvaluated: [
      { content: 'Identificação', action: setOrder, type: order },
      { content: 'Score' },
      { content: 'Atualizado em' },
      { content: 'Copiar Hash', align: 'center' },
    ],
  };
  const hasPatients = patients[status]?.length > 0;

  function handleChangeLimit(value) {
    setCurrentPage(emptyCurrentPage);
    setLimitPatientsPerPage(value);
  }

  const loadPatients = useCallback(async ({ currentStatus = status, signal = null, clearSearch = false }) => {
    try {
      setIsLoading(true);
      if (clearSearch) {
        setSearchAES('');
      }
      const patientsResponse = await PatientServices.listPatients({
        type: currentStatus, studyId, page: currentPage[currentStatus], signal, limit: limitPatientsPerPage, customerID,
      });

      if (patientsResponse.patients.length === 0 && currentPage[currentStatus] > 0) {
        setCurrentPage((prevState) => ({
          ...prevState,
          [currentStatus]: prevState[currentStatus] - 1,
        }));
        setIsLoading(true);
      } else {
        setPatients((prevState) => ({
          ...prevState,
          [currentStatus]: patientsResponse.patients,
        }));

        if (isAdmin(dataUserAuthenticated?.permissions)) {
          setQtyOfPatients({
            notEvaluated: patientsResponse.totalPatientsNotEvaluated,
            elegible: patientsResponse.totalPatientsElegible,
            ineligible: patientsResponse.totalPatientsIneligible,
          });
        } else {
          setQtyOfPatients({
            notEvaluated: patientsResponse.totalPatientsNotEvaluated,
            investigation: patientsResponse.totalPatientsInInvestigation,
            inContact: patientsResponse.totalPatientsInContact,
            inConsultation: patientsResponse.totalPatientsInConsultation,
            inPreScreening: patientsResponse.totalPatientsInPreScreening,
            concluded: patientsResponse.totalPatientsConcluded,
          });
        }
        setIsLoading(false);
      }
    } catch (resError) {
      if (resError.name !== errorTypes.ABORT_ERROR) {
        setIsLoading(false);
        setError(true);
      }
    }
  }, [
    currentPage.notEvaluated,
    currentPage.investigation,
    currentPage.inContact,
    currentPage.inConsultation,
    currentPage.inPreScreening,
    currentPage.concluded,
    currentPage.elegible,
    currentPage.ineligible,
    status,
    limitPatientsPerPage,
  ]);

  async function searchPatients() {
    if (searchAES.length < 3) {
      return;
    }

    let sanitySearchHash = searchAES.trim().replaceAll(' ', '');
    if (searchType === searchTypes.REID) {
      sanitySearchHash = getAESByReid(sanitySearchHash);
    }

    try {
      setIsLoading(true);
      const hashEncodedURI = encodeURIComponent(sanitySearchHash);
      const { patients: patientsFilteredResponse } = await PatientServices.getPatientsByAES(hashEncodedURI, studyId, customerID);

      let patientsResponse = {};

      if (isAdmin(dataUserAuthenticated?.permissions)) {
        patientsResponse = {
          notEvaluated: patientsFilteredResponse.notEvaluated,
          elegible: patientsFilteredResponse.elegible,
          ineligible: patientsFilteredResponse.ineligible,
        };

        setQtyOfPatients({
          notEvaluated: patientsFilteredResponse.totalNotEvaluated,
          elegible: patientsFilteredResponse.totalElegible,
          ineligible: patientsFilteredResponse.totalIneligible,
        });
      } else {
        patientsResponse = {
          notEvaluated: patientsFilteredResponse.notEvaluated,
          investigation: patientsFilteredResponse.investigation,
          inContact: patientsFilteredResponse.inContact,
          inConsultation: patientsFilteredResponse.inConsultation,
          inPreScreening: patientsFilteredResponse.inPreScreening,
          concluded: patientsFilteredResponse.concluded,
        };

        setQtyOfPatients({
          notEvaluated: patientsFilteredResponse.totalNotEvaluated,
          investigation: patientsFilteredResponse.totalInvestigation,
          inContact: patientsFilteredResponse.totalInContact,
          inConsultation: patientsFilteredResponse.totalInConsultation,
          inPreScreening: patientsFilteredResponse.totalInPreScreening,
          concluded: patientsFilteredResponse.totalConcluded,
        });
      }

      if (patientsFilteredResponse.totalNotEvaluated > 0) {
        setStatus(patientStatusRequests.NOT_EVALUATED);
      } else if (patientsFilteredResponse.totalInvestigation > 0) {
        setStatus(patientStatusRequests.IN_INVESTIGATION);
      } else if (patientsFilteredResponse.totalInContact > 0) {
        setStatus(patientStatusRequests.IN_CONTACT);
      } else if (patientsFilteredResponse.totalInConsultation > 0) {
        setStatus(patientStatusRequests.IN_CONSULTATION);
      } else if (patientsFilteredResponse.totalInPreScreening > 0) {
        setStatus(patientStatusRequests.IN_PRE_SCREENING);
      } else if (patientsFilteredResponse.totalConcluded > 0) {
        setStatus(patientStatusRequests.CONCLUDED);
      } else if (patientsFilteredResponse.totalElegible > 0) {
        setStatus(patientStatusRequests.ELEGIBLE);
      } else if (patientsFilteredResponse.totalIneligible > 0) {
        setStatus(patientStatusRequests.INELIGIBLE);
      }

      setPatients(patientsResponse);
    } catch {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (isAdmin(dataUserAuthenticated?.permissions)) {
      setTabsMenu([
        {
          label: 'Pacientes para avaliação interna',
          status: patientStatusRequests.NOT_EVALUATED,
        },
        {
          label: 'Pacientes potencialmente elegíveis',
          status: patientStatusRequests.ELEGIBLE,
        },
        {
          label: 'Pacientes potencialmente inelegíveis',
          status: patientStatusRequests.INELIGIBLE,
        },
      ]);
    }
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    if (searchAES.length < 3) {
      loadPatients({ signal: controller.signal });
    }

    return () => controller.abort();
  }, [loadPatients, searchAES]);

  useEffect(() => {
    searchPatients();
  }, [searchAES, searchType]);

  useEffect(() => {
    if (order === 'asc') {
      setPatients((prevState) => ({
        ...prevState,
        [status]: patients[status]?.sort((a, b) => {
          if (a.reidentifiedID && b.reidentifiedID) {
            return a.reidentifiedID - b.reidentifiedID;
          } if (a.reidentifiedID) {
            return -1;
          } if (b.reidentifiedID) {
            return 1;
          }
          return 0;
        }),
      }));
    } else if (order === 'desc') {
      setPatients((prevState) => ({
        ...prevState,
        [status]: patients[status]?.sort((a, b) => {
          if (a.reidentifiedID && b.reidentifiedID) {
            return b.reidentifiedID - a.reidentifiedID;
          } if (b.reidentifiedID) {
            return -1;
          } if (a.reidentifiedID) {
            return 1;
          }
          return 0;
        }),
      }));
    }
  }, [order]);

  return {
    isLoading,
    setIsLoading,
    status,
    setStatus,
    error,
    patients,
    qtyOfPatients,
    tableHeaderItems: tableHeaderItems[status] || tableHeaderItems.evaluated,
    columns: status === patientStatusRequests.NOT_EVALUATED ? 4 : 5,
    hasPatients,
    studyName,
    studyId,
    currentPage,
    loadPatients,
    setCurrentPage,
    searchAES,
    setSearchAES,
    searchType,
    setSearchType,
    limitPatientsPerPage,
    setLimitPatientsPerPage: handleChangeLimit,
    tabsMenu,
  };
}
