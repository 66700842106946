import Header from 'components/Header';
import NavBar from 'components/NavBar';
import * as S from './styles';

export default function Layout({ children }) {
  return (
    <S.Container>
      <Header />
      <S.Content>
        <NavBar />
        <S.Main>
          {children}
        </S.Main>
      </S.Content>
    </S.Container>
  );
}
