import styled from 'styled-components';

export const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  width: 100%;
`;

export const Card = styled.div`
  background-color: #fff;
  border-radius: 24px;
  padding: 1rem;
  color: #333;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
`;

export const Infos = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.5rem;

  h3 {
    font-weight: 500;
    font-size: 1.5rem;
  }

  span {
    font-weight: 500;
    font-size: 0.75rem;
  }
`;

export const UpdatedAt = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  span:nth-child(2){
    color: #5F6368;
  }
`;
