import { useEffect, useRef, useState } from 'react';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import {
  CircleNotificationsOutlined, ClearAll, DeleteOutline, Drafts, MarkEmailUnread,
} from '@mui/icons-material';
import NotificationServices from 'services/NotificationServices';
import { useNavigate } from 'react-router-dom';
import { isAdmin } from 'utils/isAdmin';
import useAuth from 'context/auth/useAuth';
import * as S from './styles';
import CustomMenu from './Menu';

export default function Notification() {
  const { dataUserAuthenticated } = useAuth();
  const isFirstRender = useRef(true);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [menuVisible, setMenuVisible] = useState(null);
  const open = Boolean(menuVisible);
  const [notificationBeingEdited, setNotificationBeingEdited] = useState({});
  const qtyNotiticationsNotRead = notifications.filter((notification) => !notification.is_read).length;
  const navigation = useNavigate();

  function handleOpenMenu(event) {
    setMenuVisible(event.currentTarget);
  }

  function handleCloseMenu() {
    setMenuVisible(null);
  }

  async function loadNotifications() {
    try {
      const result = await NotificationServices.listNotifications();
      setNotifications(result?.notifications || []);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (open || isFirstRender.current) {
      setError(false);
      setNotifications([]);
      setLoading(true);
      loadNotifications();
      isFirstRender.current = false;
    }
  }, [open]);

  async function changeRead(id, read) {
    try {
      setNotificationBeingEdited({
        id,
        read,
      });
      await NotificationServices.changeRead(id, read);
      await loadNotifications();
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  async function handleDelete(ids) {
    try {
      await NotificationServices.deleteNotifications(ids);
      setNotifications((prevState) => prevState.filter((notification) => !ids.includes(notification.id)));
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  function convertDate(dateString) {
    let date = new Date(dateString);
    const aux = new Date();
    const now = new Date(
      aux.getUTCDate(),
      aux.getUTCMonth(),
      aux.getUTCFullYear,
      aux.getUTCHours(),
      aux.getUTCMinutes(),
      aux.getUTCSeconds(),
    );
    const diff = now - date;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    if (seconds < 60) {
      return `Há ${seconds} segundos`;
    }

    if (minutes < 60) {
      return `Há ${minutes} minutos`;
    }

    if (hours < 24) {
      return `Há ${hours} horas`;
    }

    date = new Date(dateString);

    const dateFormated = new Intl.DateTimeFormat('pt-BR', {
      day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit',
    }).format(date);

    return dateFormated;
  }

  function transformItalicStatus(text) {
    const regex = /"(.*?)"/g;
    return text.replace(regex, '"<i>$1</i>"');
  }

  function addedBreakLines(text) {
    return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }

  function handleNotificationAction(dto) {
    const dtoJson = JSON.parse(dto);
    const { link, studyName, studyID } = dtoJson;

    navigation(link, { state: { studyName, studyId: studyID } });
  }

  if (isAdmin(dataUserAuthenticated?.permissions)) {
    return null;
  }

  return (
    <S.Container>
      <Button onClick={handleOpenMenu}>
        <CircleNotificationsOutlined sx={{ color: '#604FFF', width: '24px', height: '24px' }} />
        {qtyNotiticationsNotRead > 0 && (
          <S.Badge />
        )}
      </Button>
      {error && !loading && (
        <CustomMenu open={open} menuVisible={menuVisible} handleCloseMenu={handleCloseMenu}>
          <S.CustomFixedMenuItem>
            <p>Notificações</p>
          </S.CustomFixedMenuItem>
          <S.CustomMenuItem>Erro ao carregar as notificações</S.CustomMenuItem>
        </CustomMenu>
      )}

      {loading && !error && (
        <CustomMenu open={open} menuVisible={menuVisible} handleCloseMenu={handleCloseMenu}>
          <S.CustomFixedMenuItem>
            <p>Notificações</p>
          </S.CustomFixedMenuItem>
          <S.CustomMenuItem><CircularProgress size={16} /></S.CustomMenuItem>
        </CustomMenu>
      )}

      {!loading && !error && notifications.length === 0 && (
        <CustomMenu open={open} menuVisible={menuVisible} handleCloseMenu={handleCloseMenu}>
          <S.CustomFixedMenuItem>
            <p>Notificações</p>
          </S.CustomFixedMenuItem>
          <S.CustomMenuItem>Nenhuma notificação</S.CustomMenuItem>
        </CustomMenu>
      )}

      {!loading && !error && notifications.length > 0 && (
        <CustomMenu open={open} menuVisible={menuVisible} handleCloseMenu={handleCloseMenu}>
          <S.CustomFixedMenuItem>
            <p>Notificações</p>
            <S.MarkAsRead onClick={() => handleDelete(notifications.map((notification) => notification.id))}>
              Limpar
              <ClearAll color="primary" />
            </S.MarkAsRead>
          </S.CustomFixedMenuItem>

          {notifications.map((notification) => (
            <S.CustomMenuItem
              key={notification.id}
              $read={notificationBeingEdited?.id === notification.id ? notificationBeingEdited?.read : notification.is_read}
            >
              <S.Notification>
                <S.Details>
                  {notification.created_at ? convertDate(notification.created_at) : ''}
                  <S.Actions>
                    {((notificationBeingEdited?.id === notification.id
                      ? notificationBeingEdited?.read : notification.is_read)) ? (
                        <Tooltip title="Marcar como não lida">
                          <S.TooltipChildren>
                            <Drafts onClick={() => changeRead(notification.id, 0)} />
                          </S.TooltipChildren>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Marcar como lida">
                          <S.TooltipChildren>
                            <MarkEmailUnread color="primary" onClick={() => changeRead(notification.id, 1)} />
                          </S.TooltipChildren>
                        </Tooltip>
                      )}
                    <Tooltip title="Excluir">
                      <DeleteOutline
                        color={!(notificationBeingEdited?.id === notification.id
                          ? notificationBeingEdited?.read : notification.is_read) ? 'primary' : ''}
                        onClick={() => handleDelete([notification.id])}
                      />
                    </Tooltip>
                  </S.Actions>
                </S.Details>
                <S.Message
                  $link={notification?.extras !== null}
                  onClick={() => handleNotificationAction(notification?.extras)}
                >
                  <span>{notification.title}</span>
                  <div dangerouslySetInnerHTML={
                    { __html: addedBreakLines(transformItalicStatus(notification?.message)) }
                  }
                  />
                </S.Message>
              </S.Notification>
            </S.CustomMenuItem>
          ))}
        </CustomMenu>
      )}
    </S.Container>
  );
}
