import {
  Search,
  PermPhoneMsgOutlined,
  CalendarMonthOutlined,
  DescriptionOutlined,
  CheckCircleOutlined,
  DangerousOutlined,
} from '@mui/icons-material';

import { patientStatus } from 'constants/patientStatus';
import { patientElegibility } from 'constants/patientElegibility';
import * as S from './styles';

export default function Tag({ status, substatus, isElegible }) {
  const variant = status === patientStatus.CONCLUDED
    ? (isElegible === patientElegibility.YES ? patientElegibility.YES : patientElegibility.NO)
    : (status);

  return (
    <S.Container variant={variant}>
      {status === patientStatus.IN_INVESTIGATION && <Search />}
      {status === patientStatus.IN_CONTACT && <PermPhoneMsgOutlined />}
      {status === patientStatus.IN_CONSULTATION && <CalendarMonthOutlined />}
      {status === patientStatus.IN_PRE_SCREENING && <DescriptionOutlined />}
      {status === patientStatus.CONCLUDED && isElegible === patientElegibility.YES && <CheckCircleOutlined />}
      {status === patientStatus.CONCLUDED && isElegible === patientElegibility.NO && <DangerousOutlined />}
      {substatus}
    </S.Container>
  );
}
