import { useState } from 'react';
import { Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import * as S from './styles';

export default function ResearchCollapse({ data, radio }) {
  const [isOpen, setIsOpen] = useState(false);

  const STATUS = {
    'Not yet recruiting': 'Ainda não recrutando',
    Recruiting: 'Recrutando',
    Initiating: 'Iniciando',
    Cancelled: 'Cancelado',
    'Active, not recruiting': 'Ativo, não recrutando',
  };

  function formatText(text) {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  return (
    <div>
      <S.Selector>
        {radio}
        <button type="button" onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </button>
      </S.Selector>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <S.Content>
          <span>Distância: {data.distance} km</span>
          <span>Status: {STATUS[data.status_recruitment]}</span>
          <span>Cidade: {formatText(data.city)}</span>
          <span>Estado: {data.state}</span>
          <span>CEP: {data.cep}</span>
          <S.Separator />
        </S.Content>
      </Collapse>
    </div>
  );
}
