import { CircularProgress, TableBody } from '@mui/material';

import { ToastContainer } from 'react-toastify';
import CloseButton from 'components/Toast/CloseButton';

import Layout from 'components/Layout';
import TitlePage from 'components/TitlePage';
import { Section } from 'components/Section';
import Loader from 'components/Loader';
import TableData from 'components/TableData';
import EmptyTableCell from 'components/EmptyTableCell';

import { searchItems } from 'constants/searchItems';
import { patientStatusRequests } from 'constants/patientStatus';
import PatientRow from './components/PatientRow';
import TableFooter from './components/TableFooter';
import TabMenu from './components/TabMenu';
import usePatientsList from './usePatientsList';

import * as S from './styles';

export default function PatientsList() {
  const {
    isLoading,
    setIsLoading,
    status,
    setStatus,
    error,
    patients,
    qtyOfPatients,
    tableHeaderItems,
    columns,
    hasPatients,
    studyName,
    studyId,
    currentPage,
    setCurrentPage,
    loadPatients,
    searchAES,
    setSearchAES,
    searchType,
    setSearchType,
    limitPatientsPerPage,
    setLimitPatientsPerPage,
    tabsMenu,
  } = usePatientsList();

  return (
    <Layout>
      <ToastContainer
        position="bottom-left"
        autoClose={false}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        closeButton={CloseButton}
      />
      <Section style={{ borderRadius: '1rem 1rem 0 0' }}>
        <TitlePage
          title="Lista de Pacientes do Estudo"
          subtitle="Aqui você encontra uma lista de pacientes para o seu estudo"
          studyName={studyName}
          searchItem={searchItems.PATIENT}
          searchType={searchType}
          setSearchType={setSearchType}
          searchTerm={searchAES}
          setSearchTerm={setSearchAES}
        />
      </Section>
      {isLoading && searchAES.length === 0 ? (
        <Loader />
      ) : (
        <S.Container>
          <S.SectionFullWidth>
            <TabMenu
              status={status}
              setStatus={setStatus}
              isLoading={isLoading}
              error={error}
              qtyOfPatients={qtyOfPatients}
              tabsList={tabsMenu}
            />
            <TableData
              headItems={tableHeaderItems}
              label="Lista de Pacientes"
            >
              <TableBody>
                {error && (
                  <EmptyTableCell message="Erro ao carregar pacientes" columns={columns} />
                )}

                {!error && (isLoading && searchAES.length >= 3) && (
                  <EmptyTableCell columns={columns} align="center">
                    <CircularProgress size={50} sx={{ color: '#604FFF' }} />
                  </EmptyTableCell>
                )}

                {!error && (!hasPatients && searchAES.length > 0 && !isLoading) && (
                  <EmptyTableCell
                    message="Nenhum paciente encontrado na pesquisa. Tente usar as opções de pesquisa."
                    columns={status === patientStatusRequests.NOT_EVALUATED ? 3 : 4}
                  />
                )}

                {((!hasPatients && searchAES.length === 0) && !isLoading && !error) && (
                  <EmptyTableCell
                    message="Nenhum paciente encontrado"
                    columns={columns}
                  />
                )}

                {hasPatients && !isLoading && (
                  patients[status].map((patient, index) => (
                    <PatientRow
                      key={patient.id}
                      patient={patient}
                      status={status}
                      studyName={studyName}
                      studyId={studyId}
                      loadPatients={loadPatients}
                      lastPatient={index === patients[status].length - 1}
                      setIsLoading={setIsLoading}
                    />
                  ))
                )}
              </TableBody>

              <TableFooter
                hasPatients={hasPatients}
                error={error}
                qtyOfPatients={qtyOfPatients[status]}
                status={status}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                limit={limitPatientsPerPage}
                setLimit={setLimitPatientsPerPage}
              />

            </TableData>

          </S.SectionFullWidth>
        </S.Container>
      )}
    </Layout>
  );
}
