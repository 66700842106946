import { Button as ButtonMui, TextField } from '@mui/material';
import styled from 'styled-components';
import background from 'assets/background-form-search.png';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 110px);
  background: url(${background}) center/cover no-repeat;

  border-radius: 24px;
  margin: 0 4.24rem;
  padding: 4rem 0;
`;

export const ContainerForm = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  border: 1px solid #C5C0FF;
  border-radius: 16px;
  background: #FFF;
  padding: 3rem;
`;

export const Title = styled.div`
  color: #202124;

  h1 {
    font-size: 1.75rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
  }

  p {
    font-size: 0.9rem;
    line-height: 1.5rem;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  font-size: 0.75rem !important;

  svg {
    color: #000;
  }
`;

export const TextFieldWithPlaceholder = styled(TextField)`
  input::placeholder {
    opacity: 1;
  }
`;

export const Button = styled(ButtonMui)`
  text-transform: capitalize;
  color: #FFF;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 12px;
  padding: 0.75rem 2rem;
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 110px);
  background: #FFF;
  border-radius: 24px;
  margin: 0 4.24rem;
  margin-bottom: 55px;
  padding: 4rem 0;

  p {
    font-size: 1.5rem;
    font-weight: 400;
    color: #202124;
  }
`;
