import { permissions as permissonsContants } from 'constants/permissions';

export function getIndexRoute(permissions) {
  if (!permissions || !permissions.length) return;

  if (permissions?.includes(permissonsContants.ADMIN)) {
    return '/clientes';
  }
  if (permissions?.includes(permissonsContants.RECRUITMENT)) {
    return '/estudos';
  }
  if (permissions?.includes(permissonsContants.REFERRAL_SEND)) {
    return '/pacientes-enviados';
  }
  if (permissions?.includes(permissonsContants.REFERRAL_RECEIVE)) {
    return '/pacientes-recebidos';
  }
  if (permissions?.includes(permissonsContants.SEARCH)) {
    return '/busca';
  }

  return null;
}
