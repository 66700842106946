import styled, { css } from 'styled-components';
import { Button as MaterialButton, TextField } from '@mui/material';

export const Container = styled.div`
  background: #FFF;
  border: 1px solid rgba(96, 79, 255, 0.3);
  border-radius: 1rem;
  padding: 1.5rem 1.5rem;
  max-width: 500px;
  width: 90%;
  max-height: 100%;
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
  position: relative;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: #FFF; 
    border-radius: 16px;
    border: 1px solid #E3E3E3;
    border-bottom: 0;
    border-top: 0;
    width: 6px;
    margin: 1rem 0;
  }

  ::-webkit-scrollbar-thumb {
    background: #B8B8B8;
    width: 6px;
    border-radius: 4px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  color: #49454F;

  h4 {
    font-weight: 400;
    font-size: 1.35rem;
  }

  p {
    font-weight: 500;
    font-size: 0.8rem;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const FormActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  width: 100%;
`;

export const Button = styled(MaterialButton)`
  border-radius: 16px;
  color: #FFF;
  background: #604FFF;
  font-weight: 400;
  box-shadow: none;
  text-transform: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  ${({ variant }) => variant === 'ghost' && css`
    color: #6750A4;
    background: #F6F8FC;
    font-weight: 500;
  `}
`;

export const StyledTexField = styled(TextField)`
  width: 100%;
  
  label, legend, textarea, input {
    font-size: 0.9rem;
    color: #49454F;

    &.Mui-disabled {
      -webkit-text-fill-color: #49454F;
    }
  }
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #604FFF;
  }
  label.Mui-focused{
    color: #604FFF;
  }
  
`;

export const ContainerErrorLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 0.875rem;
  height: 100%;
  padding: 16px 0;
  position: absolute;
  inset: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(0.5px);
  z-index: 100
`;
