import { Link } from 'react-router-dom';
import { TableCell } from '@mui/material';

import { isAdmin } from 'utils/isAdmin';
import useAuth from 'context/auth/useAuth';
import * as S from './styles';

export default function StudyRow({ study, customerID }) {
  const { dataUserAuthenticated } = useAuth();
  const hasPatientsToEvaluate = Number(study.to_evaluate) > 0;

  function convertDateToPtBr(date) {
    return new Intl.DateTimeFormat('pt-BR').format(new Date(date)) || '';
  }

  function setStatus(status, patientsToEvaluated) {
    if (status === 'Active') {
      if (patientsToEvaluated > 0) {
        return 'Avaliar';
      }

      return 'Avaliado';
    }
    return 'Encerrado';
  }

  function getLinkToStudy(id) {
    if (customerID) {
      return `/estudos/${id}?customer=${customerID}`;
    }
    return `/estudos/${id}`;
  }

  return (
    <S.Container $newPatients={study.status === 'Active' && hasPatientsToEvaluate}>
      <TableCell align="left">
        <Link
          to={getLinkToStudy(study.id)}
          state={{ studyName: study.name, studyId: study.id }}
        >
          <p>{study.title}</p>
        </Link>
      </TableCell>

      <TableCell align="left">
        <Link
          to={getLinkToStudy(study.id)}
          state={{ studyName: study.name, studyId: study.id }}
        >
          <S.Status variant={setStatus(study.status, study.to_evaluate)}>
            <span />
            {setStatus(study.status, study.to_evaluate)}
          </S.Status>
        </Link>
      </TableCell>

      <TableCell align="left">
        <Link
          to={getLinkToStudy(study.id)}
          state={{ studyName: study.name, studyId: study.id }}
        >
          <S.PatientsCount $newPatients={study.status === 'Active' && hasPatientsToEvaluate}>
            {hasPatientsToEvaluate && <S.Tag variant="new">{study.to_evaluate} Novos</S.Tag>}
            {(study.pending > 0 && !isAdmin(dataUserAuthenticated?.permissions)) && (
              <S.Tag variant="pending"> {study.pending} Em andamento </S.Tag>
            )}
            {(!hasPatientsToEvaluate
              && (study.pending === '0' || isAdmin(dataUserAuthenticated?.permissions)))
              && <S.Tag variant="none"> Concluído </S.Tag>}
          </S.PatientsCount>
        </Link>
      </TableCell>

      <TableCell align="left">
        <Link
          to={getLinkToStudy(study.id)}
          state={{ studyName: study.name, studyId: study.id }}
        >
          {study?.estimated_end_date && convertDateToPtBr(study.estimated_end_date)}
        </Link>
      </TableCell>

      <TableCell align="left">
        <Link
          to={getLinkToStudy(study.id)}
          state={{ studyName: study.name, studyId: study.id }}
        >
          {convertDateToPtBr(study.updated_at)}
        </Link>
      </TableCell>
    </S.Container>
  );
}
