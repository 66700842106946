import styled, { css } from 'styled-components';
import { MenuItem as MenuItemMui } from '@mui/material';

export const Container = styled.div`
  button{
    padding: 6px 0;
    min-width: 100%;
  }
`;

export const Badge = styled.div`
  position: absolute;
  top: 20px;
  right: -4px;
  background: #BA1A1A;
  border-radius: 50%;
  width: 12px;
  height: 12px;
`;

export const Notification = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  
  font-size: 14px;
  color: #333333;
`;

export const Message = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.1rem;
  cursor: ${({ $link }) => ($link ? 'pointer' : 'default')};

  span {
    text-wrap: pretty;
  }

  div {
    text-wrap: pretty;
    font-weight: 400;
  }
`;

export const Details = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  cursor: pointer;

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const MarkAsRead = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;

  cursor: pointer;
`;

export const CustomFixedMenuItem = styled(MenuItemMui)`
  font-size: 0.75rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  max-width: 400px;
  min-width: 300px;
  width: 100%;
  padding: 0 0.5rem !important;

  ${({ $read }) => $read && css`
    background-color: #f0f0f0;
  `}

  svg {
    width: 18px;
    height: 18px;
  }

  p {
    font-size: 1.1rem;
  }

  &:hover {
    background: #FFF !important;
    cursor: default;
  }
`;

export const CustomMenuItem = styled(MenuItemMui)`
  font-size: 0.75rem;
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.5rem;
  max-width: 400px;
  min-width: 300px;
  width: 100%;

  ${({ $read }) => $read && css`
    background-color: #f0f0f0;
  `}

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const TooltipChildren = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;
