import { useRef, useState } from 'react';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';

import logoComTrials from 'assets/logo-com-trials.svg';
import logoComsentimento from 'assets/logo-comsentimento-horizontal.svg';

import useAuth from 'context/auth/useAuth';
import * as S from './styles';

export default function Form({ setIsLoading }) {
  const { handleLogin } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const rememberField = useRef(false);
  const formIsValid = email !== '' && password !== '';

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    await handleLogin({
      email,
      password,
      remember: rememberField.current.checked,
    });

    setIsLoading(false);
  }

  return (
    <S.Container>
      <S.Card>
        <S.Header>
          <img src={logoComTrials} alt="com+trials inside" width={200} />
          <p>
            O com+trials é a ferramenta de tratamento de dados
            <br />
            especializada em NLP na área de saúde.
          </p>
        </S.Header>

        <S.Form>
          <TextField
            id="email"
            InputProps={{ 'data-testid': 'email' }}
            label="Digite seu e-mail"
            variant="outlined"
            size="small"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />

          <TextField
            id="password"
            InputProps={{ 'data-testid': 'password' }}
            type="password"
            label="Digite sua Senha"
            variant="outlined"
            size="small"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />

          <FormControlLabel control={<Checkbox defaultChecked />} label="Lembrar-me" inputRef={rememberField} />

          <S.LoginButton
            variant="contained"
            type="submit"
            disabled={!formIsValid}
            onClick={handleSubmit}
          >Acessar
          </S.LoginButton>
        </S.Form>
      </S.Card>

      <S.Footer>
        <img src={logoComsentimento} alt="Comsentimento" width={220} />
        <span>2023 | Comsentimento&#174;</span>
      </S.Footer>
    </S.Container>
  );
}
