export default function getTokenFromStorage() {
  function getCookie(name) {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i += 1) {
      const [key, value] = cookies[i].split('=');
      if (key.trim() === name) {
        return value;
      }
    }

    return undefined;
  }

  const tokenCookie = getCookie('token');
  const data = JSON.parse(sessionStorage.getItem('token')) || (tokenCookie && JSON.parse(tokenCookie));

  if (!data) return false;

  const { token } = data;

  const dataUser = {
    token,
    customerId: data.customerId,
    customerName: data.customerName,
    name: data.name,
    surname: data.surname,
    userId: data.userId,
    permissions: data.permissions,
  };

  return { token, dataUser };
}
