import styled from 'styled-components';

export const Container = styled.div`
  font-size: 0.75rem;
  font-weight: 600;
  color: #001D35;
  letter-spacing: 0.5px;

  background: #E3DFFF;
  border-radius: 20px;
  padding: 0.5rem 0.75rem;
`;
