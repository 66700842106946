import { Pagination as PaginationMui } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  background: #F6F8FC;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  height: 100%;
`;

export const Info = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0.25rem 0;

  span:first-child{
    font-weight: 500;
  }

  span:nth-child(2){
    text-align: center;
  }

  span:last-child{
    text-align: right;
  }
`;

export const TextContainer = styled.div`
  height: 100%;
  width: 100%;
  background: #E5E1E6;
  border-radius: 8px;
  padding: 1rem 1rem 1rem 0;
`;

export const Text = styled.textarea`
  background: #E5E1E6;
  border: 0;
  border-radius: 8px;
  outline: 0;
  color: #3C3C3C;
  font-size: 1rem;
  padding: 0 2rem;
  resize: none;
  width: 100%;
  min-height: 100%;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    margin: 1.25rem 0;
    background-color: #FFF; 
    border-radius: 4px;
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #B8B8B8;
    width: 6px;
    border-radius: 4px;
    background-clip: content-box;
  }
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;

  p{
    color: #1F1F1F;
    font-size: 0.75rem;
  }
`;

export const Input = styled.input`
  appearance: none;
  border-radius: 2px;
  border: 2px solid #D9D9D9;
  padding: 2px 4px;
  outline: none;
  width: 44px;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:out-of-range{
    border: 2px solid #BA1A1A;
  }
`;

export const Pagination = styled(PaginationMui)`
  .Mui-selected {
    background: transparent;
    border: 1px solid black;
  }
`;

export const GoToField = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;
