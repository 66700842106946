import { memo, useRef, useState } from 'react';
import {
  Checkbox, ListItemText, MenuItem, RadioGroup, Select,
} from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

import PatientServices from 'services/PatientServices';
import {
  patientStatus, patientStatusNames, patientStatusRequestsNames,
} from 'constants/patientStatus';
import { optionsByElebility, patientElegibility } from 'constants/patientElegibility';
import { toast } from 'react-toastify';
import useAuth from 'context/auth/useAuth';
import defaultSubStatusByOptions from 'utils/defaultSubStatusByOptions';
import { isAdmin } from 'utils/isAdmin';
import useModal from 'hooks/useModal';
import Modal from 'components/Modal';
import * as S from './styles';
import Button from '../Button';
import SendNotification from '../SendNotification';

function Form({
  patient, loadPatients, onClose, inputObservations, studyId, studyName,
}) {
  const { dataUserAuthenticated } = useAuth();
  const [elegibility, setElegibility] = useState(() => {
    if (patient.is_elegible === patientElegibility.YES) {
      return patientElegibility.YES;
    }
    if (patient.is_elegible === patientElegibility.NO) {
      return patientElegibility.NO;
    }
    return '';
  });
  const [status, setStatus] = useState(patient.status ? [patientStatusNames[patient.status]] : []);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const selectRef = useRef(null);
  const completedForm = !!patient.status;
  const admin = isAdmin(dataUserAuthenticated?.permissions);
  const formIsValid = !completedForm && (
    (elegibility && status.length > 0) || (elegibility && admin)
  );
  const {
    handleOpenModal: handleOpenSendNotification,
    handleCloseModal: handleCloseSendNotification,
    visible: visibleSendNotification,
  } = useModal();

  function handleChangeElegibility(event) {
    setElegibility(event.target.value);
    setStatus([]);
  }

  function handleChangeStatus(event) {
    const { value } = event.target;
    setStatus(value);

    if (value === optionsByElebility[patientElegibility.YES].option7) {
      setTimeout(() => {
        inputObservations.current.focus();
      }, 0);
    }
  }

  function handleBlurSelect(event) {
    const { value } = event.target;
    if (value.includes(optionsByElebility[patientElegibility.NO].option3)) {
      selectRef.current.blur();
      setTimeout(() => {
        inputObservations.current.focus();
      }, 0);
    }
  }
  function statusByOption() {
    if (status === optionsByElebility[elegibility].option1) {
      return patientStatus.IN_INVESTIGATION;
    }
    if (status === optionsByElebility[elegibility].option2) {
      return patientStatus.IN_CONTACT;
    }
    if (status === optionsByElebility[elegibility].option3) {
      return patientStatus.IN_CONSULTATION;
    }
    if (status === optionsByElebility[elegibility].option4) {
      return patientStatus.IN_PRE_SCREENING;
    }
    if (status === optionsByElebility[elegibility].option6) {
      return patientStatus.IN_INVESTIGATION;
    }

    return patientStatus.CONCLUDED;
  }

  async function handleSubmit(event) {
    if (event) {
      event.preventDefault();
    }
    setIsSubmitting(true);

    const newStatus = statusByOption();
    const substatus = defaultSubStatusByOptions({ newStatus, status, elegibility });

    const data = {
      status: newStatus,
      substatus,
      author_id: dataUserAuthenticated.userId,
      study_id: studyId,
      patientId: patient.id,
      is_elegible: elegibility,
    };

    try {
      await PatientServices.sendFeedback(data);

      if (admin) {
        const elegibilityText = String(elegibility) === String(patientElegibility.YES) ? 'Elegíveis' : 'Inelegíveis';
        toast.success(`Paciente enviado para a aba Potencialmente ${elegibilityText}`, {
          icon: <CheckCircleOutlineOutlinedIcon />,
        });
      } else {
        toast.success(`Paciente enviado para a aba ${patientStatusNames[newStatus]}`, {
          icon: <CheckCircleOutlineOutlinedIcon />,
        });
      }

      onClose();
      loadPatients({ clearSearch: true });
      loadPatients({ currentStatus: patientStatusRequestsNames[newStatus] });
    } catch (error) {
      toast.error('Erro ao avaliar paciente');
      onClose();
    } finally {
      setIsSubmitting(false);
    }
  }

  function handleSendFeedback() {
    if (String(elegibility) === String(patientElegibility.YES)) {
      handleOpenSendNotification();
    } else {
      handleSubmit();
    }
  }

  return (
    <S.Container onSubmit={handleSubmit}>
      <S.Questions>
        <S.FormControlCustom>
          <S.FormLabelCustom>
            O paciente indicado corresponde ao <br /> perfil desejado para este estudo?
          </S.FormLabelCustom>
          <RadioGroup
            row
            aria-labelledby="elegibility patient"
            value={elegibility}
            onChange={handleChangeElegibility}
            id="elegibility"
          >
            <S.FormControlLabelCustom
              value={patientElegibility.YES}
              control={<S.RadioCustom size="small" />}
              label="Sim"
              disabled={completedForm}
            />
            <S.FormControlLabelCustom
              value={patientElegibility.NO}
              control={<S.RadioCustom size="small" />}
              label="Não"
              disabled={completedForm}
            />
          </RadioGroup>
        </S.FormControlCustom>

        {!admin && (
          String(elegibility) === String(patientElegibility.NO) || String(elegibility) === String(patientElegibility.YES)
        ) && (
        <S.FormControlCustom>
          <S.FormLabelCustom>Qual próximo passo gostaria de realizar?</S.FormLabelCustom>

          {String(elegibility) === String(patientElegibility.YES) && (
          <Select
            id="next-step"
            size="small"
            value={status}
            defaultValue=""
            displayEmpty
            onChange={handleChangeStatus}
            disabled={completedForm}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return 'Selecione a próxima etapa...';
              }
              return selected;
            }}
          >
            {Object.values(optionsByElebility[elegibility]).map((option) => (
              <MenuItem
                key={option}
                value={option}
              >
                {option}
              </MenuItem>
            ))}
          </Select>
          )}

          {String(elegibility) === String(patientElegibility.NO) && (
          <Select
            id="next-step"
            size="small"
            value={status}
            onChange={handleChangeStatus}
            disabled={completedForm}
            onBlur={handleBlurSelect}
            multiple
            defaultValue=""
            displayEmpty
            ref={selectRef}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return 'Selecione o motivo';
              }
              return (selected.length > 1 ? 'Mais de um campo selecionado' : selected.join(''));
            }}
          >
            {Object.values(optionsByElebility[elegibility]).map((option) => (
              <MenuItem
                key={option}
                value={option}
              >
                <Checkbox checked={status.indexOf(option) > -1} />
                <ListItemText primary={option} />
              </MenuItem>
            ))}
          </Select>
          )}
        </S.FormControlCustom>
        )}

        {admin && (String(elegibility) === String(patientElegibility.YES)) && (
          <Modal visible={visibleSendNotification}>
            <SendNotification
              onClose={handleCloseSendNotification}
              onSubmit={handleSubmit}
              patient={patient.aes || patient.sha}
              studyName={studyName}
              studyID={studyId}
            />
          </Modal>
        )}
      </S.Questions>

      <Button
        type={admin ? 'button' : 'submit'}
        formIsValid={formIsValid}
        isSubmitting={isSubmitting}
        onClick={admin ? handleSendFeedback : undefined}
      >
        Avaliar
      </Button>
    </S.Container>
  );
}

export default memo(Form);
