import { Autocomplete } from '@mui/material';
import * as Form from 'components/Filter';
import { AccepetedValues } from 'constants/inspectors';

export default function Custom({
  filter, values, value, onChange,
}) {
  function areArraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;

    return arr1.every((item) => arr2.includes(item));
  }

  if (areArraysEqual(AccepetedValues.SIM_NAO, values)
    || areArraysEqual(AccepetedValues.NORMAL_MUTADO, values)
    || areArraysEqual(AccepetedValues.POSITIVO_NEGATIVO, values)
    || areArraysEqual(AccepetedValues.RESISTENTE_SENSIVEL, values)
    || areArraysEqual(AccepetedValues.PRIMEIRA_SEGUNDA_LINHA, values)
  ) {
    return (
      <Form.Radio
        name={filter}
        options={values}
        value={value}
        onChange={onChange}
      />
    );
  }

  if (areArraysEqual(AccepetedValues.NUMERICO_FLOAT, values)
    || areArraysEqual(AccepetedValues.NUMERICO_INTEIRO, values)
  ) {
    return (
      <Form.TextField
        id={filter}
        name={filter}
        value={value}
        onChange={onChange}
      />
    );
  }

  if (areArraysEqual(AccepetedValues['0_1'], values)
    || areArraysEqual(AccepetedValues['1_2_3_4'], values)
    || areArraysEqual(AccepetedValues.A_B_C, values)
    || areArraysEqual(AccepetedValues.X_0_1_2_3_4, values)
  ) {
    return (
      <Autocomplete
        disablePortal
        size="small"
        id={filter}
        options={values}
        getOptionLabel={(option) => option}
        inputValue={value || ''}
        onInputChange={(event, newInputValue) => {
          if (!event) return;
          onChange({ target: { name: filter, value: newInputValue } });
        }}
        isOptionEqualToValue={(option, valueInput) => {
          if (valueInput === '') return true;
          return option === valueInput;
        }}
        filterSelectedOptions
        noOptionsText="Valor inválido"
        renderInput={(params) => (
          <Form.TextField
            {...params}
            name={filter}
          />
        )}
      />
    );
  }
}
