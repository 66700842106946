import { Link, useNavigate } from 'react-router-dom';

import TopicOutlinedIcon from '@mui/icons-material/TopicOutlined';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import useAuth from 'context/auth/useAuth';
import logo from 'assets/logo-comsentimento.svg';
import PatientServices from 'services/PatientServices';

import {
  BookmarkBorder, Search, LogoutOutlined, HomeOutlined,
  ShareOutlined,
} from '@mui/icons-material';
import * as S from './styles';

export default function NavBar() {
  const currentPage = window.location.href;
  const { dataUserAuthenticated } = useAuth();
  const navigate = useNavigate();

  function handleResetTest() {
    try {
      PatientServices.testReset();
      alert('Reset com sucesso');
      navigate(0);
    } catch {
      alert('Erro no reset');
    }
  }

  return (
    <S.Container>
      <S.Menu>
        {dataUserAuthenticated?.permissions.includes('admin') && (
          <S.Links>
            <S.ListItem isActive={currentPage.includes('clientes')}>
              <Link to="/clientes">
                <HomeOutlined />
                <p>Home</p>
              </Link>
            </S.ListItem>

            <S.ListItem isActive={currentPage.includes('referral')}>
              <Link to="/referral">
                <ShareOutlined />
                <p>Referral</p>
              </Link>
            </S.ListItem>
          </S.Links>
        )}

        {!dataUserAuthenticated?.permissions.includes('admin') && (
          <S.Links>
            {dataUserAuthenticated?.permissions.includes('recruitment') && (
              <S.ListItem isActive={currentPage.includes('estudos')}>
                <Link to="/estudos">
                  <TopicOutlinedIcon />
                  <p>Estudos</p>
                </Link>
              </S.ListItem>
            )}
            {dataUserAuthenticated?.permissions.includes('search') && (
              <>
                <S.ListItem>
                  <Link to="/busca">
                    <Search />
                    <p>Busca</p>
                  </Link>
                </S.ListItem>
                <S.ListItem isActive={currentPage.includes('favoritos')}>
                  <Link to="/busca/favoritos">
                    <BookmarkBorder />
                    <p>Listas de Favoritos</p>
                  </Link>
                </S.ListItem>
              </>
            )}
            {dataUserAuthenticated?.permissions.includes('referral_send') && (
              <S.ListItem isActive={currentPage.includes('pacientes-enviados')}>
                <Link to="/pacientes-enviados">
                  <LogoutOutlined sx={{ transform: 'rotate(-90deg)' }} />
                  <p>Pacientes Enviados</p>
                </Link>
              </S.ListItem>
            )}
            {dataUserAuthenticated?.permissions.includes('referral_receive') && (
              <S.ListItem isActive={currentPage.includes('pacientes-recebidos')}>
                <Link to="/pacientes-recebidos">
                  <LogoutOutlined sx={{ transform: 'rotate(90deg)' }} />
                  <p>Pacientes Recebidos</p>
                </Link>
              </S.ListItem>
            )}
            {dataUserAuthenticated.userId === '51007cda-7eb8-41ea-8bcd-87d491f02581' && (
              <S.ListItemWithButton onClick={handleResetTest}>
                <div>
                  <RestartAltIcon />
                  <p>reset test</p>
                </div>
              </S.ListItemWithButton>
            )}
          </S.Links>
        )}
      </S.Menu>
      <S.Footer>
        <img src={logo} alt="Logo Comsentimento" />
        <p>
          <span>2023</span>
          <span>Comsentimento<sup>&#174;</sup></span>
          <span>v2.0</span>
        </p>
      </S.Footer>
    </S.Container>
  );
}
