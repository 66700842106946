import { MenuItem, Select } from '@mui/material';
import styled from 'styled-components';

export const CustomSelect = styled(Select)`
  max-width: 100%;
  color: #5F6368;
  font-weight: 500;
  font-size: 0.875rem;

  .MuiSelect-select {
    padding: 0;
  }

  input, fieldset {
    border: 0 !important;
  }
`;

export const CustomMenuItem = styled(MenuItem)`
  color: #5F6368;
  font-weight: 500;
  font-size: 0.75rem;

`;
