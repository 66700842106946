import { Slider as SliderMui, styled } from '@mui/material';
import { useState } from 'react';

const StyledSlider = styled(SliderMui)({
  color: '#2563EB',
  height: 4,
  '& .MuiSlider-rail': {
    border: 'none',
    backgroundColor: '#DBEAFE',
  },
  '& .MuiSlider-track': {
    border: 'none',
    backgroundColor: '#2563EB',
  },
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: '#2563EB',
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabelLabel': {
    color: '#fff',
    fontSize: 12,
    fontFamily: 'Readex Pro',
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1,
    background: 'unset',
    padding: 0,
    width: 28,
    height: 28,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#1E293B',
    color: '#fff',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

export default function Slider() {
  const [value, setValue] = useState([20, 37]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <StyledSlider
      getAriaLabel={() => 'Range'}
      value={value}
      onChange={handleChange}
      valueLabelDisplay="auto"
    />
  );
}
