import { TableBody } from '@mui/material';
import EmptyTableCell from 'components/EmptyTableCell';
import Layout from 'components/Layout';
import Loader from 'components/Loader';
import { Section } from 'components/Section';
import TableData from 'components/TableData';
import TitlePage from 'components/TitlePage';
import { useEffect, useState } from 'react';
import SemanticSearchServices from 'services/SemanticSearchServices';
import PatientRow from '../components/PatientRow';

export default function ReferralAdmin() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const tableHeaderItems = [
    { content: 'Identificação', value: 'aes', isCustomized: true },
    { content: 'Estudo Sugerido', value: 'study_name' },
    { content: 'Centro de Origem', value: 'customer_source_name' },
    { content: 'Centro de Destino', value: 'customer_recipient_name' },
    { content: 'Status', value: 'status' },
    { content: 'Data do Envio', value: 'send_date', isCustomized: true },
  ];

  useEffect(() => {
    (async () => {
      try {
        const response = await SemanticSearchServices.listAllReferral();
        setData(response);
      } catch {
        setError(true);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Layout>
      <Section>
        <TitlePage
          title="Referral"
          subtitle="Aqui você encontrará todos os pacientes que foram compartilhados entre centros."
        />
      </Section>

      {loading && (
        <Loader />
      )}

      {!loading && (
        <Section>
          <TableData headItems={tableHeaderItems} label="Lista de Pacientes enviados para outros centros">
            <TableBody>
              {error && (
                <EmptyTableCell message="Erro ao carregar pacientes" columns={tableHeaderItems?.lenght} />
              )}

              {!error && data.length > 0 && (
                data.map((patient) => (
                  <PatientRow
                    key={patient.id || patient.PATIENT_CID_id}
                    patient={patient}
                    columns={tableHeaderItems}
                  />
                ))
              )}
            </TableBody>
          </TableData>
        </Section>
      )}
    </Layout>
  );
}
