import { Button } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  flex-grow: 1;
  flex-basis: 50%;
  background: #F6F8FC;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Card = styled.div`
  background: #FFF;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 2.5rem 2rem;
  width: 100%;
  max-width: 400px;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;

  p {
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.45);
    line-height: 1.375rem;
    text-align: center;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  input, button {
    width: 100%;
  }

  .MuiTextField-root:first-of-type{
    margin-bottom: 1rem;
  }
  

`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  position: absolute;
  bottom: 5%;

  span {
    font-weight: 500;
    color: #B8B8B8;
    font-size: 0.75rem;
  }
`;

export const LoginButton = styled(Button)`
  width: 100%;
  text-transform: none;
  color: #FFF;
  font-size: 0.875rem;
  font-weight: 400;
  background: #604FFF;
  border-radius: 100px;
  box-shadow: none;
  padding: 0.5rem 2rem;
  height: 40px;

  &:hover{
    background: #604FFF;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
  }
  &:active{
    background: #604FFF;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
  }
`;
