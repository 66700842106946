import { useEffect, useState } from 'react';
import { TableBody } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';

import EmptyTableCell from 'components/EmptyTableCell';
import Layout from 'components/Layout';
import Loader from 'components/Loader';
import { Section } from 'components/Section';
import TableData from 'components/TableData';
import TitlePage from 'components/TitlePage';

import ShareServices from 'services/ShareServices';

import CloseButton from 'components/Toast/CloseButton';
import {
  patientStatusShared, referralPatientsNextStep, referralStatusNames, requestReferralTypes,
} from 'constants/patientStatus';

import TabMenu from '../components/TabMenu';
import PatientRow from '../components/PatientRow';

import * as S from '../styles';

export default function ReceivedPatients() {
  const [patients, setPatients] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [status, setStatus] = useState(patientStatusShared.AWAIT_DESTINY);
  const [qtyOfPatients, setQtyOfPatients] = useState({});

  const tabs = [
    { label: 'Aguardando Aprovação', status: patientStatusShared.AWAIT_DESTINY },
    { label: 'Aguardando consentimento', status: patientStatusShared.AWAIT_ASSIGN },
    { label: 'Pacientes Validados', status: patientStatusShared.ACCEPTED },
    { label: 'Pacientes Rejeitados', status: patientStatusShared.REJECTED },
  ];

  const tableHeaderItems = {
    [patientStatusShared.AWAIT_DESTINY]: [
      { content: 'Identificação', value: 'aes', isCustomized: true },
      { content: 'Estudo Sugerido', value: 'study_name' },
      { content: 'Centro de Origem', value: 'customer_name' },
      { content: 'Data do Envio', value: 'send_date', isCustomized: true },
      { content: 'Ações', align: 'center', isCustomized: true },
    ],
    [patientStatusShared.AWAIT_ASSIGN]: [
      { content: 'Identificação', value: 'aes', isCustomized: true },
      { content: 'Estudo Sugerido', value: 'study_name' },
      { content: 'Centro de Origem', value: 'customer_name' },
      { content: 'Data do Envio', value: 'send_date', isCustomized: true },
    ],
    [patientStatusShared.ACCEPTED]: [
      { content: 'Identificação', value: 'aes', isCustomized: true },
      { content: 'Estudo Sugerido', value: 'study_name' },
      { content: 'Centro de Origem', value: 'customer_name' },
      { content: 'Data do Envio', value: 'send_date', isCustomized: true },
      { content: 'Data Aceito', value: 'accepted_date', isCustomized: true },
      { content: 'Contato', align: 'center', isCustomized: true },
    ],
    [patientStatusShared.REJECTED]: [
      { content: 'Identificação', value: 'aes', isCustomized: true },
      { content: 'Estudo Sugerido', value: 'study_name' },
      { content: 'Centro de Origem', value: 'customer_name' },
      { content: 'Data do Envio', value: 'send_date', isCustomized: true },
    ],
  };

  async function loadPatients() {
    try {
      const patientsResponse = await ShareServices.listSharedPatients(requestReferralTypes.RECEIVED);
      setPatients(patientsResponse);
      setQtyOfPatients({
        [patientStatusShared.AWAIT_DESTINY]: patientsResponse[patientStatusShared.AWAIT_DESTINY]?.length,
        [patientStatusShared.AWAIT_ASSIGN]: patientsResponse[patientStatusShared.AWAIT_ASSIGN]?.length,
        [patientStatusShared.ACCEPTED]: patientsResponse[patientStatusShared.ACCEPTED]?.length,
        [patientStatusShared.REJECTED]: patientsResponse[patientStatusShared.REJECTED]?.length,
      });
    } catch {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    loadPatients();
  }, []);

  async function handleReply(referralID, reply) {
    const replyStatus = reply === 1 ? referralPatientsNextStep[status] : referralStatusNames[patientStatusShared.REJECTED];
    try {
      setIsLoading(true);
      await ShareServices.replyPatient({
        referralID,
        reply: replyStatus,
      });
      toast.success('Paciente atualizado com sucesso');
      loadPatients();
    } catch {
      toast.error('Erro ao atualizar paciente');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Layout>
      <ToastContainer
        position="bottom-left"
        autoClose={false}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        closeButton={CloseButton}
      />
      <Section>
        <TitlePage
          title="Pacientes recebidos de outros centros"
          subtitle="Aqui você encontrará pacientes que outros centros enviaram como sugestão para participar de seus estudos."
        />
      </Section>

      {isLoading && (
        <Loader />
      )}

      {!isLoading && (
        <S.Container>
          <S.SectionFullWidth>
            <TabMenu
              tabs={tabs}
              status={status}
              setStatus={setStatus}
              isLoading={isLoading}
              error={error}
              qtyOfPatients={qtyOfPatients}
            />
            <TableData headItems={tableHeaderItems[status]} label="Lista de Pacientes Recebidos de outros centros">
              <TableBody>
                {error && (
                <EmptyTableCell message="Erro ao carregar pacientes" columns={tableHeaderItems[status]?.lenght} />
                )}

                {!error && !qtyOfPatients[status] && (
                <EmptyTableCell message="Não há pacientes para serem mostrados aqui" columns={tableHeaderItems[status]?.lenght} />
                )}

                {!error && qtyOfPatients[status] > 0 && (
                  patients[status].map((patient) => (
                    <PatientRow
                      key={patient.id || patient.PATIENT_CID_id}
                      patient={patient}
                      onReply={handleReply}
                      status={status}
                      columns={tableHeaderItems[status]}
                    />
                  ))
                )}
              </TableBody>
            </TableData>
          </S.SectionFullWidth>
        </S.Container>
      )}
    </Layout>
  );
}
