import { CircularProgress } from '@mui/material';
import * as S from './styles';

export default function Button({
  type, isSubmitting, formIsValid, children, onClick = undefined,
}) {
  return (
    <S.Container disabled={!formIsValid || isSubmitting} variant="contained" type={type} onClick={onClick}>
      {isSubmitting ? (
        <CircularProgress size={16} sx={{ color: '#604FFF' }} />
      ) : (
        children
      )}
    </S.Container>
  );
}
