import styled, { css } from 'styled-components';
import { Button as MaterialButton, TextField as MaterialTextField } from '@mui/material';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background: #fff;
  border: 1px solid rgba(96, 79, 255, 0.3);
  border-radius: 1rem;
  padding: 2rem 1.5rem 1rem 1.5rem;
  max-width: 468px;
  font-size: 0.875rem;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  color: #49454F;

  h4 {
    font-weight: 400;
    font-size: 1.35rem;
  }

  p {
    font-weight: 700;
    font-size: 0.8rem;
  }

  span {
    font-weight: 500;
    font-size: 0.75rem;
  }
`;

export const Content = styled.div`
  width: 100%;
`;

export const TexField = styled(MaterialTextField)`
  width: 100%;
  
  label, legend, textarea {
    font-size: 0.875rem;
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #604FFF;
  }

  label.Mui-focused{
    color: #604FFF;
  }
  
`;

export const Actions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
`;

export const Button = styled(MaterialButton)`
  border-radius: 16px;
  color: #FFF;
  background: #604FFF;
  font-weight: 400;
  box-shadow: none;
  text-transform: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  ${({ variant }) => variant === 'ghost' && css`
    color: #6750A4;
    background: #F6F8FC;
    font-weight: 500;
  `}
`;
