import { BrowserRouter } from 'react-router-dom';

import AppProvider from 'context';
import Router from './routes';

import GlobalStyle from './styles/global';
import 'react-toastify/dist/ReactToastify.css';

export default function App() {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <AppProvider>
        <Router />
      </AppProvider>
    </BrowserRouter>
  );
}
