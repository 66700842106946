import styled from 'styled-components';

export const Container = styled.div`
  background: #FFF;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 1rem;
  width: ${({ size }) => (size === 'large' ? '55%' : '40%')};
  max-width: 575px;
  padding: 0.4rem 1.5rem;
  border: 2px solid transparent;
  height: 100%;

  &:focus-within {
    border: 2px solid #6750A4;
  }
`;

export const InputSearch = styled.input`
  border: none;
  outline: none;
  background: #FFF;
  width: 100%;
  color: #5F6368;
  font-weight: 500;
  font-size: 0.875rem;

  &::placeholder {
    color: #5F6368;
    font-weight: 500;
    font-size: 0.875rem;
  }
`;

export const Separator = styled.div`
  width: 2px;
  height: 24px;
  background: #5F6368;
`;
