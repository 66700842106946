import { useContext } from 'react';
import { Reid } from './ReidContext';

export default function useReid() {
  const {
    handleReidSubmit,
    reidentify,
    getAESByReid,
    password,
    someReidentifiedPatient,
    visibleTasyCode,
    showTasyCode,
    reidentifyTasyCode,
    availableToReidTasyCode,
    someTasyCodePatient,
  } = useContext(Reid);

  return {
    handleReidSubmit,
    reidentify,
    getAESByReid,
    password,
    someReidentifiedPatient,
    visibleTasyCode,
    showTasyCode,
    reidentifyTasyCode,
    availableToReidTasyCode,
    someTasyCodePatient,
  };
}
