import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

export const Filter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  gap: 0.5rem;

  background: #FFF;
  border: 1px solid #E5E1E6;
  border-radius: 1rem;
  padding: 0.25rem 0.5rem;

  svg {
    width: 1rem;
    height: 1rem;
  }
`;

export const Content = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: center;
`;
