import { Link } from 'react-router-dom';

import Layout from 'components/Layout';
import { Section } from 'components/Section';
import TitlePage from 'components/TitlePage';
import Loader from 'components/Loader';
import { ErrorContainer } from 'components/ErrorContainer';

import formatDateToPtBr from 'utils/formatDateToPtBr';
import useFavoriteLists from 'context/favoriteLists/useFavoriteLists';
import { useEffect } from 'react';
import * as S from './styles';

export default function FavoritesList() {
  const {
    lists, loading, error, loadLists,
  } = useFavoriteLists();

  useEffect(() => {
    loadLists();
  }, [loadLists]);

  return (
    <Layout>
      <Section>
        <TitlePage
          title="Listas de Favoritos"
          subtitle="Salve pacientes em listas para encontra-lós mais facilmente."
        />
      </Section>

      {loading && <Loader />}

      <Section>
        {(error && !loading) && (
        <ErrorContainer>
          <span>Erro ao carregar listas</span>
        </ErrorContainer>
        )}
        {!error && !loading && lists.length === 0 && (
        <ErrorContainer>
          <span>Nenhuma lista encontrada</span>
        </ErrorContainer>
        )}

        {!error && !loading && lists.length > 0 && (
        <S.Cards>
          {lists.map((list) => (
            <Link to={list.id} key={list.id}>
              <S.Card>
                <h3>{list.name}</h3>
                <S.Infos>
                  <span>{list.qty_patients} Pacientes</span>
                  <S.UpdatedAt>
                    <span>Criada em </span>
                    <span>{list.created_at && formatDateToPtBr(list.created_at)}</span>
                  </S.UpdatedAt>
                </S.Infos>
              </S.Card>
            </Link>
          ))}
        </S.Cards>
        )}

      </Section>

    </Layout>
  );
}
