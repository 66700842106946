import { FormControlLabel, Radio as RadioMui } from '@mui/material';
import * as S from './styles';

export default function Radio({
  options, value, onChange, name, ...props
}) {
  function handleChange(event) {
    onChange(event);
  }

  function handleClick(event) {
    if (event.target.value === value) {
      onChange({ target: { value: '', name } });
    }
  }

  return (
    <S.Group
      row
      value={value ?? ''}
      onChange={handleChange}
      onClick={handleClick}
    >
      {options?.map((option) => (
        <FormControlLabel
          key={option}
          value={option}
          label={option}
          name={name}
          control={(<RadioMui color="primary" size="small" checked={value === option} />)}
          {...props}
        />
      ))}
    </S.Group>
  );
}
