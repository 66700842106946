import { memo, useState } from 'react';
import * as S from './styles';

function HealthRecord({ healthRecords }) {
  const totalPages = healthRecords?.length;
  const [currentPage, setCurrentPage] = useState(totalPages);
  const [inputPage, setInputPage] = useState('');

  function handleChangeGoToPage(event) {
    event.preventDefault();

    setCurrentPage(Number(inputPage));
  }

  function handleChangePage(event, value) {
    setCurrentPage(value);
  }

  function formatStringDate({ day, month, year }) {
    let date = `${String(day).padStart(2, 0)}/`;
    date += `${String(month).padStart(2, 0)}/`;
    date += String(year);

    return date;
  }

  return (
    <S.Container>
      <S.Info>
        <span>Notas Clínicas do Paciente</span>
        <span>
          {formatStringDate({
            day: healthRecords[currentPage - 1]?.day,
            month: healthRecords[currentPage - 1]?.month,
            year: healthRecords[currentPage - 1]?.year,
          })}
        </span>
        <span>{currentPage}/{totalPages}</span>
      </S.Info>

      <S.TextContainer>
        <S.Text disabled value={healthRecords[currentPage - 1]?.text} />
      </S.TextContainer>

      <S.Footer>
        <S.Pagination
          count={totalPages}
          onChange={handleChangePage}
          size="small"
          shape="rounded"
          page={currentPage}
        />
        <S.GoToField onSubmit={handleChangeGoToPage}>
          <p>Ir para</p>
          <S.Input
            type="number"
            min={1}
            max={totalPages}
            value={inputPage}
            onChange={(event) => setInputPage(event.target.value)}
          />
          <p>Total de Notas Clínicas: {totalPages}</p>
        </S.GoToField>
      </S.Footer>

    </S.Container>
  );
}

export default memo(HealthRecord);
