import { useState } from 'react';

export default function useMenu() {
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const openMenu = Boolean(anchorElMenu);

  function handleOpen(event) {
    setAnchorElMenu(event.currentTarget);
  }

  function handleClose() {
    setAnchorElMenu(null);
  }

  return {
    anchorElMenu, openMenu, handleOpen, handleClose,
  };
}
