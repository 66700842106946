/* eslint-disable no-useless-escape */
import { useState } from 'react';
import { CircularProgress } from '@mui/material';

import InputPassword from 'pages/Profile/components/InputPassword/InputPassword';

import CustomerServices from 'services/CustomerServices';
import * as S from './styles';

export default function Form({ email }) {
  const [currentPassword, setCurrentPassword] = useState('');
  const [currentPasswordIsValid, setCurrentPasswordIsValid] = useState(true);
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordIsValid, setNewPasswordIsValid] = useState(false);
  const [helperTextNewPassword, setHelperTextNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  function validatePasswordStrength(password) {
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[~`!@#$%^&*()_\-+={[}\]|:;"'<,>.?\\\/]).{8,}$/;
    if (regex.test(password)) {
      setNewPasswordIsValid(true);
      setHelperTextNewPassword('');

      return;
    }

    setNewPasswordIsValid(false);
    setHelperTextNewPassword('A senha deve conter letras minúsculas, letras maiúsculas, números e símbolos.');
  }

  function handleChangeCurrentPassword(event) {
    setCurrentPassword(event.target.value);
  }

  function handleChangeNewPassword(event) {
    setNewPassword(event.target.value);

    if (event.target.value.length < 8) {
      setHelperTextNewPassword('Senha curta. Deve ter no mínimo 8 caracteres');
      setNewPasswordIsValid(false);
      return;
    }

    validatePasswordStrength(event.target.value);
  }

  function handleChangeConfirmNewPassword(event) {
    setConfirmNewPassword(event.target.value);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      setCurrentPasswordIsValid(true);

      await CustomerServices.changePassword({
        email,
        currentPassword,
        newPassword,
      });

      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
      alert('Senha alterada com sucesso');
    } catch {
      setCurrentPasswordIsValid(false);
    } finally {
      setIsSubmitting(false);
    }
  }

  const formIsValid = currentPassword.length > 0 && newPasswordIsValid && newPassword === confirmNewPassword;

  return (
    <S.Container onSubmit={handleSubmit}>
      <InputPassword
        id="current-password"
        inputLabel="Digite sua senha antiga"
        error={!currentPasswordIsValid}
        value={currentPassword}
        onChange={handleChangeCurrentPassword}
        helperText="Senha Incorreta"
      />

      <InputPassword
        id="new-password"
        inputLabel="Digite sua nova senha"
        value={newPassword}
        onChange={handleChangeNewPassword}
        error={newPassword.length > 0 && !newPasswordIsValid}
        helperText={helperTextNewPassword}
      />

      <InputPassword
        id="confirm-new-password"
        inputLabel="Redigite sua nova senha"
        error={newPassword !== confirmNewPassword}
        value={confirmNewPassword}
        onChange={handleChangeConfirmNewPassword}
        helperText="As senhas são diferentes"
      />

      <S.SaveButton variant="contained" type="submit" disabled={!formIsValid || isSubmitting}>
        {isSubmitting ? (
          <CircularProgress size={24} sx={{ color: '#604FFF' }} />
        ) : (
          'Salvar Senha'
        )}
      </S.SaveButton>
    </S.Container>
  );
}
