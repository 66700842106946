import { Route, Routes } from 'react-router-dom';
import Login from 'pages/Login';
import ProtectedRoute from 'components/ProtectedRoute';
import StudiesList from 'pages/StudiesList';
import PatientsList from 'pages/PatientsList';
import Profile from 'pages/Profile';
import FavoritesList from 'pages/FavoritesList';
import Favorites from 'pages/Favorites';
import Search from 'pages/Search';
import ReceivedPatients from 'pages/ReferralPatients/Received';
import SendedPatients from 'pages/ReferralPatients/Sended';
import CustomersList from 'pages/CustomersList';
import ReferralAdmin from 'pages/ReferralPatients/Admin';

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/clientes" element={<ProtectedRoute permission="admin"><CustomersList /></ProtectedRoute>} />
      <Route path="/referral" element={<ProtectedRoute permission="admin"><ReferralAdmin /></ProtectedRoute>} />
      <Route path="/estudos" element={<ProtectedRoute permission="recruitment"><StudiesList /></ProtectedRoute>} />
      <Route path="/estudos/:id" element={<ProtectedRoute permission="recruitment"><PatientsList /></ProtectedRoute>} />
      <Route path="/perfil/:id" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
      <Route path="/busca" element={<ProtectedRoute permission="search"><Search /></ProtectedRoute>} />
      <Route path="/busca/favoritos" element={<ProtectedRoute permission="search"><FavoritesList /></ProtectedRoute>} />
      <Route path="/busca/favoritos/:id" element={<ProtectedRoute permission="search"><Favorites /></ProtectedRoute>} />
      <Route path="pacientes-enviados" element={<ProtectedRoute permission="referral_send"><SendedPatients /></ProtectedRoute>} />
      <Route
        path="pacientes-recebidos"
        element={<ProtectedRoute permission="referral_receive"><ReceivedPatients /></ProtectedRoute>}
      />
    </Routes>
  );
}
