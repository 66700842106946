import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Layout from 'components/Layout';
import TitlePage from 'components/TitlePage';
import { Section } from 'components/Section';
import StudyServices from 'services/StudyServices';
import Loader from 'components/Loader';
import { barretosTIID } from 'constants/customers';
import * as S from './styles';

export default function CustomersList() {
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const hasCustomers = (!error && !isLoading) && customers.length > 0;

  useEffect(() => {
    (async () => {
      try {
        const { customers: customersList } = await StudyServices.getAllCustomers();
        setCustomers(customersList.filter((customer) => customer.id !== barretosTIID));
      } catch {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  function sliceDate(date) {
    if (!date) {
      return '-';
    }

    return new Intl.DateTimeFormat('pt-BR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    }).format(new Date(date));
  }

  return (
    <Layout>
      <Section>
        <TitlePage
          title="Clientes"
          subtitle="Lista de clientes cadastrados"
        />
      </Section>
      {isLoading && <Loader />}

      {!isLoading && hasCustomers && (
        <Section>
          <S.CustomersContainer>
            {customers.map((customer) => (
              <Link
                to={`/estudos?customer=${customer.id}`}
                key={customer.id}
                state={{ customerName: customer.name }}
              >
                <S.Card>
                  <S.Content>
                    <S.Name>{customer.name}</S.Name>
                    <S.Update>
                      Última Atualização <span /> {sliceDate(customer.updated_at) || '-'}
                    </S.Update>
                  </S.Content>
                </S.Card>
              </Link>
            ))}
          </S.CustomersContainer>
        </Section>
      )}
    </Layout>
  );
}
