import { Checkbox, FormControlLabel } from '@mui/material';

import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { updateSearchParams } from 'utils/updateSearchParams';
import * as S from './styles';

export default function CidsCheckbox({ cids, selectedCIDs, setSelectedCIDs }) {
  const [searchParams, setSearchParams] = useSearchParams();

  function handleToogleMainCid(cid) {
    let newCids = [];
    if (selectedCIDs.indexOf(cid.cid) > -1) {
      newCids = selectedCIDs.filter((selected) => selected !== cid.cid);
      cid.subcids.forEach((subcid) => {
        newCids = newCids.filter((selected) => selected !== subcid);
      });
    } else {
      newCids = [...selectedCIDs, cid.cid, ...cid.subcids];
    }
    setSelectedCIDs(newCids);
    updateSearchParams(searchParams, setSearchParams, 'cid', newCids.join(','));
  }

  function handleChangeSubCid(cid, subcid) {
    let newCids = [];
    if (selectedCIDs.indexOf(subcid) > -1) {
      newCids = selectedCIDs.filter((selected) => selected !== subcid);
      newCids = newCids.filter((selected) => selected !== cid);
    } else {
      newCids = [...selectedCIDs, subcid];
    }
    setSelectedCIDs(newCids);
    updateSearchParams(searchParams, setSearchParams, 'cid', newCids.join(','));
  }

  function verifyAllIsChecked(cid) {
    if (selectedCIDs.indexOf(cid) > -1) {
      return true;
    }

    const { subcids } = cids.find((c) => c.cid === cid);
    return subcids.every((subcid) => selectedCIDs.indexOf(subcid) > -1);
  }

  function verifySomeIsChecked(cid) {
    const { subcids } = cids.find((c) => c.cid === cid);
    return subcids.some((subcid) => selectedCIDs.indexOf(subcid) > -1);
  }

  function verifyIsChecked(cid) {
    return selectedCIDs.indexOf(cid) > -1;
  }

  return (
    <S.Container>
      {cids.map((cid) => (
        <React.Fragment key={cid.cid}>
          <FormControlLabel
            key={cid.cid}
            label={cid.cid}
            control={(
              <Checkbox
                color="secondary"
                size="small"
                checked={verifyAllIsChecked(cid.cid)}
                indeterminate={verifySomeIsChecked(cid.cid) && !verifyAllIsChecked(cid.cid)}
                onChange={() => handleToogleMainCid(cid)}
              />
            )}
          />

          <S.Subcids>
            {cid.subcids.map((subcid) => (
              <FormControlLabel
                key={`${cid}-${subcid}`}
                label={subcid}
                control={(
                  <Checkbox
                    color="secondary"
                    size="small"
                    checked={verifyIsChecked(subcid)}
                    onChange={() => handleChangeSubCid(cid.cid, subcid)}
                  />
              )}
              />
            ))}
          </S.Subcids>
        </React.Fragment>
      ))}
    </S.Container>
  );
}
