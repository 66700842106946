import styled, { css } from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  padding: 1rem;
  background-color: #FFF;
  border-radius: 16px;

  ${({ sendToStudy }) => sendToStudy && css`
    background-color: #F4EFF4;
    border: 1px solid #E2E2E2;
  `}
`;

export const SectionWithActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
`;

export const SectionWithActionsFooter = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: flex-end;
`;

export const InfosContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  width: 100%;

  p, span {
    font-weight: 500;
  }

  span {
    font-size: 0.875rem;
  }
`;

export const CardActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.75rem;
`;

export const ButtonCardActions = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;

  background: transparent;
  border: none;
  outline: none;
  color: #5F6368;
  font-weight: 500;
  font-size: 0.7rem;

  svg, img {
    width: 20px;
    height: 20px;
    color: #5F6368;
  }
`;

export const Disease = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  h3 {
    display: inline-block;
    margin-right: 2rem;
    font-weight: 500;
    font-size: 1.25rem;
  }

  p {
    font-size: 1rem;
    color: #201A1B;
  }
`;

export const Topics = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;

  p {
    font-size: 1rem;
    font-weight: 400;
    color: #201A1B;
  }

  span {
    font-size: 1rem;
    font-weight: 500;
    color: #201A1B;
  }
`;

export const Treaments = styled.span`
  span {
    margin-right: 1rem;
  }
`;

export const AdditionalCount = styled.div`
  color: #001D35;
  font-size: 0.75rem;
  background: #F4EFF4;
  border-radius: 20px;
  padding: 0.5rem 0.75rem;
  font-weight: 600;
`;

export const MenuItemWithIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.75rem;
  font-weight: 500;

  img, svg {
    width: 20px;
    height: 20px;
  }
`;
