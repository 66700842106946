import { createContext, useState } from 'react';
import SemanticSearchServices from 'services/SemanticSearchServices';

export const FilterContext = createContext({});

export default function FilterProvider({ children }) {
  const [filters, setFilters] = useState({});
  const [availableFilters, setAvailableFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  async function loadFilters(cids) {
    setIsLoading(true);
    try {
      const filtersList = await SemanticSearchServices.getFilters(cids);
      setAvailableFilters(filtersList.filters);
    } catch {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <FilterContext.Provider value={{
      filters, setFilters, isLoading, error, availableFilters, loadFilters,
    }}
    >
      {children}
    </FilterContext.Provider>
  );
}
