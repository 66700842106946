import { memo, useState } from 'react';

import { Avatar, CircularProgress } from '@mui/material';
import useAuth from 'context/auth/useAuth';
import PatientServices from 'services/PatientServices';
import { isAdmin } from 'utils/isAdmin';
import * as S from './styles';

import Button from '../Button';
import Tag from './Tag';

function Observations({
  observations, patientId, loadObservations, inputObservations,
}) {
  const [observation, setObservation] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { dataUserAuthenticated } = useAuth();

  function convertDate(dateString) {
    const date = new Date(dateString);

    const dateFormated = new Intl.DateTimeFormat('pt-BR', {
      day: '2-digit', month: '2-digit', year: 'numeric',
    }).format(date);

    return dateFormated;
  }

  function handleChangeObservation(event) {
    setObservation(event.target.value);
  }

  async function handleSubmit(event) {
    setIsSubmitting(true);
    event.preventDefault();

    const data = {
      patient_id: patientId,
      text: observation,
      author_id: dataUserAuthenticated.userId,
    };

    try {
      await PatientServices.createObservation(data);
      await loadObservations();
      setObservation('');
    } catch (error) {
      alert('Erro ao adicionar observação');
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <S.Container>

      {isSubmitting ? (
        <S.ContainerLoader>
          <CircularProgress size={16} sx={{ color: '#604FFF' }} />
        </S.ContainerLoader>
      ) : (
        <div style={{ height: '100%' }}>
          <S.Title>Histórico</S.Title>
          <S.ContainerObservations $isAdmin={isAdmin(dataUserAuthenticated?.permissions)}>
            {observations?.map((obs) => (
              <S.Observation key={obs.id}>
                <Avatar
                  alt={obs.author_name}
                  sx={{
                    height: '32px', width: '32px',
                  }}
                />
                <S.Content>
                  <S.Author>
                    <span>{obs.author_name} </span>
                    <span>{convertDate(obs.created_at)}</span>
                  </S.Author>
                  <p key={obs.id}>{obs.observation}</p>
                  {obs.sub_status && (
                    <Tag
                      isElegible={obs.is_elegible}
                      status={obs.status}
                      substatus={obs.sub_status}
                    />
                  )}
                </S.Content>
              </S.Observation>
            ))}
          </S.ContainerObservations>
        </div>
      )}

      {!isAdmin(dataUserAuthenticated?.permissions) && (
        <S.Form onSubmit={handleSubmit}>
          <S.TexField
            label="Observações"
            placeholder="Escreva detalhes sobre o caso…"
            multiline
            rows={5}
            value={observation}
            onChange={handleChangeObservation}
            inputRef={inputObservations}
            tabIndex={-1}
          />
          <Button type="submit" variant="contained" formIsValid={observation} isSubmitting={isSubmitting}>Comentar</Button>
        </S.Form>
      )}

    </S.Container>
  );
}

export default memo(Observations);
