import { createContext, useState, useRef } from 'react';
import CryptoJS from 'crypto-js';
import { toast } from 'react-toastify';
import useAuth from 'context/auth/useAuth';
import { barretosID, barretosTIID, testeID } from 'constants/customers';
import UtilServices from 'services/UtilServices';
import TasyServices from 'services/TasyServices';

const Reid = createContext();

function ReidProvider({ children }) {
  const { dataUserAuthenticated } = useAuth();
  const [password, setPassword] = useState(sessionStorage.getItem('hash')
    ? CryptoJS.enc.Utf8.parse(sessionStorage.getItem('hash')) : '');
  const [someReidentifiedPatient, setSomeReidentifiedPatient] = useState(false);
  const keepSomeReidPatient = useRef(false);
  const availableToReidTasyCode = useRef(false);
  const [visibleTasyCode, setVisibleTasyCode] = useState(() => (
    sessionStorage.getItem('tasy_code') === 'true'
      ? CryptoJS.enc.Utf8.parse(sessionStorage.getItem('tasy_code')) : ''
  ));
  const [someTasyCodePatient, setSomeTasyCodePatient] = useState(false);

  function resetReid() {
    setSomeReidentifiedPatient(false);
    keepSomeReidPatient.current = false;
    setSomeTasyCodePatient(false);
    availableToReidTasyCode.current = false;
    sessionStorage.removeItem('tasy_code');
  }

  function showErrorMessage(lastPatient) {
    if (lastPatient && !keepSomeReidPatient.current) {
      toast.error('Nenhum paciente reidentificado', {
        toastId: 'noReidentifiedPatient',
      });

      resetReid();
    }
  }

  function showTasyCode() {
    sessionStorage.setItem('tasy_code', 'true');
    setVisibleTasyCode(true);
  }

  async function reidentifyTasyCode(hash) {
    if (!hash) {
      return null;
    }
    try {
      const tasyCode = await TasyServices.getTasyCode(hash);
      setSomeTasyCodePatient(true);
      return tasyCode;
    } catch (error) {
      if (error?.response?.status === 400) {
        toast.error('Hashs não informados corretamente', {
          toastId: 'errorTasyCode',
          draggable: false,
        });
      } else if (error?.response?.status === 403 || error?.request?.status === 0) {
        toast.error('Este computador não tem permissão para reidentificar os pacientes', {
          toastId: 'errorTasyCode',
          draggable: false,
        });
      } else if (error?.response?.status === 404) {
        toast.error('Códigos de identificação não encontrados', {
          toastId: 'errorTasyCode',
          draggable: false,
        });
      } else {
        toast.error('Erro ao buscar os códigos de identificação', {
          toastId: 'errorTasyCode',
          draggable: false,
        });
      }
      resetReid();
      setVisibleTasyCode(false);
      return null;
    }
  }

  function reidentify({
    aes, sha, studyID, lastPatient,
  }) {
    if (!aes) {
      return null;
    }

    try {
      const decrypted = CryptoJS.AES.decrypt(aes, password, {
        mode: CryptoJS.mode.ECB,
      });

      const patientId = decrypted.toString(CryptoJS.enc.Utf8);

      if (!patientId) {
        UtilServices.logReid({
          aes, sha, study: studyID, userId: dataUserAuthenticated.userId,
        }).then().catch();
        showErrorMessage(lastPatient);
      } else {
        setSomeReidentifiedPatient(true);
        keepSomeReidPatient.current = true;
        if (dataUserAuthenticated.customerId === testeID
          || dataUserAuthenticated.customerId === barretosID
          || dataUserAuthenticated.customerId === barretosTIID) {
          availableToReidTasyCode.current = true;
        }

        return patientId;
      }
    } catch {
      showErrorMessage(lastPatient);
    }

    return null;
  }

  function getAESByReid(id) {
    if (!id) {
      return null;
    }

    const aes = CryptoJS.AES.encrypt(id, password, {
      mode: CryptoJS.mode.ECB,
    });

    const patientAES = aes.toString();
    return patientAES;
  }

  function sha256(message) {
    const hash = CryptoJS.SHA256(message);
    const hashString = hash.toString(CryptoJS.enc.Hex).slice(0, 32);
    return hashString;
  }

  function handleReidSubmit(passwordInput) {
    const hashedPassword = sha256(passwordInput);
    const key = CryptoJS.enc.Utf8.parse(hashedPassword);
    setPassword(key);
    sessionStorage.setItem('hash', hashedPassword);

    resetReid();
  }

  return (
    <Reid.Provider
      value={{
        handleReidSubmit,
        reidentify,
        getAESByReid,
        password,
        someReidentifiedPatient,
        visibleTasyCode,
        showTasyCode,
        reidentifyTasyCode,
        availableToReidTasyCode,
        someTasyCodePatient,
      }}
    >
      {children}
    </Reid.Provider>
  );
}

export { Reid, ReidProvider };
