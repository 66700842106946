import useReid from 'context/reid/useReid';
import { searchTypes } from 'constants/searchTypes';
import { useEffect } from 'react';
import SearchBar from '..';

import * as S from './styles';

export default function SearchPatients({
  searchType, setSearchType, searchTerm, setSearchTerm,
}) {
  const { someReidentifiedPatient } = useReid();
  const patientsReidentify = someReidentifiedPatient;
  let placeholder = 'Buscar hash, digite os três primeiros caracteres';

  if (patientsReidentify && searchType === searchTypes.REID) {
    placeholder = 'Para pesquisar, digite o ID completo';
  }

  useEffect(() => {
    if (someReidentifiedPatient) {
      setSearchType(searchTypes.REID);
    }
  }, [someReidentifiedPatient]);

  return (
    <SearchBar
      placeholder={placeholder}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
    >
      <S.CustomSelect
        value={searchType}
        onChange={(event) => setSearchType(event.target.value)}
      >
        <S.CustomMenuItem value={searchTypes.REID}>Busca por ID</S.CustomMenuItem>
        <S.CustomMenuItem value={searchTypes.AES}>Busca por Hash</S.CustomMenuItem>
      </S.CustomSelect>
    </SearchBar>
  );
}
