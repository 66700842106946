import HttpClient from './utils/HttpClient';

class SemanticSearchServices {
  constructor() {
    this.httpClient = new HttpClient(process.env.REACT_APP_API_COMTRIALS_URL);
  }

  search({ CIDs, filters, page = 0 }) {
    const body = {
      CIDs,
      filters,
    };
    return this.httpClient.post(`study/semanticSearch?page=${page}`, body);
  }

  getFilters(cids) {
    return this.httpClient.post('search/filters', { cids });
  }

  sendPatientToStudy(sha, studiesId) {
    return this.httpClient.post(`/study/insertPatients?sha=${sha}`, { studiesId });
  }

  createFavoriteList(name, studyId) {
    return this.httpClient.post('/favoriteList', { name, studyId });
  }

  getFavoritesList() {
    return this.httpClient.get('/favoriteList');
  }

  getFavoriteListData(listId) {
    return this.httpClient.get(`/favoriteList/${listId}`);
  }

  removePatientFromList(listId, patientId) {
    return this.httpClient.delete(`/favoriteList/${listId}?sha=${patientId}`);
  }

  addPatientToList(listId, sha, aes, flag, cid) {
    return this.httpClient.post(`/favoriteList/${listId}`, {
      sha, flag, aes, cid,
    });
  }

  getExternalCentersByStudy(studyId) {
    return this.httpClient.get(`referral/research-sites?clinicalTrialsID=${studyId}`);
  }

  listAllReferral() {
    return this.httpClient.get('referral/all');
  }
}

export default new SemanticSearchServices();
