import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';

import { ListItem } from '@mui/material';
import { useState } from 'react';
import * as S from './styles';
import * as DefaultStyles from '../styles';

export default function MenuItem({
  option, selectedIndex, index, onMenuItemClick, onSubMenuItemClick, setIndexBeingSelected,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  function handleClickListItem(event) {
    setAnchorEl(event.currentTarget);
    setIndexBeingSelected(index);
  }

  function handleClose() {
    setAnchorEl(null);
  }
  const Icon = option.icon;

  if (option.subItems) {
    return (
      <>
        <DefaultStyles.CustomList>
          <ListItem onClick={handleClickListItem}>
            <Icon />
            {option.text}
            <ArrowRightOutlinedIcon />
          </ListItem>
        </DefaultStyles.CustomList>

        <DefaultStyles.CustomMenu
          sx={{ left: 10 }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {option.subItems.map((subOption, indexSubItem) => (
            <S.Container
              key={subOption}
              onClick={() => onSubMenuItemClick(indexSubItem)}
            >
              {subOption}
            </S.Container>
          ))}
        </DefaultStyles.CustomMenu>
      </>
    );
  }

  return (
    <S.Container
      selected={index === selectedIndex}
      onClick={(event) => onMenuItemClick(event, index)}
    >
      <div>
        <Icon />
        {option.text}
      </div>
    </S.Container>
  );
}
