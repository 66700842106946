import CloseIcon from '@mui/icons-material/Close';
import * as S from './styles';

export default function CloseButton({ closeToast }) {
  return (
    <S.Container>
      <CloseIcon onClick={closeToast} />
    </S.Container>
  );
}
