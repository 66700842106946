import { CircularProgress } from '@mui/material';
import { useRef, useState } from 'react';
import { patientStatus, patientStatusRequestsNames } from 'constants/patientStatus';
import { CheckCircleOutlineOutlined } from '@mui/icons-material';
import { toast } from 'react-toastify';
import useAuth from 'context/auth/useAuth';
import PatientServices from 'services/PatientServices';
import { patientElegibility } from 'constants/patientElegibility';
import * as S from './styles';

export default function ModalIneligible({
  motive, onClose, loadPatients, oldStatus, patientId, studyId,
}) {
  const { dataUserAuthenticated } = useAuth();
  const [loading, setLoading] = useState(false);
  const observation = useRef(null);

  async function handleSendForm() {
    setLoading(true);
    try {
      const dataFeedback = {
        status: patientStatus.CONCLUDED,
        substatus: motive,
        patientId,
        author_id: dataUserAuthenticated.userId,
        study_id: studyId,
        is_elegible: String(patientElegibility.NO),
      };

      await PatientServices.sendFeedback(dataFeedback);

      if (observation.current.value) {
        const dataObservation = {
          patient_id: patientId,
          text: observation.current.value,
          author_id: dataUserAuthenticated.userId,
        };

        await PatientServices.createObservation(dataObservation);
      }

      if (oldStatus !== patientStatus.CONCLUDED) {
        toast.success(`Paciente enviado para a aba ${patientStatus.CONCLUDED}`, {
          icon: <CheckCircleOutlineOutlined />,
        });

        loadPatients({ currentStatus: patientStatusRequestsNames[oldStatus], clearSearch: true });
        loadPatients({ currentStatus: patientStatusRequestsNames[patientStatus.CONCLUDED] });
      }
    } catch {
      toast.error('Erro ao enviar feedback');
    } finally {
      setLoading(false);
      onClose();
    }
  }

  return (
    <S.Container>
      <S.Header>
        <h4>Motivo de Exclusão</h4>
        <p>{motive || 'Falha de Pre-screening'}</p>
        <span>
          Se possível, compartilhe o motivo da exclusão do paciente. Isso ajudará a melhorar o processo de pesquisa clínica.
        </span>
      </S.Header>
      <S.Content>
        <S.TexField
          label="Observações"
          placeholder="Escreva detalhes sobre este paciente"
          multiline
          rows={5}
          inputRef={observation}
        />
      </S.Content>

      <S.Actions>
        <S.Button variant="ghost" onClick={onClose}>Cancelar</S.Button>
        <S.Button variant="contained" onClick={handleSendForm}>
          Comentar
          {loading && <CircularProgress size={14} sx={{ color: '#FFF' }} />}
        </S.Button>
      </S.Actions>
    </S.Container>
  );
}
