import { MenuItem as MenuItemMui } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(MenuItemMui)`
  font-size: 0.75rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  svg {
    width: 18px;
    height: 18px;
  } 
`;
