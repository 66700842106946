import styled from 'styled-components';

export const Container = styled.div`
  background: #FFF;
  min-height: 100vh;
  height: 100%;
`;

export const Content = styled.main`
  display: flex;
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;
  width: 100%;
  position: relative;
`;
