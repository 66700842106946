import { useState } from 'react';
import {
  TableCell, TableFooter as Container, TableRow, MenuItem,
} from '@mui/material';

import countPagesTable from 'utils/countPagesTable';
import * as S from './styles';

export default function TableFooter({
  hasPatients, error, qtyOfPatients, currentPage, setCurrentPage, status, limit, setLimit,
}) {
  const [inputPage, setInputPage] = useState('');

  function handleChangePage(event, value) {
    setCurrentPage((prevState) => ({
      ...prevState,
      [status]: value - 1,
    }));
  }

  function handleChangeGoToPage(event) {
    event.preventDefault();

    setCurrentPage((prevState) => ({
      ...prevState,
      [status]: Number(inputPage) - 1,
    }));
  }

  return (
    <Container>
      <TableRow>
        <TableCell
          colSpan={6}
          sx={{
            borderBottom: 0, paddingBottom: 0, paddingTop: '1rem', paddingRight: '1rem',
          }}
        >
          {(hasPatients && !error) && (
          <S.FooterPaginationTable>
            <S.Limit>
              <p>Exibir Pacientes</p>
              <S.Select
                value={limit}
                onChange={(event) => setLimit(event.target.value)}

              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </S.Select>

            </S.Limit>
            <S.Pages>
              <p>Total de Pacientes: {qtyOfPatients}</p>
              {qtyOfPatients > 10 && (
              <>
                <S.StyledPagination
                  onChange={handleChangePage}
                  page={currentPage[status] + 1}
                  count={countPagesTable(qtyOfPatients, limit)}
                  size="small"
                />
                <S.GoToField onSubmit={handleChangeGoToPage}>
                  <p>Ir para</p>
                  <S.Input
                    type="number"
                    min={1}
                    max={countPagesTable(qtyOfPatients)}
                    value={inputPage}
                    onChange={(event) => setInputPage(event.target.value)}
                    id="go-to-page"
                  />
                </S.GoToField>
              </>
              )}
            </S.Pages>
          </S.FooterPaginationTable>
          )}
        </TableCell>
      </TableRow>
    </Container>
  );
}
