export const Inspectors = {
  VALIDADO: 'Validado',
  METASTATICO: 'Metastático',
};

export const AccepetedValues = {
  NUMERICO_INTEIRO: ['Numerico Inteiro'],
  NUMERICO_FLOAT: ['Numerico Float'],
  SIM_NAO: ['Sim', 'Não'],
  RESISTENTE_SENSIVEL: ['Resistente', 'Sensível'],
  POSITIVO_NEGATIVO: ['Positivo', 'Negativo'],
  PRIMEIRA_SEGUNDA_LINHA: ['Primeira Linha', 'Segunda Linha'],
  NORMAL_MUTADO: ['Normal', 'Mutado'],
  X_0_1_2_3_4: ['X', '0', '1', '2', '3', '4'],
  '0_1': ['0', '1'],
  A_B_C: ['A', 'B', 'C'],
  '1_2_3_4': ['1', '2', '3', '4'],
};
