import { Link } from 'react-router-dom';

import LogoutIcon from '@mui/icons-material/Logout';
import { Avatar } from '@mui/material';

import useAuth from 'context/auth/useAuth';
import logo from 'assets/logo-com-trials.svg';
import * as S from './styles';
import Notification from './Notification';

export default function Header() {
  const { dataUserAuthenticated, handleLogout } = useAuth();

  return (
    <S.Container>
      <Link to="/estudos"><img src={logo} alt="com+trials" /></Link>
      <S.Nav>
        <Notification />
        <Link to={`/perfil/${dataUserAuthenticated.userId}`}>
          <S.UserDetail>
            <Avatar
              alt={`${dataUserAuthenticated.name} ${dataUserAuthenticated.surname}`}
              src={dataUserAuthenticated.avatar}
              sx={{ width: '32px', height: '32px' }}
            />
            <p>{`${dataUserAuthenticated.name} ${dataUserAuthenticated.surname}`}</p>
          </S.UserDetail>
        </Link>

        <LogoutIcon
          sx={{
            color: '#604FFF', width: '18px', height: '18px', cursor: 'pointer',
          }}
          onClick={handleLogout}
        />

      </S.Nav>
    </S.Container>
  );
}
