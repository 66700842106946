import styled from 'styled-components';

export const Container = styled.form`
  padding: 2rem 1rem;
  position: relative;
  height: calc(100vh - 170px - 64px);
  overflow-y: auto;
  overflow-x: hidden;

  h2 {
    font-size: 1rem;
    font-weight: 600;
    color: #202124;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: #FFF; 
    border-radius: 16px;
    border: 1px solid #E3E3E3;
    border-bottom: 0;
    border-top: 0;
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #B8B8B8;
    width: 6px;
    border-radius: 4px;
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: #E5E1EC;
  margin: 1rem 0;
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;

  p {
    font-size: 0.875rem;
    font-weight: 700;
  }

  .MuiCheckbox-root {
    margin: -10px 0 !important;
  }
`;

export const FixedActions = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  max-width: 256px;
  width: 100%;

  div {
    margin-right: 0 !important;
  }

  button {
    width: 100%;
  }
`;

export const ContainerLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 48px);
`;
