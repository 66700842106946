import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 2rem;

  h3 {
    margin-bottom: 1rem;
    padding: 1rem 0;
    border-bottom: 1px solid #B8B8B8;
  }
`;

export const ContainerContent = styled.div`
  display: flex;
  width: 100%;
  background: #FFF;
  padding: 1.5rem;
  border-radius: 1rem;
`;

export const EditPassword = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;
