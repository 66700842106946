import { useRef } from 'react';
import { CircularProgress } from '@mui/material';
import * as S from './styles';

export default function ModalCreateList({ onClose, callback, loading }) {
  const nameList = useRef();

  function handleSendForm() {
    callback(nameList.current.value);
  }

  return (
    <S.Container>
      <S.Header>
        <h4>Nova Lista de Favoritos</h4>
      </S.Header>
      <S.Content>
        <S.TexField
          label="Nome da Lista"
          inputRef={nameList}
          size="small"
        />
      </S.Content>

      <S.Actions>
        <S.Button variant="ghost" onClick={onClose}>Cancelar</S.Button>
        <S.Button variant="contained" onClick={handleSendForm}>
          Criar
          {loading && <CircularProgress size={14} sx={{ color: '#FFF' }} />}
        </S.Button>
      </S.Actions>
    </S.Container>
  );
}
