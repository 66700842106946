import styled from 'styled-components';
import banner from 'assets/banner-login.png';

export const Container = styled.section`
  flex-grow: 1;
  flex-basis: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 100vh;
  height: 100%;
  background: url(${banner});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 35% 0;
  position: relative;

  @media(max-width: 895px){
    display: none;
  }
`;

export const Content = styled.div`
  z-index: 10;
  width: 100%;

  img{
    position: absolute;
  }

  img:first-of-type{
    top: 2rem;
    left: 0;
  }

  img:last-of-type{
    bottom: 2rem;
    right: 0;
  }

  h2 {
    color: #FFF;
    font-size: 2.5rem;
    font-weight: 600;
    text-align: center;
  }
`;

export const Overlay = styled.div`
  min-height: 100vh;
  height: 100%;
  position: absolute;
  inset: 0;
  background: linear-gradient(212.76deg, #A6FFF2 18.79%, #604FFF 83.54%);
  opacity: 0.8;
`;
