import { Select as SelectMui, Pagination } from '@mui/material';
import styled from 'styled-components';

export const FooterPaginationTable = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: 0.75rem;
    font-weight: 500;
    color: #5F6368;
  }
`;

export const StyledPagination = styled(Pagination)`
  .MuiPaginationItem-text{
    font-size: 0.75rem;
    font-weight: 500;
    color: #B8B8B8;
  }

  .Mui-selected, .MuiPaginationItem-previousNext {
    background: transparent;
    color: #000;
    font-weight: 500;
    background: transparent;
  }
`;

export const Input = styled.input`
  appearance: none;
  border-radius: 2px;
  border: 2px solid #D9D9D9;
  padding: 4px 6px;
  outline: none;
  width: 44px;
  font-size: 0.75rem;
  color: #5F6368;
  font-weight: 500;

  &:focus {
    border: 2px solid #604FFF;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:out-of-range{
    border: 2px solid #BA1A1A;
  }
`;

export const Select = styled(SelectMui)`
  border-radius: 2px;

  .MuiSelect-select{
    font-family: 'Roboto', sans-serif;
    appearance: none !important;
    border-radius: 2px !important;
    border: 2px solid #D9D9D9;
    padding: 0 6px !important;
    outline: none !important;
    width: 44px !important;
    font-size: 0.75rem !important;
    color: #5F6368 !important;
    font-weight: 500;

    svg.MuiSelect-iconOutlined {
      width: 16px;
    }
  }
`;

export const GoToField = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

export const Limit = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

`;

export const Pages = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem
`;
