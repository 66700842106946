import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  background: #F6F8FC;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  height: 100%;
`;

export const Info = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0;

  button {
    background: none;
    border: none;
    cursor: pointer;
    outline: 0;
  }
`;

export const TextContainer = styled.div`
  height: 100%;
  width: 100%;
  background: #E5E1E6;
  border-radius: 8px;
  padding: 1rem 1rem 1rem 0;
`;

export const Text = styled.textarea`
  background: #E5E1E6;
  border: 0;
  border-radius: 8px;
  outline: 0;
  color: #3C3C3C;
  font-size: 1rem;
  padding: 0 2rem;
  resize: none;
  width: 100%;
  min-height: 100%;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    margin: 1.25rem 0;
    background-color: #FFF; 
    border-radius: 4px;
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #B8B8B8;
    width: 6px;
    border-radius: 4px;
    background-clip: content-box;
  }
`;
