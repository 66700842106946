import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
  }

  h2 {
    font-family: 'League Spartan', sans-serif;
  }

  button {
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .Toastify__toast-container{
    width: max-content;

    &--bottom-left{
      left: inherit;
    }
  }

  .Toastify__toast {
    min-width: 240px;
    max-width: 380px;
    border-radius: 8px;
  }

  .Toastify__toast-body {
    font-size: 0.95rem;
    font-weight: 500;
    margin-inline-end: 1.5rem !important;
    margin-left: 1rem !important;
  }

  .Toastify__toast-theme--colored.Toastify__toast--success {
    background: #00E1A7;
  }
  .Toastify__toast-theme--colored.Toastify__toast--error {
    background: #BA1A1A !important;
    border-radius: 8px;
    padding: 1rem 1rem;
  }

  .Toastify__toast-icon {
    margin-inline-end: 1rem !important;
  }
`;
