import { MoreVert } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import hash from 'assets/hash_currentColor.svg';
import useMenu from 'hooks/useMenu';
import * as S from '../styles';

export default function MoreMenu({ patientId }) {
  const {
    handleOpen, anchorElMenu, openMenu, handleClose,
  } = useMenu();

  function copyHashToClipBoard() {
    navigator.clipboard.writeText(patientId);
  }

  return (
    <>
      <S.ButtonCardActions
        onClick={handleOpen}
        fontSize="small"
      >
        <MoreVert />
      </S.ButtonCardActions>
      <Menu
        anchorEl={anchorElMenu}
        open={openMenu}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={copyHashToClipBoard}>
          <S.MenuItemWithIcon>
            <img src={hash} alt="Hash" />
            Copiar Hash
          </S.MenuItemWithIcon>
        </MenuItem>
      </Menu>
    </>
  );
}
