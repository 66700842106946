import aspas1 from 'assets/comsentimento-aspas-01.svg';
import aspas2 from 'assets/comsentimento-aspas-02.svg';

import * as S from './styles';

export default function Banner() {
  return (
    <S.Container>
      <S.Overlay />
      <S.Content>
        <img src={aspas1} alt="Aspas Comsentimento" width={180} />
        <h2>
          Vencendo o câncer
          <br />
          através de dados.
        </h2>
        <img src={aspas2} alt="Aspas Comsentimento" width={180} />
      </S.Content>
    </S.Container>
  );
}
