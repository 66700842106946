import {
  useState, memo, useEffect, useCallback, useRef,
} from 'react';

import Loader from 'components/Loader';
import PatientServices from 'services/PatientServices';

import { CircularProgress } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import Header from './Header';
import HealthRecord from './HealthRecord';
import Observations from './Observations';
import * as S from './styles';
import Form from './Form';
import Laudos from './Laudos';

function ModalHealthRecord({
  onClose, patient, patientIdReid, studyName, loadPatients, studyId, search = false, data,
}) {
  const [healthRecords, setHealthRecords] = useState(data ? JSON.parse(data) : []);
  const [observations, setObservations] = useState([]);
  const [isLoading, setIsLoading] = useState(() => !data);
  const [error, setError] = useState(false);
  const inputObservations = useRef(null);
  const [activeMenu, setActiveMenu] = useState('prontuario');
  const [pathox, setPathox] = useState([]);
  const isFirstRender = useRef(true);
  const [searchParams] = useSearchParams();
  const customerID = searchParams.get('customer');

  const loadObservations = useCallback(async () => {
    const { result: observationsResponse } = await PatientServices.listHistory(patient.id, studyId);

    setObservations(observationsResponse);
  }, []);

  useEffect(() => {
    if (!data) {
      if (isFirstRender.current) {
        isFirstRender.current = false;
      }
      (async () => {
        try {
          if (!search && !isFirstRender.current) {
            setIsLoading(true);
            await loadObservations();
          }

          if (activeMenu === 'prontuario') {
            if (healthRecords.length === 0) {
              setIsLoading(true);
              const healthRecordsData = await PatientServices.listHealthRecord(patient.sha, customerID);
              setHealthRecords(healthRecordsData.reverse());
            }
          }

          if (activeMenu === 'laudo') {
            if (pathox.length === 0) {
              setIsLoading(true);
              const pathoxData = await PatientServices.listPathox(patient.sha);
              setPathox(pathoxData);
            }
          }
        } catch {
          setError(true);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [loadObservations, activeMenu]);

  if (isLoading && isFirstRender.current) {
    return <Loader />;
  }

  return (
    <S.Container role="dialog">
      <Header
        studyName={studyName}
        patientIdReid={patientIdReid}
        onClose={onClose}
        indicatedDate={patient.LIST_created_at}
        activeMenu={activeMenu}
        setActiveMenu={setActiveMenu}
      />

      {error && (
        <S.ModalErrorContainer><strong>Erro ao exibir as informações do paciente</strong></S.ModalErrorContainer>
      )}

      {!error && (
        <S.Content indicated={patient.indicated} search={search}>
          <S.Evaluator>
            {isLoading && (
              <S.ContainerLoading>
                <CircularProgress size={64} />
              </S.ContainerLoading>
            )}

            {(!isLoading && activeMenu === 'prontuario') && (
              healthRecords.length === 0 ? (
                <S.ModalErrorContainer>Não há prontuários para esse paciente</S.ModalErrorContainer>
              ) : (
                <HealthRecord healthRecords={healthRecords} />
              )
            )}

            {(!isLoading && activeMenu === 'laudo') && (
              pathox.length === 0 ? (
                <S.ModalErrorContainer>Não há laudos para esse paciente</S.ModalErrorContainer>
              ) : (
                <Laudos laudos={pathox} />
              )
            )}

            {!search && (
              <Form
                patient={patient}
                hasObservations={observations.length > 0}
                loadPatients={loadPatients}
                onClose={onClose}
                inputObservations={inputObservations}
                studyId={studyId}
                studyName={studyName}
              />
            )}
          </S.Evaluator>

          {(patient.indicated || !search) && (
            <Observations
              observations={observations}
              loadObservations={loadObservations}
              patientId={patient.id}
              inputObservations={inputObservations}
            />
          )}
        </S.Content>
      )}
    </S.Container>

  );
}

export default memo(ModalHealthRecord);
