import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #C5C0FF;
  width: 100%;
  white-space: nowrap;
  margin-bottom: 1rem;
`;

export const ItemMenu = styled.div`
  padding: 0.5rem 0;
  cursor: pointer;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  > span {
    color: #C5C0FF;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #C5C0FF;
    border-radius: 12px;
    padding: 2px 6px;
    font-size: 0.75rem;
    text-align: center;
    color: #FFF;
    font-family: 'Readex Pro', 'Roboto', sans-serif;
  }

  ${({ active }) => active && css`
    border-bottom: 2px solid #604FFF;
    
    > span {
      color: #604FFF;
      font-weight: 700;
    }

    div{
      background: #604FFF;
    }
  `}
`;
