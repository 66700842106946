import { TableRow } from '@mui/material';

import * as S from './styles';

export default function EmptyTableCell({
  message, columns, children, align,
}) {
  return (
    <TableRow>
      <S.TableCell colSpan={columns} align={align || 'left'}>{message || children}</S.TableCell>
    </TableRow>
  );
}
