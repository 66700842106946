import { useState, useEffect } from 'react';
import { Avatar, TableCell, Tooltip } from '@mui/material';

import Modal from 'components/Modal';
import useModal from 'hooks/useModal';

import hash from 'assets/hash.svg';

import { patientStatusRequests } from 'constants/patientStatus';
import useReid from 'context/reid/useReid';
import ModalHealthRecord from 'components/Modal/ModalHealthRecord';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import Criterias from 'components/Criterias';
import Tag from '../Tag';
import * as S from './styles';

export default function PatientRow({
  patient, status, studyName, studyId, loadPatients, lastPatient, setIsLoading,
}) {
  const { visible, handleOpenModal, handleCloseModal } = useModal();
  const {
    password, reidentify, visibleTasyCode, reidentifyTasyCode,
  } = useReid();
  const [textTooltip, setTextTooltip] = useState(patient.aes || patient.sha);
  const [patientId, setPatientId] = useState(() => {
    if (patient.aes) {
      return `${patient.aes.slice(0, 3)} •••••••••••`;
    }
    return '•••••••••••';
  });
  const changeScore = patient.next_to_last_score && patient.score - patient.next_to_last_score;

  useEffect(() => {
    (async () => {
      try {
        if (password) {
          let id = reidentify({
            aes: patient.aes, sha: patient.sha, studyID: studyId, lastPatient,
          });

          if (visibleTasyCode) {
            setIsLoading(true);
            const responseTasyCode = await reidentifyTasyCode(id);
            id = responseTasyCode.CODIGO_TASY;
          }

          setPatientId(id || (patient.aes ? `${patient.aes.slice(0, 3)} •••••••••••` : '•••••••••••'));
          setTextTooltip((prevState) => id || prevState);
        }
      } catch {} finally {
        setIsLoading(false);
      }
    })();
  }, [password, visibleTasyCode]);

  function copyHashToClipBoard() {
    const valueToCopy = textTooltip;

    navigator.clipboard.writeText(valueToCopy);
    setTextTooltip('Hash Copiada');

    setTimeout(() => {
      setTextTooltip(valueToCopy);
    }, 2000);
  }

  function convertDateToPtBr(dateString) {
    const date = new Date(dateString);

    const dateFormated = new Intl.DateTimeFormat('pt-BR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    }).format(date);

    return dateFormated;
  }

  return (
    <>
      <Modal visible={visible}>
        <ModalHealthRecord
          onClose={handleCloseModal}
          patient={patient}
          patientIdReid={patientId}
          variant="healthRecord"
          studyName={studyName}
          studyId={studyId}
          loadPatients={loadPatients}
        />
      </Modal>
      <S.Container>
        <TableCell align="left" sx={{ cursor: 'pointer' }} onClick={handleOpenModal} role="button">
          <S.PatientInfo>
            <Avatar
              alt={patient.id}
              sx={{
                height: '32px', width: '32px', color: '#604FFF', background: '#FFF',
              }}
            />
            {patientId}
          </S.PatientInfo>
        </TableCell>

        <TableCell align="left" onClick={handleOpenModal} role="button">
          {patient.score === 2 && (
            <p>Não indicado pela IA</p>
          )}
          {patient.score !== 2 && (
            <Tooltip
              title={<Criterias negatives={patient.criterias_negative} positives={patient.criterias_positive} />}
            >
              <S.Score>
                {patient.score}
                {changeScore > 0 && (
                <ArrowDropUp sx={{ color: '#00FFBE' }} />
                )}
                {changeScore < 0 && (
                <ArrowDropDown sx={{ color: '#BA1A1A' }} />
                )}
              </S.Score>
            </Tooltip>
          )}
        </TableCell>

        {status !== patientStatusRequests.NOT_EVALUATED && (
          <TableCell align="left" role="button">
            <Tag
              currentSubStatus={patient.sub_status}
              currentStatus={patient.status}
              loadPatients={loadPatients}
              studyId={studyId}
              patientId={patient.id}
              elegibility={patient.is_elegible}
              setIsLoading={setIsLoading}
            />
          </TableCell>
        )}

        <TableCell align="left" onClick={handleOpenModal} role="button">
          {patient.updated_at ? convertDateToPtBr(patient.updated_at) : '-'}
        </TableCell>

        <TableCell align="center">
          <Tooltip title={textTooltip}>
            <S.ButtonCopy type="button" onClick={copyHashToClipBoard}>
              <img src={hash} alt="Hash" />
            </S.ButtonCopy>
          </Tooltip>
        </TableCell>
      </S.Container>
    </>
  );
}
