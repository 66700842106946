import * as S from './styles';

export default function ProfileData({ data }) {
  return (
    <S.Container>
      <h3>Seus dados</h3>
      <S.Item>
        <strong>Nome Completo</strong>
        <p>{`${data.name} ${data.surname}`}</p>
      </S.Item>

      <S.Item>
        <strong>E-mail</strong>
        <p>{data.email}</p>
      </S.Item>
    </S.Container>
  );
}
