import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StudiesList = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;

  p {
    font-size: 0.75rem;
    font-weight: 600;
    color: #001D35;
    letter-spacing: 0.5px;

    border: 1px solid #604FFF;
    border-radius: 20px;
    padding: 0.5rem 0.75rem;
  }
`;

export const Study = styled.div`
  font-size: 0.75rem;
  font-weight: 600;
  color: #001D35;
  letter-spacing: 0.5px;
  border: 1px solid #604FFF;
  border-radius: 20px;
  padding: 0.5rem 0.75rem;
  background-color: #F4EFF4;

  ${({ indicated }) => indicated !== 1 && css`
    border: 1px solid #00C290;
    background-color: #FFF;
    cursor: pointer;
  `}

`;
