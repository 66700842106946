import HttpClient from './utils/HttpClient';

class ShareServices {
  constructor() {
    this.httpClient = new HttpClient(process.env.REACT_APP_API_COMTRIALS_URL);
  }

  sharePatient({
    patient, patientSHA, customerDestiny, studyDestiny,
  }) {
    return this.httpClient.post('referral/share/send', {
      patient, patientSHA, customerDestiny, studyDestiny,
    });
  }

  listSharedPatients(type) {
    return this.httpClient.get(`referral/share/list?type=${type}`);
  }

  replyPatient({ referralID, reply }) {
    return this.httpClient.put('referral/share/reply', {
      referralID, reply,
    });
  }

  replyWithContact({
    referralID, doctorName, patientName, email, phone, observations,
  }) {
    return this.httpClient.post('referral/share/reply/contact', {
      referralID, doctorName, patientName, email, phone, observations,
    });
  }

  getContactData(referralID) {
    return this.httpClient.get(`referral/share/contact/${referralID}`);
  }
}

export default new ShareServices();
