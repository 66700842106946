import HttpClient from './utils/HttpClient';

class UtilServices {
  constructor() {
    this.httpClient = new HttpClient(process.env.REACT_APP_API_COMTRIALS_URL);
  }

  sanityCheck() {
    return this.httpClient.get('/utils/verify-token');
  }

  logReid({
    aes, sha, study, userId,
  }) {
    return this.httpClient.post('/utils/log-reid', {
      aes, sha, study, userId,
    });
  }
}

export default new UtilServices();
