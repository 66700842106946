import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import * as Form from 'components/Filter';
import useFilter from 'context/filter/useFilter';

import * as SForm from 'components/Filter/styles';
import { Inspectors } from 'constants/inspectors';
import { updateSearchParams } from 'utils/updateSearchParams';
import { CircularProgress } from '@mui/material';
import * as S from './styles';
import Collapse from '../Collapse';
import CidsCheckbox from '../CidsCheckbox';

export default function Filter({ onSubmit, cids }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const cid = searchParams.get('cid');
  const status = searchParams.get(Inspectors.METASTATICO);

  const [cidsFilter, setCidsFilter] = useState(cid.split(','));
  const {
    filters, setFilters, isLoading, error, availableFilters, loadFilters,
  } = useFilter();

  function handleInputChange(event, regex = '') {
    if (regex && event.target.value) {
      const regexTest = new RegExp(regex);
      if (!regexTest.test(event.target.value)) return;
    }
    const { name, value } = event.target;

    if (name === Inspectors.METASTATICO) {
      updateSearchParams(searchParams, setSearchParams, name, value);
    }

    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function applyFilters() {
    onSubmit(0);
  }

  function handleResetFilters() {
    setCidsFilter(cid);
    const keys = Object.keys(filters);
    keys.forEach((key) => {
      filters[key] = '';
    });
    handleInputChange({ target: { name: filters[Inspectors.METASTATICO], value: status } });
    onSubmit();
  }

  useEffect(() => {
    handleInputChange({ target: { name: Inspectors.METASTATICO, value: status } });
  }, []);

  useEffect(() => {
    (async () => {
      await loadFilters(cidsFilter.join(','));
    })();
  }, [cidsFilter]);

  return (
    <S.Container>
      <h3>Filtros</h3>
      {error && (
        <S.ContainerLoading>
          Erro ao buscar filtros
        </S.ContainerLoading>
      )}

      {isLoading && (
        <S.ContainerLoading>
          <CircularProgress />
        </S.ContainerLoading>
      )}

      {(!error && !isLoading && availableFilters.length > 0) && (
        <>
          <S.Separator />
          <Collapse title="CIDs">
            <SForm.GroupColumn>
              <CidsCheckbox cids={cids} selectedCIDs={cidsFilter} setSelectedCIDs={setCidsFilter} />

            </SForm.GroupColumn>
          </Collapse>

          <S.Separator />
          <SForm.GroupColumn>
            <SForm.Label>Validado</SForm.Label>
            <Form.Radio
              id={filters[Inspectors.VALIDADO]}
              name={Inspectors.VALIDADO}
              options={['Sim', 'Não']}
              value={filters[Inspectors.VALIDADO]}
              onChange={handleInputChange}
            />
          </SForm.GroupColumn>

          {availableFilters.map((filter) => (
            <React.Fragment key={filter.category}>
              <S.Separator />
              <Collapse title={filter.category}>
                {filter.filters.map((item) => (
                  <SForm.GroupColumn key={item.filter}>
                    <SForm.Label>{item.filter}</SForm.Label>
                    <Form.Custom
                      filter={item.filter}
                      values={item.values}
                      onChange={handleInputChange}
                      value={filters[item.filter]}
                    />
                  </SForm.GroupColumn>
                ))}
              </Collapse>
            </React.Fragment>
          ))}
        </>

      )}
      <S.FixedActions>
        <S.Separator />
        <SForm.GroupColumn>
          <SForm.Button variant="contained" onClick={applyFilters}>Aplicar Filtros</SForm.Button>
          <SForm.Button variant="ghost" onClick={handleResetFilters}>Limpar Filtros</SForm.Button>
        </SForm.GroupColumn>
      </S.FixedActions>
    </S.Container>
  );
}
