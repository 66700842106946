import {
  Button, FormControl, FormControlLabel, FormLabel, Radio,
} from '@mui/material';
import styled from 'styled-components';

export const Container = styled.form`
  padding: 1rem 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 3rem;

  button {
    margin-bottom: 9px;
  }
`;

export const Questions = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const FormControlCustom = styled(FormControl)`
  height: 100%;
  display: flex;
  justify-content: space-between;

  &:nth-child(2){
    padding-bottom: 9px;
    width: 50%;
  }

  #next-step {
    font-size: 0.875rem;
  }
`;

export const FormLabelCustom = styled(FormLabel)`
  color: #201A1B;
  font-size: 0.875rem;
  font-weight: 500;

  &.Mui-focused{
    color: #201A1B;
  }
`;

export const RadioCustom = styled(Radio)`
  color: #645C5D;

  .MuiButtonBase-root {
    padding-bottom: 0;
  }

  .Mui-checked {
    color: #604FFF;
  }
`;

export const FormControlLabelCustom = styled(FormControlLabel)`
  span{
    font-size: 0.875rem;
    font-weight: 500;

    &.Mui-checked {
      color: #604FFF;
    }

    &.Mui-disabled {
      color: rgba(0,0,0,0.26);
    }

    &.MuiFormControlLabel-label{
      color: #645C5D;
    }
  }
`;

export const ContainerNotification = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100%;
`;

export const ButtonNotification = styled(Button)`
  padding: 0.5rem;
  border-radius: 50%;
  width: fit-content;
  height: fit-content;
  min-width: 0;
`;
