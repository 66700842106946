import axios from 'axios';
import getTokenFromStorage from 'utils/getTokenFromStorage';

class HttpClient {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  async get(path, signal) {
    return this.makeRequest(path, {
      method: 'GET',
      signal,
    });
  }

  async post(path, data) {
    return this.makeRequest(path, {
      method: 'POST',
      data,
    });
  }

  async delete(path, data) {
    return this.makeRequest(path, {
      method: 'DELETE',
      data,
    });
  }

  async put(path, data) {
    return this.makeRequest(path, {
      method: 'PUT',
      data,
    });
  }

  async makeRequest(url, { data = null, method, signal }) {
    const headers = {};

    const { token } = getTokenFromStorage();

    if (token) {
      headers.Authorization = token;
    }

    if (data) {
      headers['Content-Type'] = 'application/json';
    }

    const response = await axios({
      method,
      baseURL: this.baseUrl,
      url,
      data,
      headers,
      signal,
    });

    return response.data;
  }
}

export default HttpClient;
