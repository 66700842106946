import { Share, TopicOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import Criterias from 'components/Criterias';
import * as S from './styles';

export default function StudiesIndications({
  internal, external, onSend, sended,
}) {
  const dataStudiesInternal = internal?.length > 0;
  const dataStudiesExternal = external?.length > 0;

  if (!dataStudiesInternal && !dataStudiesExternal) {
    return null;
  }

  return (
    <S.Container>
      <S.StudiesList>
        <TopicOutlined color="primary" />
        {dataStudiesInternal && internal.map((studyIndicated) => (
          <Tooltip
            key={studyIndicated.studyId}
            arrow
            title={(
              <Criterias
                positives={studyIndicated.criterias_positive}
                negatives={studyIndicated.criterias_negative}
                extraText={studyIndicated.indicated === 1 && 'O paciente já está sendo investigado'}
              />
            )}
          >
            <S.Study
              indicated={studyIndicated.indicated || sended.internal}
              onClick={() => onSend(studyIndicated)}
            >
              {studyIndicated.studyName}
            </S.Study>
          </Tooltip>
        ))}
      </S.StudiesList>
      {dataStudiesExternal && (
        <S.StudiesList>
          <Share color="primary" />
          {external.map((studyIndicated) => (
            <Tooltip
              key={studyIndicated.studyId}
              arrow
              title={(
                <Criterias
                  positives={studyIndicated.criterias_positive}
                  negatives={studyIndicated.criterias_negative}
                  extraText={studyIndicated.indicated === 1 && 'O paciente já foi enviado'}
                />
            )}
            >
              <S.Study
                indicated={studyIndicated.indicated || sended.external}
                onClick={() => onSend(studyIndicated, true)}
              >
                {studyIndicated.studyName}
              </S.Study>
            </Tooltip>
          ))}
        </S.StudiesList>
      )}
    </S.Container>
  );
}
