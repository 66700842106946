import {
  AddchartOutlined, DonutLargeOutlined, OpenInNew,
} from '@mui/icons-material';
import { Avatar, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import useReid from 'context/reid/useReid';
import { descriptionCIDs } from 'constants/descriptionCIDs';
import useModal from 'hooks/useModal';
import Modal from 'components/Modal';
import ModalHealthRecord from 'components/Modal/ModalHealthRecord';

import * as S from './styles';
import TopicTag from './TopicTag';
import MoreMenu from './MoreMenu';
import FavoriteListMenu from './FavoriteListMenu';
import ModalSendPatientToStudy from './ModalSendPatientToStudy';
import StudiesIndications from './StudiesIndication';

export default function CardPatient({
  data, lastPatient, study, studyName,
}) {
  const { password, reidentify } = useReid();
  const [patientId, setPatientId] = useState(data.aes || data.sha);
  const [isLoadingReid, setIsLoadingReid] = useState(false);
  const [studyBeingClicked, setStudyBeingClicked] = useState(null);
  const [patientSended, setPatientSended] = useState({ internal: 0, external: 0 });

  const { handleOpenModal: openEHRModal, visible: visibleEHRModal, handleCloseModal: closeEHRModal } = useModal();
  const { handleOpenModal: openSendModal, visible: visibleSendModal, handleCloseModal: closeSendModal } = useModal();
  const { visibleTasyCode, reidentifyTasyCode } = useReid();

  useEffect(() => {
    (async () => {
      try {
        if (password) {
          let id = reidentify({
            aes: data.aes, sha: data.sha, studyID: null, lastPatient,
          });

          if (visibleTasyCode) {
            setIsLoadingReid(true);
            const responseTasyCode = await reidentifyTasyCode(id);
            id = responseTasyCode.CODIGO_TASY;
          }

          setPatientId(id || (data.aes || data.sha));
        }
      } catch {} finally {
        setIsLoadingReid(false);
      }
    })();
  }, [password, visibleTasyCode]);

  function formatDate() {
    if (!data.last_contact || data.last_contact === '') return '';

    const date = new Date(data.last_contact);

    return new Intl.DateTimeFormat('pt-BR', { dateStyle: 'long' }).format(date);
  }

  function handleSendPatientToStudy(studyIndicated, external = false) {
    if (studyIndicated.indicated === 1) return;

    setStudyBeingClicked({ studyIndicated, external });
    openSendModal();
  }

  return (
    <>
      <Modal visible={visibleEHRModal}>
        <ModalHealthRecord
          onClose={closeEHRModal}
          patient={data}
          patientIdReid={patientId}
          studyName={studyName}
          search
        />
      </Modal>
      <Modal visible={visibleSendModal}>
        <ModalSendPatientToStudy
          onClose={closeSendModal}
          sha={data.sha}
          id={data.id}
          patientId={patientId}
          study={studyBeingClicked}
          setPatientSended={setPatientSended}
        />
      </Modal>

      {/* <Modal visible={visibleSendModal}>
        <ModalSendToStudy
          onClose={closeSendModal}
          studies={dataStudies}
          setDataStudies={setDataStudies}
          sha={data.sha}
        />
      </Modal> */}
      <S.Container>
        <S.SectionWithActions>
          <S.InfosContainer>
            <Avatar sx={{ bgcolor: '#9747FF' }} />
            <p>
              {isLoadingReid && (
                <CircularProgress size={16} />
              )}

              {!isLoadingReid && (
                patientId
              )}

            </p>
          </S.InfosContainer>
          <S.InfosContainer style={{ justifyContent: 'flex-end' }}>
            <span>{data.last_contact && `Último contato com paciente: ${formatDate()}`} </span>
          </S.InfosContainer>
          <S.ButtonCardActions type="button" onClick={openEHRModal}>
            <OpenInNew />
            Abrir
          </S.ButtonCardActions>
        </S.SectionWithActions>
        <S.Disease>
          <DonutLargeOutlined color="primary" />
          <h3>{data.diagnosis} - {data.diagnosis && descriptionCIDs[data.diagnosis]}</h3>
        </S.Disease>
        {data?.tags?.length > 0 && (
          <S.Topics>
            <AddchartOutlined color="primary" />
            {data.tags.map((tag) => (
              <TopicTag key={tag.id} tag={tag} />
            ))}
          </S.Topics>
        )}
        <S.SectionWithActionsFooter>
          <StudiesIndications
            internal={data.internal_studies}
            external={data.external_studies}
            onSend={handleSendPatientToStudy}
            sended={patientSended}
          />
          <S.CardActions>
            <FavoriteListMenu
              sha={data.sha}
              aes={data.aes}
              cid={data.diagnosis}
              listsSelected={data.lists}
              study={study}
            />
            <MoreMenu />
          </S.CardActions>
        </S.SectionWithActionsFooter>
      </S.Container>
    </>
  );
}
