import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const ContainerScrollBar = styled.div`
  height: calc(100vh - 120px);
  overflow-x: auto;
  padding-right: 8px;

  ::-webkit-scrollbar {
    width: 6px;
    height: 90%;
  }

  ::-webkit-scrollbar-track {
    background-color: #FFF; 
    border-radius: 16px;
    border: 1px solid #E3E3E3;
    border-bottom: 0;
    border-top: 0;
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #B8B8B8;
    width: 6px;
    border-radius: 4px;
  }
`;

export const ContainerFilter = styled.div`
  max-width: 256px;
  width: 100%;
`;

export const ContainerCards = styled.main`
  flex: 1;
  background: #F3F6FC;
  border-radius: 24px;
  padding: 1rem 1rem;
  padding-right: 8px;
  margin-right: 2rem;
  margin-left: 0.5rem;
  margin-bottom: 1rem;

  section + section {
    margin-top: 1rem;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;

  margin-bottom: 1rem;
`;

export const DetailsSearch = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

export const ActionsSearch = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  width: 100%;

  select {
    width: 100%;
  }

  div {
    display: flex;
    align-items: center;
    gap: 1rem;

    svg {
      color: #5F6368;
    }
  }
`;

export const ButtonCardActions = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;

  background: transparent;
  border: none;
  outline: none;
`;

export const Filters = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  flex-direction: column;
  flex: 1;
  width: 100%;

  span {
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.5px;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
  }
`;

export const Reid = styled.div``;

export const CardsWithPagination = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 110px);
  background: #FFF;
  border-radius: 24px;
  margin: 0 4.24rem;
  margin-bottom: 55px;
  padding: 4rem 0;

  p {
    font-size: 1.5rem;
    font-weight: 400;
    color: #202124;
  }
`;
