import { ArrowBack } from '@mui/icons-material';
import * as S from './styles';

export default function LaudoReader({ data, setSelectedLaudo }) {
  function formatStringDate({ day, month, year }) {
    let date = `${String(day).padStart(2, 0)}/`;
    date += `${String(month).padStart(2, 0)}/`;
    date += String(year);

    return date;
  }

  return (
    <S.Container>
      <S.Info>
        <button type="button" onClick={() => setSelectedLaudo(null)}>
          <ArrowBack />
        </button>
        <span>
          {formatStringDate({
            day: data?.day,
            month: data?.month,
            year: data?.year,
          })}
        </span>
      </S.Info>

      <S.TextContainer>
        <S.Text disabled value={data?.text} />
      </S.TextContainer>

    </S.Container>
  );
}
