import { patientElegibility } from 'constants/patientElegibility';
import { patientStatus } from 'constants/patientStatus';
import styled, { css } from 'styled-components';

const TagVariants = {
  [patientStatus.IN_INVESTIGATION]: css`
    background: #F8F147;
    color: #1F1F1F;
  `,
  [patientStatus.IN_CONTACT]: css`
    background: #F2AB58;
    color: #FFF;
  `,
  [patientStatus.IN_CONSULTATION]: css`
    background: #5A8CFD;
    color: #FFF;
  `,
  [patientStatus.IN_PRE_SCREENING]: css`
    background: #AC3CF0;
    color: #FFF;
  `,
  [patientElegibility.YES]: css`
    background: #00E1A7;
    color: #FFF;
  `,
  [patientElegibility.NO]: css`
    background: #BA1A1A;
    color: #FFF;
  `,
  none: css`
    background: transparent;
    color: #5F6368;
  `,
};

export const Container = styled.div`
  border-radius: 20px;
  width: fit-content;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.25rem 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;

  ${({ variant }) => TagVariants[variant] || TagVariants.none};

  svg {
    width: 18px;
    height: 18px;
  }
`;
