import { TableRow } from '@mui/material';
import styled, { css } from 'styled-components';

export const Container = styled(TableRow)`
  cursor: pointer;
  
  td {
    font-weight: 700;
  }
  
  ${({ $evaluated }) => $evaluated && css`
    background: #FFFBFF;

    td {
      font-weight: 400;
      color: #5F6368;
    }
  `}

  &:last-child td, &:last-child th {
    border: 0;
  }
`;

export const PatientInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
`;

export const ButtonCopy = styled.button`
  background: transparent;
  border: none;
`;

export const Score = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
`;
