import { useEffect, useState } from 'react';

import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import ShareServices from 'services/ShareServices';
import * as S from './styles';

export default function ModalContact({
  onClose, referralID, onReply, enableEdit,
}) {
  const [doctorName, setDoctorName] = useState('');
  const [patientName, setPatientName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [observations, setObservations] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      setSubmitting(true);
      await ShareServices.replyWithContact({
        referralID,
        doctorName,
        patientName,
        email,
        phone,
        observations,
      });
      onReply(referralID, 1);
      toast.success('Dados de contato enviados com sucesso');
    } catch {
      toast.error('Erro ao enviar dados de contato', {
        toastId: 'error-contact',
      });
      onClose();
    } finally {
      setSubmitting(false);
    }
  }

  useEffect(() => {
    (async () => {
      try {
        const { contact } = await ShareServices.getContactData(referralID);
        setDoctorName(contact?.doctor_name || '');
        setPatientName(contact?.patient_name || '');
        setEmail(contact?.email || '');
        setPhone(contact?.phone || '');
        setObservations(contact?.observations || '');
      } catch {
        toast.error('Erro ao carregar dados de contato');
      } finally {
        setInitialLoading(false);
      }
    })();
  }, []);

  function handleChangePhone(event) {
    setPhone(
      event.target.value
        .replace(/\D/g, '')
        .replace(/^(\d{2})\B/, '($1) ')
        .replace(/(\d{1})?(\d{4})(\d{4})/, '$1$2-$3'),
    );
  }

  return (
    <S.Container>
      <S.Header>
        <h4>Dados para contato</h4>
        <p>Complete o formulário com os dados para o centro receptor entrar em contato</p>
      </S.Header>

      {initialLoading && (
        <S.ContainerErrorLoading>
          <CircularProgress size={36} />
        </S.ContainerErrorLoading>
      )}

      <S.Form onSubmit={handleSubmit}>
        <S.StyledTexField
          label="Nome do Médico"
          value={doctorName}
          onChange={(event) => setDoctorName(event.target.value)}
          size="small"
          disabled={!enableEdit || initialLoading}
          InputLabelProps={{ shrink: true }}
        />
        <S.StyledTexField
          label="Nome do Paciente"
          value={patientName}
          onChange={(event) => setPatientName(event.target.value)}
          size="small"
          disabled={!enableEdit || initialLoading}
          InputLabelProps={{ shrink: true }}
        />
        <S.StyledTexField
          label="E-mail"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          size="small"
          disabled={!enableEdit || initialLoading}
          InputLabelProps={{ shrink: true }}
        />
        <S.StyledTexField
          label="Telefone"
          value={phone}
          onChange={handleChangePhone}
          size="small"
          disabled={!enableEdit || initialLoading}
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: 15 }}
        />
        <S.StyledTexField
          label="Comentários"
          value={observations}
          onChange={(event) => setObservations(event.target.value)}
          multiline
          rows={5}
          disabled={!enableEdit || initialLoading}
          InputLabelProps={{ shrink: true }}
        />

        <S.FormActions>
          <S.Button variant="ghost" onClick={onClose}>
            {enableEdit ? 'Cancelar' : 'Fechar'}
          </S.Button>
          {enableEdit && (
            <S.Button
              variant="contained"
              disabled={submitting || initialLoading || (!doctorName && !patientName && !email && !phone && !observations)}
              type="submit"
            >
              Enviar
              {submitting && <CircularProgress size={14} sx={{ color: '#FFF' }} />}
            </S.Button>
          )}
        </S.FormActions>
      </S.Form>
    </S.Container>
  );
}
