import {
  Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip,
} from '@mui/material';

import { useState } from 'react';
import * as S from './styles';
import LaudoReader from './LaudoReader';

export default function Laudos({ laudos }) {
  const [selectedLaudo, setSelectedLaudo] = useState(null);

  function handleSelectLaudo(laudo) {
    setSelectedLaudo(laudo);
  }

  function formatStringDate({ day, month, year }) {
    let date = `${String(day).padStart(2, 0)}/`;
    date += `${String(month).padStart(2, 0)}/`;
    date += String(year);

    return date;
  }

  if (selectedLaudo) {
    return (
      <LaudoReader setSelectedLaudo={setSelectedLaudo} data={selectedLaudo} />
    );
  }

  return (
    <S.Container>
      <S.TableContainer
        component={Paper}
        sx={{
          boxShadow: 'none', borderRadius: '1rem', overflowX: 'hidden', textOverflow: 'ellipsis',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Data</TableCell>
              <TableCell>Laudo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {laudos.map((laudo) => (
              <TableRow key={laudo.id}>
                <TableCell>{formatStringDate({
                  day: laudo?.day,
                  month: laudo?.month,
                  year: laudo?.year,
                })}
                </TableCell>
                <TableCell onClick={() => handleSelectLaudo(laudo)} sx={{ cursor: 'pointer' }}>
                  <Tooltip
                    arrow
                    title={<pre style={{ whiteSpace: 'break-spaces' }}>{laudo?.text}</pre>}
                    placement="bottom"
                  >
                    <S.Row>
                      {laudo.text}
                    </S.Row>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

      </S.TableContainer>
    </S.Container>
  );
}
