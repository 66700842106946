import { memo } from 'react';
import { Avatar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import formatDateToPtBr from 'utils/formatDateToPtBr';

import useAuth from 'context/auth/useAuth';
import { barretosID } from 'constants/customers';
import * as S from './styles';

function Header({
  studyName,
  patientIdReid,
  onClose,
  indicatedDate,
  activeMenu,
  setActiveMenu,
}) {
  const { dataUserAuthenticated } = useAuth();

  return (
    <S.Container>
      <S.MultipleTitles>
        <S.Title
          onClick={() => setActiveMenu('prontuario')}
          active={activeMenu === 'prontuario'}
        >
          Prontuários
        </S.Title>
        {dataUserAuthenticated.customerId === barretosID && (
          <S.Title
            onClick={() => setActiveMenu('laudo')}
            active={activeMenu === 'laudo'}
          >
            Laudos
          </S.Title>
        )}
      </S.MultipleTitles>
      <S.ModalInfo>
        {indicatedDate && (
        <S.IndicatedDate>
          <InfoOutlinedIcon sx={{ color: '#5F6368', width: '20px' }} />
          Paciente indicado pela IA em {formatDateToPtBr(indicatedDate)}
        </S.IndicatedDate>
        )}
        <p>{studyName}</p>
        <S.PatientInfo>
          <Avatar
            alt={patientIdReid}
            sx={{
              height: '32px', width: '32px', color: '#604FFF', background: '#FFF',
            }}
          />
          <span data-testid={patientIdReid}>{patientIdReid}</span>
        </S.PatientInfo>
        <CloseIcon onClick={onClose} sx={{ cursor: 'pointer' }} />
      </S.ModalInfo>
    </S.Container>
  );
}

export default memo(Header);
