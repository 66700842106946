import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import SemanticSearchServices from 'services/SemanticSearchServices';
import {
  CircularProgress, FormControlLabel, Radio, RadioGroup,
} from '@mui/material';
import ShareServices from 'services/ShareServices';
import * as S from './styles';
import ResearchCollapse from './ResearchCollapse';

export default function ModalSendPatientToStudy({
  onClose, study, patientId, sha, id, setPatientSended,
}) {
  const [loading, setLoading] = useState(true);
  const [externalCenters, setExternalCenters] = useState([]);
  const [selectedExternalCenter, setSelectedExternalCenter] = useState('');

  const { studyIndicated, external } = study;

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await SemanticSearchServices.getExternalCentersByStudy(studyIndicated.studyId);
        setExternalCenters(response);
      } catch {
        toast.error('Erro ao carregar os centros de pesquisa');
        onClose();
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  async function handleSendPatientToStudy() {
    setLoading(true);
    try {
      if (external) {
        await ShareServices.sharePatient({
          patient: id,
          patientSHA: sha,
          studyDestiny: studyIndicated.studyId,
          customerDestiny: selectedExternalCenter,
        });
        setPatientSended((prevState) => ({ ...prevState, external: 1 }));
      } else {
        await SemanticSearchServices.sendPatientToStudy(sha, [studyIndicated.studyId]);
        setPatientSended((prevState) => ({ ...prevState, internal: 1 }));
      }
      toast.success(
        'Paciente enviado para o estudo',
      );
    } catch {
      toast.error('Erro ao enviar paciente para o estudo');
      onClose();
    } finally {
      setLoading(false);
      onClose();
    }
  }

  return (
    <S.Container>
      <S.Header>
        {!external && (
          <>
            <h4>Enviar Paciente para Estudo Interno</h4>
            <p>Você tem certeza que deseja enviar o paciente para o estudo?</p>
          </>
        )}

        {external && (
          <>
            <h4>Enviar Paciente para Estudo de outro centro</h4>
            <p>Escolha um centro para enviar o paciente</p>
          </>
        )}
      </S.Header>

      {loading && (
        <S.HandleErrorLoading>
          <CircularProgress />
        </S.HandleErrorLoading>
      )}

      {!loading && (
        <>
          <S.Content>
            <p>Estudo: {studyIndicated.studyName}</p>
            <span>Identificação do Paciente: {patientId}</span>

            {external && (
              <S.FormControl>
                <RadioGroup
                  value={selectedExternalCenter ?? ''}
                  name="research-sites"
                  color="primary"
                >
                  {externalCenters.map((research) => (
                    <ResearchCollapse
                      data={research}
                      radio={(
                        <FormControlLabel
                          key={research.idResearchSite}
                          value={research.idResearchSite}
                          control={<Radio size="small" />}
                          label={research.nameResearchSite}
                          onChange={() => setSelectedExternalCenter(research.idResearchSite)}
                        />
)}
                    />
                  ))}
                </RadioGroup>
              </S.FormControl>
            )}
          </S.Content>

          <S.Actions>
            <S.Button variant="ghost" onClick={onClose}>Cancelar</S.Button>
            <S.Button
              variant="contained"
              onClick={handleSendPatientToStudy}
            >
              Enviar
              {loading && <CircularProgress size={14} sx={{ color: '#FFF' }} />}
            </S.Button>
          </S.Actions>
        </>
      )}

    </S.Container>
  );
}
