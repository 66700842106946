import React, { useState } from 'react';

import {
  Checkbox, InputAdornment, ListItemText, MenuItem, Select,
} from '@mui/material';
import { AddchartOutlined, DonutLargeOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Inspectors } from 'constants/inspectors';
import * as S from './styles';

export default function Form({ error, cids }) {
  const [selectedCIDs, setSelectedCIDs] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');

  const navigate = useNavigate();

  function handleSubmit(event) {
    event.preventDefault();
    let search = '';
    if (selectedCIDs.length > 0) {
      search = `cid=${selectedCIDs.toString()}`;
    }
    if (selectedStatus) {
      search = `${search}&${Inspectors.METASTATICO}=${selectedStatus}`;
    }
    navigate(`/busca?${search}`);
  }

  function handleToogleMainCid(cid) {
    if (selectedCIDs.indexOf(cid.cid) > -1) {
      setSelectedCIDs((prevState) => (
        prevState.filter((selected) => selected !== cid.cid)
      ));

      cid.subcids.forEach((subcid) => {
        setSelectedCIDs((prevState) => (
          prevState.filter((selected) => selected !== subcid)
        ));
      });
    } else {
      setSelectedCIDs([...selectedCIDs, cid.cid, ...cid.subcids]);
    }
  }

  function handleChangeSubCid(cid, subcid) {
    if (selectedCIDs.indexOf(subcid) > -1) {
      setSelectedCIDs((prevState) => (
        prevState.filter((selected) => selected !== subcid)
      ));
      setSelectedCIDs((prevState) => (
        prevState.filter((selected) => selected !== cid)
      ));
    } else {
      setSelectedCIDs([...selectedCIDs, subcid]);
    }
  }

  function verifyAllIsChecked(cid) {
    if (selectedCIDs.indexOf(cid) > -1) {
      return true;
    }

    const { subcids } = cids.find((c) => c.cid === cid);
    if (subcids.length === 0) {
      return false;
    }
    return subcids.every((subcid) => selectedCIDs.indexOf(subcid) > -1);
  }

  function verifySomeIsChecked(cid) {
    const { subcids } = cids.find((c) => c.cid === cid);
    return subcids.some((subcid) => selectedCIDs.indexOf(subcid) > -1);
  }

  function verifyIsChecked(cid) {
    return selectedCIDs.indexOf(cid) > -1;
  }

  return (
    <>
      {error && (
        <S.ErrorContainer><p>Erro ao carregar dados</p></S.ErrorContainer>
      )}

      {!error && (
        <S.Container>
          <S.ContainerForm>
            <>
              <S.Title>
                <h1>Encontre pacientes</h1>
                <p>
                  Acesse as informações de seus pacientes de maneira rápida e segura. <br />
                  Selecione o CID para iniciar a sua busca.
                </p>
              </S.Title>
              <S.Form onSubmit={handleSubmit}>
                <Select
                  value={selectedCIDs}
                  onChange={(event) => setSelectedCIDs(event.target.value)}
                  multiple
                  defaultValue=""
                  displayEmpty
                  startAdornment={(
                    <InputAdornment position="start">
                      <DonutLargeOutlined />
                    </InputAdornment>
              )}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return 'CIDS';
                    }
                    return selected.join(', ');
                  }}
                >
                  {cids.map((option) => (
                    <div key={option.cid}>
                      <MenuItem
                        value={option.cid}
                      >
                        <Checkbox
                          color="secondary"
                          size="small"
                          checked={verifyAllIsChecked(option.cid)}
                          indeterminate={verifySomeIsChecked(option.cid) && !verifyAllIsChecked(option.cid)}
                          onChange={() => handleToogleMainCid(option)}
                        />

                        <ListItemText primary={option.cid} />
                      </MenuItem>

                      {option.subcids.map((subcid) => (
                        <MenuItem
                          style={{ marginLeft: '1rem' }}
                          key={`${option.cid}-${subcid}`}
                          value={subcid}
                        >
                          <Checkbox
                            color="secondary"
                            size="small"
                            checked={verifyIsChecked(subcid)}
                            onChange={() => handleChangeSubCid(option.cid, subcid)}
                          />
                          <ListItemText primary={subcid} />
                        </MenuItem>
                      ))}
                    </div>
                  ))}
                </Select>

                <Select
                  value={selectedStatus}
                  onChange={(event) => setSelectedStatus(event.target.value)}
                  defaultValue=""
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return 'Metástase';
                    }
                    return `Metástase - ${selected}`;
                  }}
                  startAdornment={(
                    <InputAdornment position="start">
                      <AddchartOutlined />
                    </InputAdornment>
              )}
                >
                  <MenuItem value="Sim">Sim</MenuItem>
                  <MenuItem value="Não">Não</MenuItem>
                </Select>

                <S.Button
                  variant="contained"
                  type="submit"
                  disabled={selectedCIDs.length === 0}
                >
                  Buscar Pacientes
                </S.Button>
              </S.Form>
            </>

          </S.ContainerForm>
        </S.Container>
      )}
    </>
  );
}
