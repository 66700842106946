import { List, Menu } from '@mui/material';
import { patientElegibility } from 'constants/patientElegibility';
import { patientStatus } from 'constants/patientStatus';
import styled, { css } from 'styled-components';

export const Relative = styled.div`
  position: relative;
`;

const TagVariants = {
  [patientStatus.IN_INVESTIGATION]: css`
    background: #F8F147;
    color: #1F1F1F;
  `,
  [patientStatus.IN_CONTACT]: css`
    background: #F2AB58;
    color: #FFF;
  `,
  [patientStatus.IN_CONSULTATION]: css`
    background: #5A8CFD;
    color: #FFF;
  `,
  [patientStatus.IN_PRE_SCREENING]: css`
    background: #AC3CF0;
    color: #FFF;
  `,
  [patientElegibility.YES]: css`
    background: #00E1A7;
    color: #FFF;
  `,
  [patientElegibility.NO]: css`
    background: #BA1A1A;
    color: #FFF;
  `,
  none: css`
    background: transparent;
    color: #5F6368;
  `,
};

export const Container = styled.div`
  border-radius: 20px;
  width: fit-content;

  ${({ variant }) => TagVariants[variant] || TagVariants.none};

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const CustomList = styled(List)`
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.25rem 0.75rem;

  li {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.25rem;
    cursor: pointer;
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const CustomMenu = styled(Menu)`
  ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.5rem;

    li {
      padding: 0.375rem 0.75rem;

      &:hover {
        background: #E3DFFF;
      }
    }
    
    ul {
      padding: 0.375rem 0.75rem;

      &:hover {
        background: #E3DFFF;
      }

      li {
        padding: 0;
      }
    }
  }
`;
