import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  border: 1px solid rgba(96, 79, 255, 0.3);
  border-radius: 1rem;
  padding: 2rem 1.5rem 1rem 1.5rem;
  max-width: 1264px;
  font-size: 0.875rem;
`;
