import styled from 'styled-components';

export const ButtonReidentify = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 1rem;
  background: #DAE2FF;
  border: 0;
  border-radius: 1.25rem;

  p {
    font-size: 0.8rem;
    font-weight: 500;
  }
`;
