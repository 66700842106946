import { createContext, useCallback, useState } from 'react';
import SemanticSearchServices from 'services/SemanticSearchServices';

export const FavoriteListsContext = createContext({
  lists: [],
  error: false,
  loading: true,
  loadLists: () => {},
});

export default function FavoriteListsProvider({ children }) {
  const [lists, setLists] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const loadLists = useCallback(async () => {
    if (lists.length > 0) return;
    setLoading(true);
    try {
      const listsResponse = await SemanticSearchServices.getFavoritesList();
      setLists(listsResponse);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <FavoriteListsContext.Provider value={{
      lists,
      error,
      loading,
      loadLists,
      setLists,
    }}
    >
      {children}
    </FavoriteListsContext.Provider>
  );
}
