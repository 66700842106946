import * as S from './styles';

export default function TabMenu({
  status, setStatus, isLoading, error, qtyOfPatients, tabsList,
}) {
  function getQtyOfPatients(itemStatus) {
    if (isLoading || error) {
      return '0';
    }

    return qtyOfPatients[itemStatus] || '0';
  }

  return (
    <S.Container>
      {tabsList.map((tab) => (
        <S.ItemMenu
          key={tab.status}
          active={status === tab.status}
          onClick={() => setStatus(tab.status)}
        >
          <span>{tab.label}</span>
          <div>{getQtyOfPatients(tab.status)}</div>
        </S.ItemMenu>
      ))}
    </S.Container>
  );
}
