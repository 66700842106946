import { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse as CollapseMui } from '@mui/material';

import * as S from './styles';

export default function Collapse({ children, title }) {
  const [isOpen, setIsOpen] = useState(false);
  const isCid = title?.toLowerCase() === 'cids';

  return (
    <S.Wrapper>
      <S.Header onClick={() => setIsOpen(!isOpen)}>
        <S.Title isCid={isCid}>{!isCid ? title?.toLowerCase() : title}</S.Title>
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </S.Header>
      <CollapseMui in={isOpen} timeout="auto" unmountOnExit>
        {children}
      </CollapseMui>
    </S.Wrapper>
  );
}
