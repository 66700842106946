import {
  CheckCircleOutline, HighlightOff, InfoOutlined,
} from '@mui/icons-material';
import {
  Avatar, IconButton, TableCell, TableRow, Tooltip,
} from '@mui/material';

import formatDateToPtBr from 'utils/formatDateToPtBr';
import useModal from 'hooks/useModal';
import Modal from 'components/Modal';
import ModalHealthRecord from 'components/Modal/ModalHealthRecord';
import { patientStatusShared } from 'constants/patientStatus';
import * as S from '../../styles';
import ModalContact from '../ModalContact';

export default function PatientRow({
  patient, onReply, status, columns,
}) {
  const {
    visible: visibleClinicalNotes,
    handleOpenModal: handleOpenModalClinicalNotes,
    handleCloseModal: handleCloseModalClinicalNotes,
  } = useModal();

  const {
    visible: visibleContact,
    handleOpenModal: handleOpenModalContact,
    handleCloseModal: handleCloseModalContact,
  } = useModal();

  function handleReply(action) {
    if (status === patientStatusShared.AWAIT_ASSIGN) {
      handleOpenModalContact();
      return;
    }
    onReply(patient.id, action);
  }

  function handleClickRow(column) {
    if (column === 'Ações' || column === 'Contato') return;
    handleOpenModalClinicalNotes();
  }

  return (
    <>
      <Modal visible={visibleClinicalNotes}>
        <ModalHealthRecord
          onClose={handleCloseModalClinicalNotes}
          patient={patient}
          patientIdReid={patient.aes}
          search
          data={patient.clinical_notes}
        />
      </Modal>
      <Modal visible={visibleContact}>
        <ModalContact
          onClose={handleCloseModalContact}
          referralID={patient.id}
          enableEdit={status === patientStatusShared.AWAIT_ASSIGN}
          onReply={onReply}
        />
      </Modal>
      <TableRow sx={{ cursor: status !== patientStatusShared.REJECTED && 'pointer' }}>
        {columns.map((column) => (
          <TableCell
            key={column.value || column.content}
            onClick={() => handleClickRow(column.content)}
          >
            {column.value === 'aes' && (
              <S.PatientInfo
                style={{ cursor: 'pointer' }}
                onClick={handleOpenModalClinicalNotes}
              >
                <Avatar
                  alt={patient.id}
                  sx={{
                    height: '32px', width: '32px', color: '#604FFF', background: '#FFF',
                  }}
                />
                <p>{patient.aes || ''}</p>
              </S.PatientInfo>
            )}
            {(column.value === 'send_date' || column.value === 'accepted_date') && (
              <span>
                {patient[column.value] ? formatDateToPtBr(patient[column.value]) : '-'}
              </span>
            )}
            {column.content === 'Contato' && (
              <S.ActionsColumns>
                <Tooltip title="Contato" arrow>
                  <IconButton onClick={handleOpenModalContact}>
                    <InfoOutlined color="primary" style={{ cursor: 'pointer', textAlign: 'center' }} />
                  </IconButton>
                </Tooltip>
              </S.ActionsColumns>
            )}
            {!column.isCustomized && (
              <span>
                {patient[column.value]}
              </span>
            )}
            {column.content === 'Ações' && (
            <S.ActionsColumns>
              <Tooltip title="Aprovar" arrow>
                <IconButton onClick={() => handleReply(1)}>
                  <CheckCircleOutline color="primary" style={{ cursor: 'pointer', textAlign: 'center' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Recusar" arrow>
                <IconButton onClick={() => handleReply(0)}>
                  <HighlightOff color="error" style={{ cursor: 'pointer' }} />
                </IconButton>
              </Tooltip>
            </S.ActionsColumns>
            )}
          </TableCell>
        ))}
      </TableRow>
    </>
  );
}
