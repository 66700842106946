import { Menu } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Menu)`
  ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0.5rem;
    gap: 0.25rem;
    
    li, ul {
      padding: 0.75rem;
      transition: all 0.2s;
      border-radius: 8px;
      cursor: default !important;
      margin-right: 0.75rem;
    }
  }

  & .MuiPaper-root {
    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background-color: #FFF; 
      border-radius: 16px;
      border: 1px solid #E3E3E3;
      border-bottom: 0;
      border-top: 0;
      width: 6px;
      margin: 1rem 0;
    }

    ::-webkit-scrollbar-thumb {
      background: #B8B8B8;
      width: 6px;
      border-radius: 4px;
    }
  }

  & .MuiPaper-root {
    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background-color: #FFF; 
      border-radius: 16px;
      border: 1px solid #E3E3E3;
      border-bottom: 0;
      border-top: 0;
      width: 6px;
      margin: 1rem 0;
    }

    ::-webkit-scrollbar-thumb {
      background: #B8B8B8;
      width: 6px;
      border-radius: 4px;
    }
  }
`;
