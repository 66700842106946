import { Overlay } from 'components/Overlay';
import { ReactPortal } from 'components/ReactPortal';

export default function Modal({ visible, children }) {
  if (!visible) {
    return null;
  }

  return (
    <ReactPortal containerId="modal-root">
      <Overlay>
        {children}
      </Overlay>
    </ReactPortal>
  );
}
