import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #E5E1EC;
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  gap: 2rem;

  a, p {
    font-size: 0.875rem;
    color: #000;
    font-weight: 500;
  }
  
  ol {
    gap: 1rem;
  }
`;

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 1.25rem;
`;

export const BackButton = styled.button`
  background: transparent;
  border: 0;
  display: flex;
`;

export const TitleItem = styled.div`
  padding-left: calc(2rem + 20px);
  padding-right: 1.25rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;

  h2 {
    font-size: 1.375rem;
    font-weight: 400;
    margin-bottom: 2px;
    font-family: 'Roboto',sans-serif;
  }
  
  p {
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.45);
    line-height: 1.5rem;
  }
`;
