import * as S from './styles';

export default function CustomMenu({
  children, menuVisible, open, handleCloseMenu,
}) {
  return (
    <S.Container
      anchorEl={menuVisible}
      open={open}
      onClose={handleCloseMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '16px',
        },
      }}
    >
      {children}
    </S.Container>
  );
}
