import { useContext } from 'react';
import { AuthContext } from './AuthContext';

export default function useAuth() {
  const {
    authenticated, dataUserAuthenticated, handleLogin, handleLogout, loading,
  } = useContext(AuthContext);

  return {
    authenticated, dataUserAuthenticated, handleLogin, handleLogout, loading,
  };
}
