import styled, { css } from 'styled-components';
import { ErrorContainer } from 'components/ErrorContainer';

export const Container = styled.div`
  background: #FFF;
  border: 1px solid rgba(96, 79, 255, 0.3);
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  max-width: 1264px;
  width: 90%;
  max-height: 100%;
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: #FFF; 
    border-radius: 16px;
    border: 1px solid #E3E3E3;
    border-bottom: 0;
    border-top: 0;
    width: 6px;
    margin: 1rem 0;
  }

  ::-webkit-scrollbar-thumb {
    background: #B8B8B8;
    width: 6px;
    border-radius: 4px;
  }
`;

export const ContainerLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const Content = styled.main`
  display: grid;
  ${({ indicated, search }) => ((indicated || !search) ? css`
      grid-template-columns: 70% 30%;
      height: 100%;
    `
    : css`
      grid-template-columns: 100%;
      height: 100vh;
      max-height: 700px;
    `)
};
`;

export const Evaluator = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
  padding-right: 1rem;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #F6F8FC;
    border-radius: 4px;
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #B8B8B8;
    width: 6px;
    border-radius: 4px;
    background-clip: content-box;
  }
`;

export const ModalErrorContainer = styled(ErrorContainer)`
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ large }) => large && css`
    min-width: 700px;
  `};
  background: #FFF;
`;
