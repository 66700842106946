import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;

  > a img{
    height: 24px;
    object-fit: cover;
  }
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  a {
    font-weight: 500;

    &:nth-child(3){
      margin-right: 6rem;
    }
  }
`;

export const UserDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  p {
    font-size: 0.75rem;
    color: #604FFF;
    font-weight: 500;
  }
`;
