import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Pagination } from '@mui/material';

import Loader from 'components/Loader';
import CardPatient from 'components/CardPatient';
import SemanticSearchServices from 'services/SemanticSearchServices';
import countPagesTable from 'utils/countPagesTable';

import useFilter from 'context/filter/useFilter';
import { Inspectors } from 'constants/inspectors';
import ButtonReidControl from 'components/ButtonReidControl';
import Filter from '../Filter';
import ListActiveFilters from '../ListActiveFilters';

import * as S from './styles';

export default function Result({ searchParams, cids }) {
  const cid = searchParams.get('cid');
  const { filters, setFilters } = useFilter();
  const status = searchParams.get(Inspectors.METASTATICO);
  const [filtersShow, setFiltersShow] = useState({});

  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [request, setRequest] = useState(false);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(0);

  async function handleSubmitFilter(customPage) {
    setIsLoading(true);
    try {
      const resultSearch = await SemanticSearchServices.search({
        CIDs: cid,
        filters: { ...filters, [Inspectors.METASTATICO]: status },
        page: customPage ?? page,
      });
      setResults(resultSearch.patients);
      setFiltersShow({ ...filters, [Inspectors.METASTATICO]: status });
    } catch (erro) {
      setError(true);
      toast.error('Erro ao buscar pacientes', {
        toastId: 'errorSearch',
        draggable: false,
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [Inspectors.METASTATICO]: status,
    }));

    handleSubmitFilter(0);
  }, []);

  useEffect(() => {
    handleSubmitFilter();
  }, [page]);

  useEffect(() => {
    if (request) {
      handleSubmitFilter(0);
      setRequest(false);
    }
  }, [request]);

  function handleChangePage(event, value) {
    setPage(value - 1);
  }

  return (
    <>
      {isLoading && (
        <Loader />
      )}

      {error && (
        <S.ErrorContainer><p>Erro ao carregar dados</p></S.ErrorContainer>
      )}

      {!isLoading && !error && (
        <div>
          <S.Container>
            <S.ContainerFilter>
              <Filter onSubmit={handleSubmitFilter} cids={cids} />
            </S.ContainerFilter>
            <S.ContainerCards>
              <S.ContainerScrollBar>
                <S.Header>
                  <S.DetailsSearch>
                    <S.Filters>
                      <span>
                        Busca | CIDs: {cid}
                      </span>
                      <h3>
                        {results.total_patients} paciente{results.total_patients !== 1 && 's'} encontrados no banco de dados
                      </h3>
                    </S.Filters>
                    {results.patients.length > 0 && (
                      <S.Reid>
                        <ButtonReidControl />
                      </S.Reid>
                    )}

                  </S.DetailsSearch>
                  <ListActiveFilters filtersShow={filtersShow} onSubmit={setRequest} />

                </S.Header>
                {results.patients.length > 0 && (
                  <S.CardsWithPagination>
                    {results.patients.map((result, index) => (
                      <CardPatient
                        key={result.sha}
                        data={result}
                        lastPatient={index === results.patients.length - 1}
                      />
                    ))}
                    <Pagination
                      count={countPagesTable(results?.total_patients || 1)}
                      page={page + 1}
                      onChange={handleChangePage}
                      color="primary"
                    />
                  </S.CardsWithPagination>
                )}
              </S.ContainerScrollBar>
            </S.ContainerCards>
          </S.Container>
        </div>
      )}
    </>
  );
}
