import styled from 'styled-components';

export const Section = styled.section`
  background: #F6F8FC;
  /* border-radius: 1rem; */
  padding: 1.5rem 1rem;

  &:first-child {
    border-radius: 1rem 1rem 0 0;
  }

  &:last-child {
    border-radius: 0 0 1rem 1rem;
  }
`;
