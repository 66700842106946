import { Button } from '@mui/material';
import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  margin-top: 1rem;

  svg{
    margin-bottom: 1rem;
  }
`;

export const Form = styled.form`
  padding: 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 2rem 0;
`;

export const Input = styled.input`
  border: 0;
  outline: 0;
  background: #F6F8FC;
  border-radius: 8px;
  padding: 1rem 1rem;
  width: 100%;
  border: 1px solid transparent;

  &:focus{
    border: 1px solid #604FFF;
  }
`;

export const ReidButton = styled(Button)`
  text-transform: capitalize;
  color: #FFF;
  font-size: 0.875rem;
  font-weight: 400;
  background: #604FFF;
  border-radius: 100px;
  box-shadow: none;
  padding: 0.5rem 2rem;
  height: 40px;
  width: 100%;

  &:hover{
    background: #604FFF;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
  }

  &:active{
    background: #604FFF;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
  }
`;
