import styled from 'styled-components';
import { Button } from '@mui/material';

export const Container = styled(Button)`
  text-transform: capitalize;
  color: #FFF;
  font-size: 0.875rem;
  font-weight: 400;
  background: #604FFF;
  border-radius: 100px;
  box-shadow: none;
  padding: 0.5rem 2rem;
  height: 40px;
  min-width: 110px;
  margin-bottom: 9px;

  &:hover{
    background: #604FFF;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
  }

  &:active{
    background: #604FFF;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
  }
`;
