import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const Criteria = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 0.875rem;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 400;
  font-size: 0.75rem;

  span {
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: ${({ type }) => (type === 'positive' ? '#00FFBE' : '#BA1A1A')}
  };
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, .4);
  margin: 0.25rem;
`;

export const ExtraText = styled.p`
  font-size: 0.75rem;
  margin-bottom: 1rem;
`;
