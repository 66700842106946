import { Button } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 350px;

  label {
    font-size: 0.85rem;
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #604FFF;
  }

  label.Mui-focused{
    color: #604FFF;
  }
`;

export const SaveButton = styled(Button)`
  background: #604FFF;
  margin-top: 1rem;
  text-transform: capitalize;

  &:hover{
    background: #604FFF;
  }

  &:active{
    background: #604FFF;
  }
`;
