import { ThemeProvider } from '@mui/material';
import theme from 'styles/theme';

import { AuthProvider } from './auth/AuthContext';
import { ReidProvider } from './reid/ReidContext';
import FilterProvider from './filter';
import FavoriteListsProvider from './favoriteLists';

export default function AppProvider({ children }) {
  return (
    <AuthProvider>
      <ReidProvider>
        <ThemeProvider theme={theme}>
          <FilterProvider>
            <FavoriteListsProvider>
              {children}
            </FavoriteListsProvider>
          </FilterProvider>
        </ThemeProvider>
      </ReidProvider>
    </AuthProvider>
  );
}
