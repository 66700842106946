import styled from 'styled-components';
import { TextField as TextFieldMui } from '@mui/material';

export const CloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
  
  svg {
    cursor: pointer;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  color: #49454F;
  max-width: 400px;
  margin-bottom: 1.5rem;

  h4 {
    font-weight: 400;
    font-size: 1.35rem;
  }

  p {
    font-weight: 500;
    font-size: 0.8rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  p {
    font-weight: 500;
    font-size: 0.8rem;
    color: #49454F;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
`;

export const TexField = styled(TextFieldMui)`
  width: 100%;

  label, legend, textarea {
    font-size: 0.875rem;
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #604FFF;
  }

  label.Mui-focused{
    color: #604FFF;
  }
  
`;
