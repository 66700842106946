import { Table as TableMui, TableCell as TableCellMui } from '@mui/material';
import styled, { css } from 'styled-components';

export const Table = styled(TableMui)`
  min-width: 650px;

  th {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  tbody {
    border-bottom: 1px solid rgba(224,224,224,1);
  }

  td {
    font-size: 0.875rem;
  }
`;

export const TableCell = styled(TableCellMui)`
  ${({ $hasAction }) => $hasAction && css`
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 2rem;

    svg {
      width: 18px;
      height: 18px;
    }
  `}
`;
