import { Navigate } from 'react-router-dom';
import useAuth from 'context/auth/useAuth';
import Loader from 'components/Loader';

export default function ProtectedRoute({ children, permission }) {
  const { authenticated, loading, dataUserAuthenticated } = useAuth();

  if (loading) {
    return <Loader />;
  }

  if (!authenticated) {
    return <Navigate to="/" />;
  }

  if (permission && !dataUserAuthenticated?.permissions?.includes(permission)) {
    return <Navigate to="/" />;
  }

  return children;
}
