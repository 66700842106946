import styled from 'styled-components';

export const CustomersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;

  section {
    height: 100%;
  }
`;

export const Card = styled.section`
  background: #fff;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.05);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.75rem;
`;

export const Name = styled.h3`
  font-size: 1.25rem;
  font-weight: 400;
  color: #201A1B;
`;

export const Update = styled.div`
  font-size: 0.8rem;
  color: #201A1B;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  span {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #201A1B;
  }
`;
