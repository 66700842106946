export const descriptionCIDs = {
  C50: 'Neoplasia maligna da mama',
  C501: 'Neoplasia maligna da porção central da mama',
  C502: 'Neoplasia maligna do quadrante superior interno da mama',
  C504: 'Neoplasia maligna do quadrante superior externo da mama',
  C508: 'Neoplasia maligna da mama com lesão invasiva',
  C509: 'Neoplasia maligna da mama, não especificada',
  C61: 'Neoplasia maligna da próstata',
  C16: 'Neoplasia maligna do estômago',
  C15: 'Neoplasia maligna do esôfago',
  C155: 'Neoplasia maligna do esôfago (parte inferior)',
  C159: 'Neoplasia maligna do esôfago, não especificada',
  C154: 'Neoplasia maligna do intestino delgado',
  C163: 'Neoplasia maligna do cólon',
  C166: 'Neoplasia maligna do apêndice',
  C169: 'Neoplasia maligna do reto',
  C160: 'Neoplasia maligna do reto superior',
  C161: 'Neoplasia maligna do reto médio',
  C168: 'Neoplasia maligna do reto inferior',
  C151: 'Neoplasia maligna do intestino grosso, parte não especificada',
  C150: 'Neoplasia maligna do intestino grosso',
  C152: 'Neoplasia maligna do cólon ascendente',
  C165: 'Neoplasia maligna do cólon transverso',
  C162: 'Neoplasia maligna do cólon descendente',
  C67: 'Neoplasia maligna da bexiga',
  C670: 'Neoplasia maligna da parede da bexiga',
  C672: 'Neoplasia maligna do assoalho da bexiga',
  C673: 'Neoplasia maligna do lábio superior da uretra',
  C677: 'Neoplasia maligna do colo da bexiga',
  C679: 'Neoplasia maligna da bexiga, não especificada',
};
