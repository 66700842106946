import { useRef } from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CloseIcon from '@mui/icons-material/Close';

import useReid from 'context/reid/useReid';

import * as Modal from 'components/Modal/styles';
import * as S from './styles';

export default function ModalReidentify({ onClose }) {
  const passwordField = useRef();
  const { handleReidSubmit } = useReid();

  function handleSubmit(event) {
    event.preventDefault();
    handleReidSubmit(passwordField.current.value);
    onClose();
  }

  return (
    <Modal.Container>
      <S.Header><CloseIcon onClick={onClose} sx={{ cursor: 'pointer', color: '#5F6368' }} /></S.Header>
      <S.Title>
        <LockOutlinedIcon sx={{ color: '#604FFF', width: 48, height: 48 }} />
        <strong>Reidentificar Pacientes</strong>
        <span>Utilize sua senha para identificação dos pacientes</span>
      </S.Title>
      <S.Form onSubmit={handleSubmit}>
        <S.Input type="password" ref={passwordField} placeholder="Digite sua senha" />
        <S.ReidButton type="submit" variant="contained">Reidentificar</S.ReidButton>
      </S.Form>
    </Modal.Container>
  );
}
