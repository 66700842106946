import HttpClient from './utils/HttpClient';

class StudyServices {
  constructor() {
    this.httpClient = new HttpClient(process.env.REACT_APP_API_COMTRIALS_URL);
  }

  getAllCustomers() {
    return this.httpClient.get('/customer/all');
  }

  listCustomerStudies() {
    return this.httpClient.get('/customer/studies');
  }

  listStudiesByCustomerID(customerID) {
    return this.httpClient.get(`/customer/all/${customerID}`);
  }

  listCIDsByCustomer() {
    return this.httpClient.get('/customer/cids');
  }

  async getStudyName(studyId) {
    const { studies } = await this.httpClient.get('/customer/studies');

    return studies.find((study) => (
      study.id === studyId
    )).name;
  }
}

export default new StudyServices();
