import { TextField as Container } from '@mui/material';

export default function TextField({
  value, onChange, label, helperText, type, ...props
}) {
  return (
    <Container
      id="outlined-basic"
      label={label}
      variant="outlined"
      size="small"
      value={value || ''}
      onChange={onChange}
      sx={{ fontSize: '0.875rem' }}
      helperText={helperText}
      type={type || 'text'}
      InputLabelProps={{ shrink: true }}
      {...props}
    />
  );
}
