import { TableRow } from '@mui/material';
import styled, { css } from 'styled-components';

export const Container = styled(TableRow)`
  td {
    font-weight: 700;
  }
  
  ${({ $newPatients }) => !$newPatients && css`
    background: #FFFBFF;

    td {
      font-weight: 400;
      color: #5F6368;
    }
  `}

  &:last-child td, &:last-child th {
    border: 0;
  }
`;

const StatusVariants = {
  Avaliar: '#00FFBE',
  Avaliado: '#5F6368',
  Encerrado: '#B8B8B8',
};

export const Status = styled.p`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  span {
    display: block;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background: ${({ variant }) => StatusVariants[variant] || StatusVariants.Disabled};
  }
`;

export const PatientsCount = styled.div`
  display: flex;
  align-items: center;
  align-items: center;
  gap: 0.5rem;
  
  div {
    color: ${({ $newPatients }) => ($newPatients ? '#1F1F1F' : '#5F6368')};
    font-weight: ${({ $newPatients }) => ($newPatients ? 700 : 500)};
  }
`;

const TagVariants = {
  new: '#00FFBE',
  pending: '#F8F147',
  none: '#B8B8B8',
};

export const Tag = styled.div`
  border-radius: 1.25rem;
  font-size: 0.75rem;
  padding: 0.25rem 0.75rem;
  width: fit-content;

  background: ${({ variant }) => TagVariants[variant] || TagVariants.none};
`;
