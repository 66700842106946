import * as S from './styles';

export default function Criterias({ negatives, positives, extraText }) {
  const [positivesFormatted] = (!positives || positives?.length === 0)
    ? [['Sem critérios positivos']]
    : positives.map((criteria) => criteria.split('+').map((positive) => positive.trim()));
  const [negativesFormatted] = (!negatives || negatives?.length === 0)
    ? [['Sem critérios negativos']]
    : negatives.map((criteria) => criteria.split('+').map((negative) => negative.trim()));

  return (
    <S.Container>
      {extraText && (
        <S.ExtraText>
          {extraText}
        </S.ExtraText>
      )}
      <S.Criteria>
        <S.Title>Critérios Positivos</S.Title>
        {positivesFormatted?.map((criteria) => (
          <S.Item key={criteria} type="positive">
            <span />
            <p>{criteria}</p>
          </S.Item>
        ))}
      </S.Criteria>
      <S.Separator />
      <S.Criteria>
        <S.Title>Critérios Negativos</S.Title>
        {negativesFormatted?.map((criteria) => (
          <S.Item key={criteria} type="negative">
            <span />
            <p>{criteria}</p>
          </S.Item>
        ))}
      </S.Criteria>
    </S.Container>
  );
}
