import HttpClient from './utils/HttpClient';

class CustomerServices {
  constructor() {
    this.httpClient = new HttpClient(process.env.REACT_APP_API_CUSTOMER_URL);
  }

  login(user) {
    return this.httpClient.post('/users/login', user);
  }

  getDataUser(userId) {
    return this.httpClient.get(`/users/info?userID=${userId}`);
  }

  changePassword(data) {
    return this.httpClient.post('/users/redefine-password', {
      email: data.email,
      password: data.currentPassword,
      newPassword: data.newPassword,
    });
  }
}

export default new CustomerServices();
