import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  background: #F3F6FC;
  border-radius: 1rem;
  padding: 0.25rem 0.5rem;
  margin-left: -4px;
`;

export const Title = styled.div`
  font-size: 0.9rem;
  font-weight: 700;
  ${({ isCid }) => !isCid && `
    text-transform: capitalize;
  `}
`;
