import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput,
} from '@mui/material';

export default function InputPassword({
  id, inputLabel, error, value, onChange, helperText,
}) {
  const [showPassword, setShowPassword] = useState(false);

  function handleClickShowPassword() {
    setShowPassword((prevState) => !prevState);
  }

  return (
    <FormControl variant="outlined" size="small" error={error}>
      <InputLabel htmlFor={id}>{inputLabel}</InputLabel>
      <OutlinedInput
        id={id}
        type={showPassword ? 'text' : 'password'}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
            )}
        label={inputLabel}
        value={value}
        onChange={onChange}
        error={error}
      />
      {error && <FormHelperText id="outlined-helper-text">{helperText}</FormHelperText>}
    </FormControl>
  );
}
