export const patientStatus = {
  IN_INVESTIGATION: 'Em investigação',
  IN_CONTACT: 'Em contato',
  IN_CONSULTATION: 'Em consulta',
  IN_PRE_SCREENING: 'Em pré-triagem',
  CONCLUDED: 'Concluído',
};

export const patientStatusNames = {
  [patientStatus.IN_INVESTIGATION]: 'Investigação',
  [patientStatus.IN_CONTACT]: 'Contato',
  [patientStatus.IN_CONSULTATION]: 'Consulta',
  [patientStatus.IN_PRE_SCREENING]: 'Pre-Screening',
  [patientStatus.CONCLUDED]: 'Concluído',
};

export const patientStatusRequests = {
  NOT_EVALUATED: 'notEvaluated',
  IN_INVESTIGATION: 'investigation',
  IN_CONTACT: 'inContact',
  IN_CONSULTATION: 'inConsultation',
  IN_PRE_SCREENING: 'inPreScreening',
  CONCLUDED: 'concluded',
  ELEGIBLE: 'elegible',
  INELIGIBLE: 'ineligible',
};

export const requestReferralTypes = {
  SENDED: 'sended',
  RECEIVED: 'received',
};

export const patientStatusShared = {
  AWAIT_DOCTOR: 'awaitingDoctorApproval',
  AWAIT_DESTINY: 'awaitingDestinyCenter',
  AWAIT_ASSIGN: 'awaitingPatientConsent',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
};

export const referralStatusNames = {
  [patientStatusShared.AWAIT_DOCTOR]: 'Aguardando aprovação do médico',
  [patientStatusShared.AWAIT_DESTINY]: 'Aguardando centro receptor',
  [patientStatusShared.AWAIT_ASSIGN]: 'Aguardando consentimento do paciente',
  [patientStatusShared.ACCEPTED]: 'Aceito',
  [patientStatusShared.REJECTED]: 'Rejeitado',
};

export const referralPatientsNextStep = {
  [patientStatusShared.AWAIT_DOCTOR]: referralStatusNames[patientStatusShared.AWAIT_DESTINY],
  [patientStatusShared.AWAIT_DESTINY]: referralStatusNames[patientStatusShared.AWAIT_ASSIGN],
  [patientStatusShared.AWAIT_ASSIGN]: referralStatusNames[patientStatusShared.ACCEPTED],
};

export const patientStatusRequestsNames = {
  [patientStatus.IN_INVESTIGATION]: patientStatusRequests.IN_INVESTIGATION,
  [patientStatus.IN_CONTACT]: patientStatusRequests.IN_CONTACT,
  [patientStatus.IN_CONSULTATION]: patientStatusRequests.IN_CONSULTATION,
  [patientStatus.IN_PRE_SCREENING]: patientStatusRequests.IN_PRE_SCREENING,
  [patientStatus.CONCLUDED]: patientStatusRequests.CONCLUDED,
};
