import { patientStatusRequests } from 'constants/patientStatus';
import HttpClient from './utils/HttpClient';

class PatientServices {
  constructor() {
    this.httpClient = new HttpClient(process.env.REACT_APP_API_COMTRIALS_URL);
  }

  testReset() {
    this.httpClient.post('/customer/test-reset');
  }

  listPatients({
    type, studyId, page, signal, limit = 10, customerID = '',
  }) {
    if (type === patientStatusRequests.NOT_EVALUATED) {
      return this.httpClient.get(`/study/${type}/${studyId}?page=${page}&limit=${limit}&customerID=${customerID}`, signal);
    }

    return this.httpClient.get(
      `/study/evaluated/${studyId}?page=${page}&status=${type}&limit=${limit}&customerID=${customerID}`,
      signal,
    );
  }

  sendFeedback(data) {
    return this.httpClient.post('/feedback/create', data);
  }

  listHealthRecord(patientID, customerID = '') {
    return this.httpClient.get(`/indicatedPatient/file?sha=${patientID}&customer=${customerID}`);
  }

  listPathox(patientID) {
    return this.httpClient.get(`/indicatedPatient/pathox?sha=${patientID}`);
  }

  listHistory(patientID, studyID) {
    return this.httpClient.get(`/history/?patient_id=${patientID}&study_id=${studyID}`);
  }

  createObservation(data) {
    return this.httpClient.post('/observation', data);
  }

  getPatientsByAES(aes, studyId, customerID = '') {
    return this.httpClient.get(`/study/search?studyID=${studyId}&AES=${aes}&customerID=${customerID}`);
  }
}

export default new PatientServices();
