import { createTheme } from '@mui/material';

export default createTheme({
  palette: {
    primary: {
      main: '#604FFF',
    },
    secondary: {
      main: '#00E1A7',
    },
    error: {
      main: '#BA1A1A',
    },
  },
});
