import {
  Paper, TableContainer, TableHead, TableRow,
} from '@mui/material';

import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { useState } from 'react';
import useReid from 'context/reid/useReid';
import * as S from './styles';

export default function TableData({ children, headItems, label }) {
  const [activeAction, setActiveAction] = useState(false);
  const { someReidentifiedPatient } = useReid();

  function handleClick(callback) {
    if (someReidentifiedPatient) {
      setActiveAction(true);
      callback((prevState) => (prevState === 'asc' ? 'desc' : 'asc'));
    }
  }

  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: 'none', borderRadius: '1rem',
      }}
    >
      <S.Table aria-label={label}>
        <TableHead>
          <TableRow>
            {headItems.map((item) => (
              <S.TableCell
                key={item.content}
                align={item.align || 'left'}
                onClick={() => handleClick(item.action)}
                $hasAction={someReidentifiedPatient && !!item.action}
              >
                {item.content}
                {item.action && activeAction && item.type === 'asc' && (
                  <ArrowUpward />
                )}
                {item.action && activeAction && item.type === 'desc' && (
                  <ArrowDownward />
                )}
              </S.TableCell>
            ))}
          </TableRow>
        </TableHead>
        {children}
      </S.Table>
    </TableContainer>
  );
}
