import { useSearchParams } from 'react-router-dom';
import Header from 'components/Header';
import { ToastContainer } from 'react-toastify';
import CloseButton from 'components/Toast/CloseButton';
import { useEffect, useState } from 'react';
import StudyServices from 'services/StudyServices';
import Loader from 'components/Loader';
import Result from './components/Result';
import Form from './components/Form';

export default function Search() {
  const [searchParams, setSearchParams] = useSearchParams();
  const isSubmitted = searchParams.size > 0;

  const [cids, setCids] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const cidsList = await StudyServices.listCIDsByCustomer();
        setCids(cidsList.cids);
      } catch {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={false}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        closeButton={CloseButton}
      />

      {isLoading && (
        <Loader />
      )}

      <Header />

      {!isSubmitted && !isLoading && (
        <Form
          setSearch={setSearchParams}
          error={error}
          cids={cids}
        />
      )}

      {isSubmitted && !isLoading && (
        <Result searchParams={searchParams} cids={cids} />
      )}

    </>
  );
}
