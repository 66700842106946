import { optionsByElebility, patientElegibility } from 'constants/patientElegibility';
import { patientStatus } from 'constants/patientStatus';

export default function defaultSubStatusByOptions({ newStatus, elegibility, status }) {
  if (newStatus === patientStatus.CONCLUDED) {
    if (elegibility === String(patientElegibility.YES)) {
      if (status === optionsByElebility[elegibility].option7) {
        return 'Exclusão';
      }
      return 'Randomizado';
    }
    return 'Exclusão';
  }
  if (newStatus === patientStatus.IN_INVESTIGATION) {
    return 'Investigar';
  }
  if (newStatus === patientStatus.IN_CONTACT) {
    return 'Contato';
  }
  if (newStatus === patientStatus.IN_CONSULTATION) {
    return 'Consulta';
  }
  if (newStatus === patientStatus.IN_PRE_SCREENING) {
    return 'Pre-screening';
  }
  return '';
}
