import axios from 'axios';

class TasyService {
  constructor() {
    this.baseURL = 'https://sisonco.com.br';
  }

  async getTasyCode(aes) {
    const data = new FormData();
    data.append('hash', aes);

    const response = await axios({
      baseURL: this.baseURL,
      url: '/api-comsentimento/v1/decodeHashComsentimento',
      method: 'POST',
      data,
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return response.data;
  }
}

export default new TasyService();
