import useFilter from 'context/filter/useFilter';
import { useSearchParams } from 'react-router-dom';
import { Close } from '@mui/icons-material';

import { Inspectors } from 'constants/inspectors';
import { updateSearchParams } from 'utils/updateSearchParams';
import * as S from './styles';

export default function ListActiveFilters({ filtersShow, onSubmit }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setFilters } = useFilter();

  async function removeFilter(filterKey) {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterKey]: '',
    }));

    if (filterKey === Inspectors.METASTATICO) {
      updateSearchParams(searchParams, setSearchParams, Inspectors.METASTATICO, '');
    }

    onSubmit(true);
  }

  return (
    <S.Container>
      {Object.keys(filtersShow).map((key) => (
        filtersShow[key] && (
          <S.Filter key={key}>
            <S.Content>
              <p>{key}:</p>
              <p>{filtersShow[key] === 'true' && 'Sim'}</p>
              <p>{filtersShow[key] === 'false' && 'Não'}</p>
              <p>{filtersShow[key] !== 'true' && filtersShow[key] !== 'false' && filtersShow[key]}</p>
            </S.Content>
            <Close onClick={() => removeFilter(key)} />
          </S.Filter>
        )
      ))}
    </S.Container>
  );
}
