import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Layout from 'components/Layout';
import TitlePage from 'components/TitlePage';
import Loader from 'components/Loader';
import { Section } from 'components/Section';
import { ErrorContainer } from 'components/ErrorContainer';
import CustomerServices from 'services/CustomerServices';

import Form from './components/Form';
import ProfileData from './components/ProfileData';

import * as S from './styles';

export default function Profile() {
  const [dataUser, setDataUser] = useState({});
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const data = await CustomerServices.getDataUser(id);

        setDataUser(data);
      } catch {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <Layout>
      <Section>
        <TitlePage
          title="Perfil"
          subtitle="Aqui você encontra seus dados e pode alterar a sua senha"
        />
      </Section>
      {isLoading ? (
        <Loader />
      ) : (
        <Section>
          {error ? (
            <ErrorContainer>
              <strong>Erro ao carregar dados</strong>
            </ErrorContainer>
          ) : (
            <S.Content>
              <S.ContainerContent>
                <ProfileData data={dataUser} />

                <S.EditPassword>
                  <h3>Alterar sua Senha</h3>

                  <Form email={dataUser.email} />
                </S.EditPassword>
              </S.ContainerContent>
            </S.Content>
          )}
        </Section>
      )}

    </Layout>
  );
}
