import { LockOpenOutlined, Visibility } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import Modal from 'components/Modal';
import ModalReidentify from 'components/Modal/ModalReidentify';
import { barretosID, barretosTIID, testeID } from 'constants/customers';
import useAuth from 'context/auth/useAuth';
import useReid from 'context/reid/useReid';
import useModal from 'hooks/useModal';

import * as S from './styles';

export default function ButtonReidControl() {
  const {
    someReidentifiedPatient, password, someTasyCodePatient, availableToReidTasyCode, showTasyCode,
  } = useReid();
  const { visible, handleOpenModal, handleCloseModal } = useModal();
  const patientsReidentify = password && someReidentifiedPatient;
  const { dataUserAuthenticated } = useAuth();
  const customerIsTesteOrBarretos = (
    dataUserAuthenticated.customerId === testeID
    || dataUserAuthenticated.customerId === barretosID
    || dataUserAuthenticated.customerId === barretosTIID
  );

  return (
    <>
      {!patientsReidentify && (
        <>
          <S.ButtonReidentify type="button" onClick={handleOpenModal}>
            <LockOpenOutlined fontSize="small" />
            <p>Reidentificar Pacientes</p>
          </S.ButtonReidentify>
          <Modal visible={visible}>
            <ModalReidentify onClose={handleCloseModal} />
          </Modal>
        </>
      )}
      {(!customerIsTesteOrBarretos && patientsReidentify && password) && (
      <>
        {/* TODO: não é sempre que todos os pacientes são reidentificados, sendo necessário mudar a senha digitada as vezes */}
        <Tooltip arrow title="Clique para alterar a senha digitada">
          <S.ButtonReidentify type="button" onClick={handleOpenModal}>
            <LockOpenOutlined fontSize="small" />
            <p>Pacientes Reidentificados</p>
          </S.ButtonReidentify>
        </Tooltip>
        <Modal visible={visible}>
          <ModalReidentify onClose={handleCloseModal} />
        </Modal>
      </>
      )}

      {(customerIsTesteOrBarretos && someTasyCodePatient) && (
      <S.ButtonReidentify type="button">
        <LockOpenOutlined fontSize="small" />
        <p>Pacientes Reidentificados</p>
      </S.ButtonReidentify>
      )}

      {(patientsReidentify
        && availableToReidTasyCode.current
        && customerIsTesteOrBarretos
        && !someTasyCodePatient
      ) && (
      <S.ButtonReidentify type="button" onClick={showTasyCode}>
        <Visibility fontSize="small" />
        Exibir Códigos de Identificação
      </S.ButtonReidentify>
      )}
    </>
  );
}
