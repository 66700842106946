import { TextField as TextFieldMui } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.div`
  width: 50%;
  border-bottom: 2px solid #604FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.25rem;

  font-size: 0.875rem;
  font-weight: 700;
  color: #604FFF;
  margin-bottom: 1rem;
`;

export const ContainerObservations = styled.div`
  padding-right: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: ${({ $isAdmin }) => ($isAdmin ? '55vh' : '40vh')};

  overflow: auto;

  ::-webkit-scrollbar {
    width: 6px;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    margin: 1px;
    background-color: #FFF; 
    border-radius: 4px;
    border: 1px solid #E3E3E3;
    border-bottom: 0;
    border-top: 0;
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #B8B8B8;
    width: 6px;
    border-radius: 4px;
    background-clip: content-box;
  }
`;

export const Observation = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.75rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;

  p {
    font-size: 0.85rem;
    color: #5F6368;
    word-break: break-word;
  }
`;

export const Author = styled.div`
  font-size: 0.85rem;
  color: #5F6368;
  word-break: break-word;
  font-weight: 500;

  span:nth-child(2){
    font-weight: 400;
    word-break: keep-all;
  }

  display: flex;
  gap: 3rem;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 3.5rem;
`;

export const TexField = styled(TextFieldMui)`
  width: 100%;

  label, legend, textarea {
    font-size: 0.875rem;
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #604FFF;
  }

  label.Mui-focused{
    color: #604FFF;
  }
  
`;

export const ContainerLoader = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  height: 40vh;
`;
